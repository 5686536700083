import { useHistory } from 'react-router-dom';

/**
 * this custom hook is used to go back to previous page when needed
 */
const useGoBackToPreviousPage = (toSearch = false) => {
  const history = useHistory();

  const navigateToPreviousPageHandler = () => {
    if (toSearch) {
      history.push(`/${history.location.search}`);
    } else {
      history.goBack();
    }
  };

  // const navigateToHomePageHandler = () => {
  //   history.push(`/${history.location.search}`);
  // };

  return { navigateToPreviousPageHandler };
};

export default useGoBackToPreviousPage;
