import { useEffect, useState } from 'react';

import { API_METHODS } from '../utils';

const useRoadJetAPI = (
  path,
  { apiName = 'RoadJetAPI', method = API_METHODS.GET, body = {}, headers = {} } = {}
) => {
  const [apiData, setApiData] = useState(null);
  const [isLoading, setIsLoading] = useState(true); //this should be true because if it's not started as loading it may cause unwanted renderings without data
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchFromAPI = async () => {
      try {
        // before even starting to fetch data from API, set "loading" status to true and "error" status to false (in case there were an error occurred before)
        // setIsLoading(true);
        setIsError(false);

        // if "path" for fetching data from API is not ready yet (in other words, if it includes "undefined" token), do not make the API call!
        if (path.includes('undefined')) return;

        // this does not have to be null for GET request and can also be configured
        // with provided "body" and "headers" arguments
        const myInit =
          method === API_METHODS.GET
            ? null
            : {
                // OPTIONAL
                headers, // OPTIONAL
                body
              };

        const { API } = await import('aws-amplify');
        const data = await API[method](apiName, path, myInit);

        setIsLoading(false); // set "loading" status to false after data fetching finishes
        setApiData(() => data); // update "apiData" state to be equal to "data" returned from API
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
        // console.log('error in fetching data: ', error);
      }
    };

    fetchFromAPI();
  }, [apiName, path]);

  return [apiData, isError, isLoading];
};

export default useRoadJetAPI;
