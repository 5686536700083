import roadjetLogoBlack from '../../../static/assets/images/svg/logo/RoadjetLogo-black-sm.svg';

export { roadjetLogoBlack };

export const mobileNavigationMenuItems = [
  {
    name: 'Profil',
    links: [
      {
        name: 'Profileinstellungen',
        to: '/account/profil',
        type: 'text'
      },
      {
        name: 'Meine Buchungen',
        to: '/account',
        type: 'text'
      }
    ],
    type: 'dropdown',
    isHidden: false
  },
  {
    // icon: 'fas fa-info-circle',
    name: 'Einloggen / Anmelden',
    to: '/account',
    type: 'text',
    isHidden: false
  },
  {
    // icon: 'fas fa-info-circle',
    name: 'Über Uns',
    to: '/uber-uns',
    type: 'text',
    isHidden: false
  },

  {
    // icon: 'fas fa-list',
    name: 'Features',
    to: '/features',
    type: 'text',
    isHidden: false
  },

  {
    // icon: 'fas fa-shield-virus',
    name: 'Hygiene',
    to: '/covid19-hygienekonzept',
    type: 'text',
    isHidden: false
  },

  {
    // icon: 'far fa-file-alt',
    name: 'Magazin',
    to: '/magazin',
    type: 'text',
    isHidden: false
  },

  {
    // icon: 'fas fa-suitcase',
    name: 'Jobs',
    to: '/jobs',
    type: 'text',
    isHidden: false
  },

  {
    // icon: 'fas fa-ticket-alt',
    name: 'Jetzt Buchen',
    to: '/',
    type: 'text',
    isHidden: true
  }

  /* {
    icon: 'fas fa-info-circle',
    name: 'Über RoadJet',
    links: [
      {
        icon: 'fas fa-info-circle',
        name: 'Über Uns',
        to: '/uber-uns',
        type: 'text',
      },

      {
        icon: 'fas fa-suitcase',
        name: 'Jobs',
        to: '/jobs',
        type: 'text',
      },

      {
        icon: 'far fa-question-circle',
        name: 'FAQ',
        to: '/faq',
        type: 'text',
      },

      {
        icon: 'fas fa-envelope',
        name: 'Kontakt',
        to: '/kontakt',
        type: 'text',
      },
    ],
    type: 'dropdown',
  }, */

  /* {
    icon: 'fas fa-shield-virus',
    name: 'Covid-19 Hygienekonzept',
    to: '/covid19-hygienekonzept',
    type: 'text',
  },

  {
    icon: 'far fa-file-alt',
    name: 'Magazin',
    to: '/magazin',
    type: 'text',
  }, */
];

export const desktopNavigationMenuItems = [
  {
    name: 'ÜBER UNS',
    to: '/uber-uns',
    type: 'text',
    isHidden: false
  },

  {
    name: 'FEATURES',
    to: '/features',
    type: 'text',
    isHidden: false
  },

  {
    name: 'HYGIENE',
    to: '/covid19-hygienekonzept',
    type: 'text',
    isHidden: false
  },

  {
    name: 'MAGAZIN',
    to: '/magazin',
    type: 'text',
    isHidden: false
  },

  {
    name: 'JOBS',
    to: '/jobs',
    type: 'text',
    isHidden: false
  },

  {
    name: 'JETZT BUCHEN',
    to: '/',
    type: 'button',
    isHidden: true
  },

  {
    // since for the "LOGIN" button, "LoginButton" component will be rendered in "NavigationItem" component, there is no need for assigning "to" and "type" properties
    name: 'LOGIN'
  }

  /* {
    name: 'ÜBER ROADJET',
    subLinks: [
      {
        name: 'Über Uns',
        to: '/uber-uns',
        type: 'text',
      },

      {
        name: 'Jobs',
        to: '/jobs',
        type: 'text',
      },

      {
        name: 'FAQ',
        to: '/faq',
        type: 'text',
      },

      {
        name: 'Kontakt',
        to: '/kontakt',
        type: 'text',
      },
    ],
    type: 'dropdown',
  }, */

  /* {
    name: 'COVID-19',
    to: '/covid19-hygienekonzept',
    type: 'text',
  },

  {
    name: 'MAGAZIN',
    to: '/magazin',
    type: 'text',
  }, */
];
