import { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import './Modal.scss';

import { Card } from '.';

const Modal = ({
  bgColor,
  children,
  customClass,
  isFixedHeight,
  isModalActivated,
  isCityPickerModal
}) => {
  const modalRef = useRef();

  const modalNode = document.querySelector('#custom-modal');
  const modalContent = (
    <Card
      cardClass={`custom-modal position-fixed bg-${bgColor} border d-none ${
        isFixedHeight ? 'date-picker-modal' : ''
      } ${isCityPickerModal ? 'city-picker-modal' : ''} ${customClass}`}
      radiusSize="-md"
      modalRef={modalRef}
      shadow="none">
      {children}
    </Card>
  );

  // We need to add modal-open class to body element for preventing background scrolling
  useEffect(() => {
    isModalActivated
      ? document.body.classList.add('modal-open')
      : document.body.classList.remove('modal-open');
  }, [isModalActivated]);

  useEffect(() => {
    let timer;
    if (modalRef.current) {
      if (isModalActivated) {
        modalRef.current.classList.add('custom-modal-fade-in');
        modalRef.current.classList.remove('d-none');
        modalRef.current.classList.remove('custom-modal-fade-out');
      } else {
        if (!modalRef.current.classList.contains('d-none')) {
          modalRef.current.classList.add('custom-modal-fade-out');
          modalRef.current.classList.remove('custom-modal-fade-in');

          timer = setTimeout(() => {
            modalRef.current.classList.add('d-none');
          }, 750);
        }
      }
    }

    return () => clearTimeout(timer);
  }, [isModalActivated, modalRef.current]);

  return createPortal(modalContent, modalNode);
};

Modal.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  customClass: PropTypes.string,
  isFixedHeight: PropTypes.bool,
  isCityPickerModal: PropTypes.bool,
  isModalActivated: PropTypes.bool,
  radiusSize: PropTypes.string
};

Modal.defaultProps = {
  bgColor: 'white',
  customClass: '',
  isFixedHeight: false,
  isCityPickerModal: false,
  isModalActivated: false,
  radiusSize: '-md'
};

export default Modal;
