import React from 'react';

import lazySizes from 'lazysizes';
import PropTypes from 'prop-types';
import 'lazysizes/plugins/attrchange/ls.attrchange';

lazySizes.loadMode = 1;

const Image = ({
  figureClass,
  id,
  imgAltText,
  imgClass,
  imgFallbackSrc,
  imgFit,
  imgScaleFactor,
  imgWebpSrc,
  isNotLazyLoaded,
  isRelativelyPositioned,
  isSvg
}) => {
  if (isNotLazyLoaded) {
    return (
      <figure
        className={`${figureClass} position-${
          isRelativelyPositioned ? 'relative' : 'absolute'
        } mb-0 overflow-hidden`}>
        <img
          style={{ transform: `scale(${imgScaleFactor})` }}
          src={imgFallbackSrc}
          srcSet={isSvg ? null : imgWebpSrc}
          alt={imgAltText}
          className={`${imgClass || 'w-100 h-100'} img-${imgFit}`}
          id={id}
        />
      </figure>
    );
  }

  return (
    <figure
      className={`${figureClass} position-${
        isRelativelyPositioned ? 'relative' : 'absolute'
      } mb-0 overflow-hidden`}>
      <img
        style={{ transform: `scale(${imgScaleFactor})` }}
        data-src={imgFallbackSrc}
        data-srcset={isSvg ? null : imgWebpSrc}
        alt={imgAltText}
        className={`${imgClass || 'w-100 h-100'} img-${imgFit} lazyload`}
        id={id}
      />
    </figure>
  );
};

Image.propTypes = {
  figureClass: PropTypes.string,
  id: PropTypes.string,
  imgAltText: PropTypes.string.isRequired,
  imgClass: PropTypes.string,
  imgFallbackSrc: PropTypes.string.isRequired,
  imgFit: PropTypes.string,
  imgScaleFactor: PropTypes.number,
  imgWebpSrc: PropTypes.string,
  isNotLazyLoaded: PropTypes.bool,
  isRelativelyPositioned: PropTypes.bool,
  isSvg: PropTypes.bool
};

Image.defaultProps = {
  figureClass: '',
  id: null,
  imgClass: null,
  imgFit: 'cover',
  imgScaleFactor: 1,
  imgWebpSrc: null,
  isNotLazyLoaded: false,
  isRelativelyPositioned: false,
  isSvg: false
};

export default Image;
