const actionTypes = {
  SET_FULLNAME: 'SET_FULLNAME',
  SET_EMAIL: 'SET_EMAIL',
  SET_MESSAGE: 'SET_MESSAGE',

  SET_PAYER_EMAIL: 'SET_PAYER_EMAIL',
  SET_PAYER_FIRSTNAME: 'SET_PAYER_FIRSTNAME',
  SET_PAYER_LASTNAME: 'SET_PAYER_LASTNAME',
  SET_PAYER_BIRTHDATE: 'SET_PAYER_BIRTHDATE',
  SET_PAYER_PHONE: 'SET_PAYER_PHONE',

  SET_PASSENGER1_EMAIL: 'SET_PASSENGER1_EMAIL',
  SET_PASSENGER1_FIRSTNAME: 'SET_PASSENGER1_FIRSTNAME',
  SET_PASSENGER1_LASTNAME: 'SET_PASSENGER1_LASTNAME',

  SET_PASSENGER2_EMAIL: 'SET_PASSENGER2_EMAIL',
  SET_PASSENGER2_FIRSTNAME: 'SET_PASSENGER2_FIRSTNAME',
  SET_PASSENGER2_LASTNAME: 'SET_PASSENGER2_LASTNAME',

  SET_PASSENGER3_EMAIL: 'SET_PASSENGER3_EMAIL',
  SET_PASSENGER3_FIRSTNAME: 'SET_PASSENGER3_FIRSTNAME',
  SET_PASSENGER3_LASTNAME: 'SET_PASSENGER3_LASTNAME',

  SET_PASSENGER4_EMAIL: 'SET_PASSENGER4_EMAIL',
  SET_PASSENGER4_FIRSTNAME: 'SET_PASSENGER4_FIRSTNAME',
  SET_PASSENGER4_LASTNAME: 'SET_PASSENGER4_LASTNAME',

  SET_PASSENGER5_EMAIL: 'SET_PASSENGER5_EMAIL',
  SET_PASSENGER5_FIRSTNAME: 'SET_PASSENGER5_FIRSTNAME',
  SET_PASSENGER5_LASTNAME: 'SET_PASSENGER5_LASTNAME',

  SET_COMPANY_NAME: 'SET_COMPANY_NAME',
  SET_COMPANY_PHONE: 'SET_COMPANY_PHONE',
  SET_COMPANY_ADDRESS: 'SET_COMPANY_ADDRESS',
  SET_COMPANY_ZIPCODE: 'SET_COMPANY_ZIPCODE',
  SET_COMPANY_VATNO: 'SET_COMPANY_VATNO',

  SET_CONTACT_FIRSTNAME: 'SET_CONTACT_FIRSTNAME',
  SET_CONTACT_LASTNAME: 'SET_CONTACT_LASTNAME',
  SET_CONTACT_FULLNAME: 'SET_CONTACT_FULLNAME',
  SET_CONTACT_EMAIL: 'SET_CONTACT_EMAIL',
  SET_CONTACT_PHONE: 'SET_CONTACT_PHONE',
  SET_CORPORATE__COMPANY_NAME: 'SET_CORPORATE__COMPANY_NAME',
  SET_CORPORATE__COMPANY_POSITION: 'SET_CORPORATE__COMPANY_POSITION',
  SET_CONTACT_MESSAGE: 'SET_CONTACT_MESSAGE',

  SET_TICKET_ID: 'SET_TICKET_ID',
  SET_CANCELLATION_SURNAME: 'SET_CANCELLATION_SURNAME',
  SET_BOOKING_ID: 'SET_BOOKING_ID',

  SET_PROFILE_EMAIL: 'SET_PROFILE_EMAIL',
  SET_PROFILE_NAME: 'SET_PROFILE_NAME',
  SET_PROFILE_SURNAME: 'SET_PROFILE_SURNAME',
  SET_PROFILE_PHONE: 'SET_PROFILE_PHONE',
  SET_PROFILE_STREET: 'SET_PROFILE_STREET',
  SET_PROFILE_ZIPCODE: 'SET_PROFILE_ZIPCODE',
  SET_PROFILE_OLDPASSWORD: 'SET_PROFILE_OLDPASSWORD',
  SET_PROFILE_NEWPASSWORD: 'SET_PROFILE_NEWPASSWORD',
  SET_PROFILE_CONFIRMNEWPASSWORD: 'SET_PROFILE_CONFIRMNEWPASSWORD',

  SET_PROFILE_COUNTRY: 'SET_PROFILE_COUNTRY',
  SET_PROFILE_CITY: 'SET_PROFILE_CITY',
  SET_PROFILE_COUNTRY_REGIONS: 'SET_PROFILE_COUNTRY_REGIONS',

  CHECK_FULLNAME_VALIDITY: 'CHECK_FULLNAME_VALIDITY',
  CHECK_EMAIL_VALIDITY: 'CHECK_EMAIL_VALIDITY',
  CHECK_MESSAGE_VALIDITY: 'CHECK_MESSAGE_VALIDITY',

  CHECK_PAYER_EMAIL_VALIDITY: 'CHECK_PAYER_EMAIL_VALIDITY',
  CHECK_PAYER_FIRSTNAME_VALIDITY: 'CHECK_PAYER_FIRSTNAME_VALIDITY',
  CHECK_PAYER_LASTNAME_VALIDITY: 'CHECK_PAYER_LASTNAME_VALIDITY',
  CHECK_PAYER_BIRTHDATE_VALIDITY: 'CHECK_PAYER_BIRTHDATE_VALIDITY',
  CHECK_PAYER_PHONE_VALIDITY: 'CHECK_PAYER_PHONE_VALIDITY',

  CHECK_PASSENGER1_FIRSTNAME_VALIDITY: 'CHECK_PASSENGER1_FIRSTNAME_VALIDITY',
  CHECK_PASSENGER1_LASTNAME_VALIDITY: 'CHECK_PASSENGER1_LASTNAME_VALIDITY',

  CHECK_PASSENGER2_FIRSTNAME_VALIDITY: 'CHECK_PASSENGER2_FIRSTNAME_VALIDITY',
  CHECK_PASSENGER2_LASTNAME_VALIDITY: 'CHECK_PASSENGER2_LASTNAME_VALIDITY',

  CHECK_PASSENGER3_FIRSTNAME_VALIDITY: 'CHECK_PASSENGER3_FIRSTNAME_VALIDITY',
  CHECK_PASSENGER3_LASTNAME_VALIDITY: 'CHECK_PASSENGER3_LASTNAME_VALIDITY',

  CHECK_PASSENGER4_FIRSTNAME_VALIDITY: 'CHECK_PASSENGER4_FIRSTNAME_VALIDITY',
  CHECK_PASSENGER4_LASTNAME_VALIDITY: 'CHECK_PASSENGER4_LASTNAME_VALIDITY',

  CHECK_PASSENGER5_FIRSTNAME_VALIDITY: 'CHECK_PASSENGER5_FIRSTNAME_VALIDITY',
  CHECK_PASSENGER5_LASTNAME_VALIDITY: 'CHECK_PASSENGER5_LASTNAME_VALIDITY',

  CHECK_COMPANY_NAME_VALIDITY: 'CHECK_COMPANY_NAME_VALIDITY',
  CHECK_COMPANY_PHONE_VALIDITY: 'CHECK_COMPANY_PHONE_VALIDITY',
  CHECK_COMPANY_ADDRESS_VALIDITY: 'CHECK_COMPANY_ADDRESS_VALIDITY',
  CHECK_COMPANY_ZIPCODE_VALIDITY: 'CHECK_COMPANY_ZIPCODE_VALIDITY',

  CHECK_CONTACT_FIRSTNAME_VALIDITY: 'CHECK_CONTACT_FIRSTNAME_VALIDITY',
  CHECK_CONTACT_LASTNAME_VALIDITY: 'CHECK_CONTACT_LASTNAME_VALIDITY',
  CHECK_CONTACT_FULLNAME_VALIDITY: 'CHECK_CONTACT_FULLNAME_VALIDITY',
  CHECK_CONTACT_EMAIL_VALIDITY: 'CHECK_CONTACT_EMAIL_VALIDITY',
  CHECK_CONTACT_PHONE_VALIDITY: 'CHECK_CONTACT_PHONE_VALIDITY',
  CHECK_CORPORATE__COMPANY_NAME_VALIDITY: 'CHECK_CORPORATE__COMPANY_NAME_VALIDITY',
  CHECK_CORPORATE__COMPANY_POSITION_VALIDITY: 'CHECK_CORPORATE__COMPANY_POSITION_VALIDITY',
  CHECK_CONTACT_MESSAGE_VALIDITY: 'CHECK_CONTACT_MESSAGE_VALIDITY',

  CHECK_TICKET_ID_VALIDITY: 'CHECK_TICKET_ID_VALIDITY',
  CHECK_CANCELLATION_SURNAME_VALIDITY: 'CHECK_CANCELLATION_SURNAME_VALIDITY',
  CHECK_BOOKING_ID_VALIDITY: 'CHECK_BOOKING_ID_VALIDITY',

  CHECK_PROFILE_EMAIL_VALIDITY: 'CHECK_PROFILE_EMAIL_VALIDITY',
  CHECK_PROFILE_NAME_VALIDITY: 'CHECK_PROFILE_NAME_VALIDITY',
  CHECK_PROFILE_SURNAME_VALIDITY: 'CHECK_PROFILE_SURNAME_VALIDITY',
  CHECK_PROFILE_PHONE_VALIDITY: 'CHECK_PROFILE_PHONE_VALIDITY',
  CHECK_PROFILE_STREET_VALIDITY: 'CHECK_PROFILE_STREET_VALIDITY',
  CHECK_PROFILE_ZIPCODE_VALIDITY: 'CHECK_PROFILE_ZIPCODE_VALIDITY',
  CHECK_PROFILE_OLDPASSWORD_VALIDITY: 'CHECK_PROFILE_OLDPASSWORD_VALIDITY',
  CHECK_PROFILE_NEWPASSWORD_VALIDITY: 'CHECK_PROFILE_NEWPASSWORD_VALIDITY',
  CHECK_PROFILE_CONFIRMNEWPASSWORD_VALIDITY: 'CHECK_PROFILE_CONFIRMNEWPASSWORD_VALIDITY'
};

export const dateMonths = {
  '01': 'Januar',
  '02': 'Februar',
  '03': 'März',
  '04': 'April',
  '05': 'Mai',
  '06': 'Juni',
  '07': 'Juli',
  '08': 'August',
  '09': 'September',
  10: 'Oktober',
  11: 'November',
  12: 'Dezember'
};

export const dayNames = {
  Mon: 'Montag',
  Tue: 'Dienstag',
  Wed: 'Mittwoch',
  Thu: 'Donnerstag',
  Fri: 'Freitag',
  Sat: 'Samstag',
  Sun: 'Sonntag'
};

export const API_METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete'
};

export default actionTypes;
