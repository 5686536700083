import { useReducer, useEffect, useContext } from 'react';

import {
  actionTypes,
  cityImages,
  dateLocaleOptions,
  initialState,
  parseDate
} from '../components/SearchArea/util';
import SearchContext from '../context/SearchContext';
import { sweetAlert } from '../utils';

import { useRoadJetAPI } from '.';

/**
 * this reducer function is responsible for respectively updating the
 * required slice of state whenever corresponding action is dispatched
 */
const searchAreaReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_ARRIVAL_CITY:
      return {
        ...state,
        arrivalCity: {
          ...state.arrivalCity,
          ...action.arrivalCity
        }
      };

    //NEW-30-06-21
    case actionTypes.SET_ARRIVAL_BUS_STOP:
      return {
        ...state,
        arrivalBusStop: {
          ...state.arrivalBusStop,
          ...action.arrivalBusStop
        }
      };

    case actionTypes.SET_DEPARTURE_CITY:
      return {
        ...state,
        departureCity: {
          ...state.departureCity,
          ...action.departureCity
        }
      };

    //NEW-30-06-21
    case actionTypes.SET_DEPARTURE_BUS_STOP:
      return {
        ...state,
        departureBusStop: {
          ...state.departureBusStop,
          ...action.departureBusStop
        }
      };

    case actionTypes.SET_DEPARTURE_DATE:
      return {
        ...state,
        departureDate: action.departureDate
      };

    case actionTypes.SET_ARRIVAL_DATE:
      return {
        ...state,
        arrivalDate: action.arrivalDate
      };

    case actionTypes.SET_ONE_WAY:
      return {
        ...state,
        isRoundTrip: 0
      };

    case actionTypes.SET_ROUND_TRIP:
      return {
        ...state,
        isRoundTrip: 1
      };

    case actionTypes.SET_RETURN_ROUTE_AVAILABLE:
      return {
        ...state,
        isReturnRouteAvailable: true
      };

    case actionTypes.SET_RETURN_ROUTE_IS_NOT_AVAILABLE:
      return {
        ...state,
        isReturnRouteAvailable: false
      };

    case actionTypes.SET_PASSENGER_COUNT:
      return {
        ...state,
        passengerCount: action.passengerCount
      };

    case actionTypes.SET_EXCHANGE_CITIES:
      return {
        ...state,
        departureCity: {
          ...state.departureCity,
          ...state.arrivalCity
        },
        arrivalCity: {
          ...state.arrivalCity,
          ...state.departureCity
        },
        departureBusStop: {
          ...state.departureBusStop,
          ...state.arrivalBusStop
        },
        arrivalBusStop: {
          ...state.arrivalBusStop,
          ...state.departureBusStop
        }
      };

    default:
      return state;
  }
};

const useSearch = () => {
  const [APICities] = useRoadJetAPI('/busStops');

  const { searchData, setSearchData, isContextStarted, setContextStarted } =
    useContext(SearchContext);

  const [searchState, searchDispatch] = useReducer(
    searchAreaReducer,
    isContextStarted ? searchData : initialState
  );

  // This useEffect function is used for setting search inputs according to search context which has recent search data
  useEffect(() => {
    // Check busStops is fetched from API?
    if (APICities) {
      setContextStarted(true);
    }
  }, [APICities]);

  // This useEffect updates context when search criterias change
  useEffect(() => {
    isContextStarted ? setSearchData(searchState) : setSearchData(initialState);
  }, [searchState]);

  let busStopOptions = [];
  let cityOptions = [];

  APICities?.map((city) => {
    cityOptions.push(city);
    if (city?.busStops?.length > 1) {
      busStopOptions.push({ id: 9999, cityId: city.id, name: `${city.name} Alle` });
      return city?.busStops?.map((busStop) => busStopOptions.push(busStop));
    }

    return city?.busStops?.map((busStop) => busStopOptions.push(busStop));
  });

  // console.log(busStopOptions);

  // console.log(cityOptions);

  /**
   * this handler function is responsible for setting trip status to "one-way"
   */
  const setOneWayHandler = (event) => {
    event.stopPropagation();
    searchDispatch({
      type: actionTypes.SET_ONE_WAY
    });
  };

  /**
   * this handler function is responsible for setting trip status to "round-trip"
   */
  const setRoundTripHandler = () => {
    if (searchState.isReturnRouteAvailable) {
      searchDispatch({
        type: actionTypes.SET_ROUND_TRIP
      });
    } else {
      sweetAlert('error', 'Rückfahrt ist nicht vorhanden!');
    }
  };

  const setReturnRouteAvailable = (status) => {
    if (status) {
      searchDispatch({
        type: actionTypes.SET_RETURN_ROUTE_AVAILABLE
      });
    } else {
      searchDispatch({
        type: actionTypes.SET_RETURN_ROUTE_IS_NOT_AVAILABLE
      });
    }
  };

  /**
   * this handler function is responsible for setting passenger count based on clicked icon (either plus or minus)
   */
  const setPassengerCountHandler = ({ currentTarget }) => {
    if (currentTarget.id === 'minus') {
      // if minus icon is clicked,
      if (searchState.passengerCount > 1) {
        // if total number of passengers is not less than 1 yet,
        searchDispatch({
          // then decrement the current passenger count by dispatching required action
          type: actionTypes.SET_PASSENGER_COUNT,
          passengerCount: searchState.passengerCount - 1
        });
      }
    } else if (currentTarget.id === 'plus') {
      // if plus icon is clicked,
      if (searchState.passengerCount < 5) {
        // if total number of passengers is not greater than 5 yet,
        searchDispatch({
          // then increment the current passenger count by dispatching required action
          type: actionTypes.SET_PASSENGER_COUNT,
          passengerCount: searchState.passengerCount + 1
        });
      }
    }
  };

  /**
   * this handler function is responsible for exchanging departure and arrival cities
   */
  const exchangeCitiesHandler = () => {
    if (searchState.isReturnRouteAvailable) {
      searchDispatch({
        type: actionTypes.SET_EXCHANGE_CITIES
      });
    } else {
      sweetAlert('error', 'Die Route ist nicht vorhanden!');
    }
  };

  /**
   * this handler function is responsible for setting departure date
   */
  const setDepartureDateHandler = async (date) => {
    const { moment } = await import('../components/ReactDatePicker/util');

    if (searchState.arrivalDate !== '' && moment(date).isAfter(moment(searchState.arrivalDate))) {
      searchDispatch({
        type: actionTypes.SET_ARRIVAL_DATE,
        arrivalDate: date
      });
    }

    searchDispatch({
      type: actionTypes.SET_DEPARTURE_DATE,
      departureDate: date
    });
  };

  /**
   * this handler function is responsible for setting arrival date
   */
  const setArrivalDateHandler = (date) => {
    searchDispatch({
      type: actionTypes.SET_ARRIVAL_DATE,
      arrivalDate: date
    });
  };

  /**
   * this handler function is responsible for setting departure city
   */

  // const setDepartureCityHandler = (cityName) => {
  //   const selectedDepartureCity = APICities.find((city) => city.name === cityName);
  //   const selectedDepartureCityImage = cityImages.find((city) => city.name === cityName);

  //   searchDispatch({
  //     type: actionTypes.SET_DEPARTURE_CITY,
  //     departureCity: {
  //       ...selectedDepartureCity,
  //       ...selectedDepartureCityImage
  //     }
  //   });
  // };

  /**
   * this handler function is responsible for setting bus stop city
   */

  //NEW-30-06-21
  const setDepartureBusStopHandler = (busStopName) => {
    const selectedDepartureBusStop = busStopOptions.find((busStop) => busStop.name === busStopName);
    const selectedDepartureCity = cityOptions.find(
      (city) => city.id === selectedDepartureBusStop?.cityId
    );
    const selectedDepartureCityImage = cityImages.find(
      (city) => city.name === selectedDepartureCity?.name
    );

    searchDispatch({
      type: actionTypes.SET_DEPARTURE_BUS_STOP,
      departureBusStop: {
        ...selectedDepartureBusStop
      }
    });

    searchDispatch({
      type: actionTypes.SET_DEPARTURE_CITY,
      departureCity: {
        ...selectedDepartureCity,
        ...selectedDepartureCityImage
      }
    });
  };

  /**
   * this handler function is responsible for setting arrival city
   */
  // const setArrivalCityHandler = (cityName) => {
  //   const selectedArrivalCity = APICities.find((city) => city.name === cityName);
  //   const selectedArrivalCityImage = cityImages.find((city) => city.name === cityName);

  //   searchDispatch({
  //     type: actionTypes.SET_ARRIVAL_CITY,
  //     arrivalCity: {
  //       ...selectedArrivalCity,
  //       ...selectedArrivalCityImage
  //     }
  //   });
  // };

  //NEW-30-06-21
  const setArrivalBusStopHandler = (busStopName) => {
    const selectedArrivalBusStop = busStopOptions.find((busStop) => busStop.name === busStopName);
    const selectedArrivalCity = cityOptions.find(
      (city) => city.id === selectedArrivalBusStop?.cityId
    );
    const selectedArrivalCityImage = cityImages.find(
      (city) => city.name === selectedArrivalCity?.name
    );

    searchDispatch({
      type: actionTypes.SET_ARRIVAL_BUS_STOP,
      arrivalBusStop: {
        ...selectedArrivalBusStop
      }
    });

    searchDispatch({
      type: actionTypes.SET_ARRIVAL_CITY,
      arrivalCity: {
        ...selectedArrivalCity,
        ...selectedArrivalCityImage
      }
    });
  };

  /**
   * in order to properly used available dates, they need to be parsed first.
   */
  const splittedArrivalDate = parseDate(
    searchState.arrivalDate.toLocaleDateString('de-DE', dateLocaleOptions)
  );
  const splittedDepartureDate = parseDate(
    searchState.departureDate.toLocaleDateString('de-DE', dateLocaleOptions)
  );

  return {
    searchState,
    setOneWayHandler,
    setRoundTripHandler,
    setPassengerCountHandler,
    exchangeCitiesHandler,
    setArrivalDateHandler,
    setDepartureDateHandler,
    // setArrivalCityHandler,
    // setDepartureCityHandler,
    setArrivalBusStopHandler,
    setDepartureBusStopHandler,
    setReturnRouteAvailable,
    splittedArrivalDate,
    splittedDepartureDate
  };
};

export default useSearch;
