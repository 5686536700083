import React from 'react';

import PropTypes from 'prop-types';

const Card = ({ cardClass, children, id, isClickable, onClick, radiusSize, modalRef, shadow }) => {
  if (isClickable) {
    return (
      <div
        aria-label="Clickable card"
        id={id}
        className={`${cardClass} rounded-${radiusSize} shadow-${shadow}`}
        onClick={onClick}
        role="button"
        tabIndex="0">
        {children}
      </div>
    );
  }

  return (
    <div id={id} className={`${cardClass} rounded${radiusSize} shadow-${shadow}`} ref={modalRef}>
      {children}
    </div>
  );
};

Card.propTypes = {
  cardClass: PropTypes.string,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  isClickable: PropTypes.bool,
  onClick: PropTypes.func,
  radiusSize: PropTypes.string,
  shadow: PropTypes.string
};

Card.defaultProps = {
  cardClass: null,
  id: null,
  isClickable: false,
  onClick: null,
  radiusSize: '-sm',
  shadow: 'light'
};

export default Card;
