import React from 'react';

import PropTypes from 'prop-types';

import '../../scss/error.scss';

import { networkErrorImage, maintenanceErrorImage } from './util';

import { Header, Image } from '.';

const Error = ({ errorType }) => (
  <>
    <Header />
    <div className="position-relative">
      <main className="custom-error">
        <div className="container-fluid h-100">
          <div className="row custom-error-row pt-5">
            <div className="grid-col col-24 col-lg-12 order-md-2 order-lg-1">
              {errorType === 'network' && <h1 className="custom-error-title">500</h1>}
              <h2 className="custom-error-sub-title">Entschuldige bitte,</h2>
              <p className="custom-error-text">
                Unsere IT bringt gerade wieder Speed auf die Straße. Wir sind gleich wieder für Dich
                da.
              </p>
            </div>
            <div className="grid-col col-24 col-lg-12 order-md-1 order-lg-2">
              {errorType === 'network' ? (
                <Image
                  figureClass="custom-error-image"
                  imgAltText={networkErrorImage.altText}
                  imgFallbackSrc={networkErrorImage.source}
                  imgFit="contain"
                  isRelativelyPositioned
                  isSvg
                />
              ) : (
                <Image
                  figureClass="custom-error-image"
                  imgAltText={maintenanceErrorImage.altText}
                  imgFallbackSrc={maintenanceErrorImage.source}
                  imgFit="contain"
                  isRelativelyPositioned
                  isSvg
                />
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  </>
);

Error.propTypes = {
  errorType: PropTypes.string
};

Error.defaultProps = {
  errorType: 'network'
};

export default Error;
