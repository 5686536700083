import { useState } from 'react';

import './TicketCancellationCard.scss';
import { Button, Input } from '.';

const TCCHeaderDesktop = ({
  bookingId,
  createdAt,
  isAllCancelled,
  isChecked,
  isCancelButtonDisabled,
  isContentHidden,
  isOnDashboard,
  isSelectable,
  onCancelButtonClick,
  onChange,
  onToggleContentShow,
  payerName,
  setIsSelectable,
  type
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <div className="ticket-cancellation-card-header d-flex align-content-center ">
      {type === 'booking-id' ? (
        <div
          className={`ticket-cancellation-card-title d-inline-flex align-content-center  py-2 px-6  ${
            isOnDashboard ? 'on-dashboard-desktop justify-content-between w-100' : ''
          } ${isAllCancelled ? 'cancelled-booking-header' : ''}`}>
          <div>
            <span
              className={`${
                isAllCancelled ? 'd-none' : !isSelectable ? 'd-none' : 'd-inline-block'
              }  my-auto`}>
              <Input
                customClass="mr-3"
                htmlFor="selectAll"
                id="selectAll"
                isBorderedCheckbox
                isChecked={isChecked}
                name="selectAll"
                onChange={onChange}
                type="checkbox"
              />
            </span>

            <div className="d-inline-block my-auto">
              <span className="mr-3 font-weight-bold my-auto">Buchungsnummer: </span>
              <span className="mr-3 text-primary font-weight-bold my-auto">#{bookingId}</span>
              <span className="my-auto font-14">{isOnDashboard ? createdAt : payerName}</span>
              {isOnDashboard &&
                (isSelectable ? (
                  <span className="d-inline-flex ml-3">
                    <Button
                      className="cancel-button-outline mr-3 d-inline-block"
                      isRelativelyPositioned
                      caption="Stornieren"
                      size="sm"
                      type="outline-danger"
                      color="danger"
                      borderRadiusSize="4px"
                      isDisabled={isCancelButtonDisabled}
                      onClick={onCancelButtonClick}
                    />
                    <button
                      className="btn font-14 d-flex align-content-center"
                      onClick={() => {
                        setIsSelectable(false);
                      }}>
                      <i className="fas fa-times"></i>
                    </button>
                  </span>
                ) : (
                  <div className="dropdown d-inline-block my-auto ml-3">
                    <button
                      className="btn d-flex"
                      type="button"
                      onClick={() => setIsMenuOpen(true)}>
                      <i className="fas fa-ellipsis-v"></i>
                    </button>
                    <div
                      className={`${
                        isMenuOpen ? 'd-block' : 'd-none'
                      }  p-1 position-absolute bg-light-container dropdown-menu`}>
                      <div className="dropdown-item d-flex">
                        <button className="btn btn-sm " onClick={() => setIsMenuOpen(false)}>
                          <i className="fas fa-times"></i>
                        </button>
                        <button
                          className={'btn text-secondary font-14'}
                          disabled={isAllCancelled}
                          onClick={() => {
                            setIsMenuOpen(false);
                            return !isAllCancelled && setIsSelectable(true);
                          }}>
                          Bearbeiten
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {isOnDashboard && (
            <>
              <div className="toggle-button-booking my-auto ml-6">
                <i
                  className={`fas fa-chevron-${isContentHidden ? 'down' : 'up'}`}
                  role="button"
                  tabIndex={0}
                  onClick={onToggleContentShow}></i>
              </div>
            </>
          )}
        </div>
      ) : (
        ''
      )}

      {!isOnDashboard && (
        <div className="d-inline-flex py-2 mx-2">
          <Button
            className="my-auto"
            isRelativelyPositioned
            caption="Stornieren"
            size="sm"
            type="danger"
            borderRadiusSize="4px"
            isDisabled={isCancelButtonDisabled}
            onClick={onCancelButtonClick}
          />
        </div>
      )}
    </div>
  );
};

export default TCCHeaderDesktop;
