import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import ReactPhoneInput from 'react-phone-input-2';

import { preferredCountries } from './util';

import 'react-phone-input-2/lib/style.css';
import './PhoneInput.scss';

const PhoneInput = ({ htmlFor, id, isPhoneInputError, name, onChange, value }) => {
  const phoneInputProps = useMemo(() => {
    return {
      htmlFor,
      id,
      name,
      required: true
    };
  }, [htmlFor, id, name]);

  return (
    <ReactPhoneInput
      containerClass={`${isPhoneInputError ? 'text-input--error' : ''} mb-${
        isPhoneInputError ? '1' : '4'
      }`}
      country={'de'}
      inputClass="phone-input"
      inputProps={phoneInputProps}
      onChange={onChange}
      preferredCountries={preferredCountries}
      value={value}
    />
  );
};

PhoneInput.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isPhoneInputError: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

PhoneInput.defaultProps = {
  isPhoneInputError: false
};

export default PhoneInput;
