import React, { useState } from 'react';

import PropTypes from 'prop-types';

import './NavigationItem.scss';

import { Backdrop, Button, Icon, LoginButton } from '.';

/**
 * this component is used to render all navigation menu items for mobile version. detailed explanations for each prop is given below:
 * --expandableMenuItems: (for now, only used for rendering "Support" menu item as dropdown) it is an array of sub menu items
 * --iconClass: fontAwesome class names for rendering respective icon for each navigation menu item
 * --isDesktop: boolean prop for which it is used to determine whether rendered navigation items are for mobile/tablet or desktop
 * --isExpandable: (for now, only used for rendering "Support" menu item as dropdown) set to false as default and it is only set to true for the menu item "Support" in order to state that this menu item is an expandable item whose sub content will be displayed upon expansion
 * --itemName: caption for each menu item
 * --to: URL string for each menu item in order for redirection upon clicking
 */
const NavigationItem = ({
  expandableMenuItems,
  isButton,
  isDesktop,
  isExpandable,
  isItemHidden,
  itemName,
  onToggleAuthBox,
  to
}) => {
  const [toggleMenuItem, setToggleMenuItem] = useState(false); // state to control open/close state of dropdown menu item (e.g. "Support")

  // this handler function is responsible for toggling dropdown menu item content
  const toggleMenuItemHandler = () => setToggleMenuItem((prevState) => !prevState);

  // navigation item rendered for mobile/tablet version
  if (!isDesktop) {
    if (isExpandable) {
      // if menu item is expandable, render the navigation item as a dropdown
      return (
        <>
          <Button
            customClass="nav-item--mobile nav-item--expandable my-0 mx-2 p-2"
            horizontalAlignment="start"
            isRelativelyPositioned
            onClick={toggleMenuItemHandler}>
            {/* <Icon
              color="white"
              fontAwesomeIconClass={iconClass}
              fontAwesomeIconSize="14"
              isRelativelyPositioned
            /> */}
            <span className="text-white  mr-2 ">{itemName}</span>
            <Icon
              color="white"
              fontAwesomeIconClass={`fas fa-chevron-${toggleMenuItem ? 'up' : 'down'}`}
              fontAwesomeIconSize="12"
              iconBaseClass="chevron"
              isRelativelyPositioned
            />
          </Button>

          <div
            role="button"
            tabIndex="0"
            aria-label="Expandable Menu Item for Mobile"
            onClick={toggleMenuItemHandler}
            className={`expandable-item pl-5 d-${toggleMenuItem ? 'block' : 'none'} ${
              toggleMenuItem ? 'expanded' : 'shrinked'
            }`}>
            {expandableMenuItems.map((item) => (
              <div key={item.name}>
                <Button
                  customClass="nav-item--mobile my-0 mx-2 p-2 d-flex justify-content-start align-items-center"
                  to={item.to}>
                  <Icon
                    color="white"
                    fontAwesomeIconClass={item.icon}
                    fontAwesomeIconSize="14"
                    isRelativelyPositioned
                  />
                  <span className="text-white ml-2 font-14">{item.name}</span>
                </Button>
              </div>
            ))}
          </div>
        </>
      );
    }

    return (
      // if menu item is NOT expandable, render it as a normal link
      <Button
        customClass={`nav-menu-item nav-item--mobile my-1 mx-2 p-2 d-${
          isItemHidden ? 'none' : 'flex'
        } align-items-center`}
        to={to}>
        {/* <Icon color="white" fontAwesomeIconClass={iconClass} fontAwesomeIconSize="14" isRelativelyPositioned /> */}
        <span className="text-white font-16">{itemName}</span>
      </Button>
    );
  }

  // navigation item rendered for desktop version
  if (isDesktop) {
    if (isExpandable) {
      // if menu item is expandable, render the navigation item as a dropdown
      return (
        <>
          <Backdrop isActivated={toggleMenuItem} isTransparent onDismiss={toggleMenuItemHandler} />
          <div className="position-relative">
            <Button
              customClass="d-flex justify-content-center align-items-center mr-3 font-16 pl-0"
              isRelativelyPositioned
              onClick={toggleMenuItemHandler}>
              <span className="text-secondary mr-1">{itemName}</span>
              <Icon
                color="secondary"
                fontAwesomeIconClass="fas fa-caret-down"
                fontAwesomeIconSize="16"
                iconBaseClass="caret-down"
                isRelativelyPositioned
              />
            </Button>

            <div
              className={`expandable-menu-items-wrapper--desktop position-absolute bg-white d-flex flex-column justify-content-center align-items-start ${
                toggleMenuItem ? 'expanded' : 'shrinked'
              }--desktop p-2`}>
              {expandableMenuItems.map((item) => (
                <Button
                  key={item.name}
                  customClass={`dropdown-menu-item mb-2 text-decoration-none text-secondary font-14 d-${
                    toggleMenuItem ? 'inline' : 'none'
                  }`}
                  to={item.to}>
                  {item.name}
                </Button>
              ))}
            </div>
          </div>
        </>
      );
    }

    return (
      // if menu item is NOT expandable, render it as a normal link
      <>
        {itemName !== 'LOGIN' && (
          <Button
            customClass={`nav-menu-item nav-item--${
              isButton ? 'button' : 'desktop'
            } position-relative bg-${isButton ? 'primary' : 'transparent'} d-${
              isItemHidden ? 'none' : 'flex'
            } justify-content-center align-items-center mr-4 ${isButton ? '' : 'text-secondary'}`}
            to={to}>
            {itemName}
          </Button>
        )}
        {itemName === 'JETZT BUCHEN' && <div className="vertical-separator border-left mr-3" />}
        {itemName === 'LOGIN' && (
          <LoginButton isRelativelyPositioned onToggleAuthBox={onToggleAuthBox} />
        )}
      </>
    );
  }
};

NavigationItem.propTypes = {
  expandableMenuItems: PropTypes.arrayOf(PropTypes.object),
  iconClass: PropTypes.string,
  isButton: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isExpandable: PropTypes.bool,
  isItemHidden: PropTypes.bool,
  itemName: PropTypes.string.isRequired,
  onToggleAuthBox: PropTypes.func,
  to: PropTypes.string
};

NavigationItem.defaultProps = {
  expandableMenuItems: null,
  iconClass: null,
  isButton: false,
  isDesktop: false,
  isExpandable: false,
  isItemHidden: false,
  onToggleAuthBox: null,
  to: null
};

export default NavigationItem;
