import roadjetLogoWhite from '../../../../static/assets/images/svg/logo/roadjet-logo-white.svg';

export { roadjetLogoWhite };

export const mobileMenuCloseIconProps = {
  color: 'white',
  id: 'menu-close-icon',
  name: 'times',
  position: {
    right: '1rem',
    top: '1.75rem'
  }
};
