import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import './MobileNavMenu.scss';

import { useUser } from '../../../../hooks';

import { mobileMenuCloseIconProps, roadjetLogoWhite } from './util';

import { Image, LogoutModal, Backdrop, Icon, NavigationItem } from '.';

/**
 * this component is served as the mobile navigation menu.
 * it will be activated upon clicking the burger button, which is placed at the top left of the mobile header.
 * it will be closed upon clicking either to the "Close" button located at the top right of the menu or to the Backdrop on the screen.
 * --isOpen: boolean prop to control open/close status of the mobile navigation menu
 * --menuItems: collection of navigation menu items, whose type is array
 * --onCloseMenu: reference to the handler function which is responsible for closing the mobile navigation menu. this is reference to "closeMobileNavMenuHandler" method, which is defined in Header.jsx
 */
const MobileNavMenu = ({ hideBuyTicketButton, isOpen, menuItems, onCloseMenu }) => {
  const [isLogoutModalActivated, setIsLogoutModalActivated] = useState(false);
  const { user, logout } = useUser();

  // We need to add modal-open class to body element for preventing background scrolling
  useEffect(() => {
    isOpen
      ? document.body.classList.add('modal-open')
      : document.body.classList.remove('modal-open');
  }, [isOpen]);
  return (
    <>
      <Backdrop isActivated={isOpen} onDismiss={onCloseMenu} />

      <nav
        className={`mobile-nav-menu position-fixed ${isOpen ? 'open-nav-menu' : 'close-nav-menu'}`}>
        <Icon
          color={mobileMenuCloseIconProps.color}
          id={mobileMenuCloseIconProps.id}
          name={mobileMenuCloseIconProps.name}
          position={mobileMenuCloseIconProps.position}
          onClick={onCloseMenu}
          type="custom"
        />

        <Image
          imgAltText="Roadjet White Colored Logo on Dark Background for Mobile Menu"
          figureClass={`roadjet-logo--white ml-3 ${user ? 'd-none' : 'mb-11'}`}
          imgFallbackSrc={roadjetLogoWhite}
          isRelativelyPositioned
          isSvg
        />

        {user && (
          <div className="text-center ">
            {user.profilePhotoUrl ? (
              <img
                src={user.profilePhotoUrl}
                className="rounded-circle p-0 user-image mb-3"
                alt="Profil"
              />
            ) : (
              <span className="rounded-circle border user-email-abbr p-0 d-inline-block text-white border-white font-22">
                {user.name || user.surname
                  ? `${user.name[0]?.toUpperCase() ?? ''}${user.surname[0]?.toUpperCase() ?? ''}`
                  : user.email[0].toUpperCase()}
              </span>
            )}

            <div className="text-white  font-14 mb-3 pt-2">
              {user.name || user.surname
                ? `${user.name?.toUpperCase() ?? ''} ${user.surname?.toUpperCase() ?? ''}`
                : user.email}
            </div>
          </div>
        )}

        {menuItems.map((menuItem) =>
          !user ? (
            menuItem.name !== 'Logout' && menuItem.name !== 'Profil' ? (
              <NavigationItem
                key={menuItem.name}
                expandableMenuItems={menuItem.links}
                iconClass={menuItem.icon}
                isExpandable={menuItem.type === 'dropdown'}
                isItemHidden={menuItem.isHidden && hideBuyTicketButton}
                itemName={menuItem.name}
                to={menuItem.to}
              />
            ) : (
              ''
            )
          ) : (
            menuItem.name !== 'Einloggen / Anmelden' && (
              <NavigationItem
                key={menuItem.name}
                expandableMenuItems={menuItem.links}
                iconClass={menuItem.icon}
                isExpandable={menuItem.type === 'dropdown'}
                isItemHidden={menuItem.isHidden && hideBuyTicketButton}
                itemName={menuItem.name}
                to={menuItem.to}
              />
            )
          )
        )}

        {user && (
          <>
            <button
              type="button"
              className="btn btn-block p-0"
              onClick={() => {
                onCloseMenu();
                setIsLogoutModalActivated(true);
              }}>
              <NavigationItem key="Logout" itemName="Abmelden" to="#" />{' '}
            </button>
            <LogoutModal
              isLogoutModalActivated={isLogoutModalActivated}
              closeLogoutModal={() => setIsLogoutModalActivated(false)}
              logout={logout}
            />
          </>
        )}
        <a href="mailto:info@raodjet.de" className="d-block text-primary my-1 mx-2 px-2 py-4">
          <i className="fas fa-headphones"></i>
          <span className="ml-2">Bereit zu helfen </span>
        </a>
      </nav>
    </>
  );
};

MobileNavMenu.propTypes = {
  hideBuyTicketButton: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCloseMenu: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired
};

MobileNavMenu.defaultProps = {
  hideBuyTicketButton: false,
  isOpen: false
};

export default MobileNavMenu;
