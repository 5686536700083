import './TicketCancellationCard.scss';
import { TCCItemDesktop } from '.';

const TCCContentDesktop = ({
  allTickets,
  captions,
  selectedTickets,
  setSelectedTickets,
  type,
  isSelectable
}) => {
  return (
    <div className="ticket-cancellation-card-content bg-white pb-6">
      <div className="row pt-6 pb-3 px-6 ticket-cancellation-card-content-titles">
        {isSelectable && type !== 'ticket-id' && <div className="col-2 my-auto"></div>}

        {/* Table Captions */}
        {captions.map((caption, index) => {
          // First Caption
          if (index === 0) {
            return (
              <div
                key={caption.title}
                className={`${
                  type === 'ticket-id'
                    ? `${
                        isSelectable
                          ? ` col-${caption.colWidthOnSelect + 2}`
                          : ` col-${caption.colWidth + 2}`
                      }  `
                    : `${
                        isSelectable
                          ? ` col-${caption.colWidthOnSelect}`
                          : ` col-${caption.colWidth}`
                      }  `
                } my-auto`}>
                {caption.title}
              </div>
            );
          }
          // Other Captions
          return (
            <div
              key={caption.title}
              className={`${
                isSelectable ? ` col-${caption.colWidthOnSelect}` : ` col-${caption.colWidth}`
              } my-auto text-center`}>
              {caption.title}
            </div>
          );
        })}
      </div>

      {allTickets.map((ticket, index) => (
        <TCCItemDesktop
          key={ticket.id}
          ticket={ticket}
          ticketCount={allTickets.length}
          index={index}
          isSelectable={isSelectable}
          selectedTickets={selectedTickets}
          setSelectedTickets={setSelectedTickets}
          type={type}
        />
      ))}
    </div>
  );
};

export default TCCContentDesktop;
