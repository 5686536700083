import moment from 'moment-timezone';

import augsburgFallbackImageLarge from '../../static/assets/images/roadjet-cities/large/city_augsburg.jpg';
import berlinFallbackImageLarge from '../../static/assets/images/roadjet-cities/large/city_berlin.jpg';
import frankfurtFallbackImageLarge from '../../static/assets/images/roadjet-cities/large/city_frankfurt.jpg';
import karlsruheFallbackImageLarge from '../../static/assets/images/roadjet-cities/large/city_karlsruhe.jpg';
import leipzigFallbackImageLarge from '../../static/assets/images/roadjet-cities/large/city_leipzig.jpg';
import mannheimFallbackImageLarge from '../../static/assets/images/roadjet-cities/large/city_mannheim.jpg';
import munchenFallbackImageLarge from '../../static/assets/images/roadjet-cities/large/city_munchen.jpg';
import nurnbergFallbackImageLarge from '../../static/assets/images/roadjet-cities/large/city_nurnberg.jpg';
import stuttgartFallbackImageLarge from '../../static/assets/images/roadjet-cities/large/city_stuttgart.jpg';
import ulmFallbackImageLarge from '../../static/assets/images/roadjet-cities/large/city_ulm.jpg';
import augsburgWebpImageLarge from '../../static/assets/images/roadjet-cities/large/webp/city_augsburg.webp';
import berlinWebpImageLarge from '../../static/assets/images/roadjet-cities/large/webp/city_berlin.webp';
import frankfurtWebpImageLarge from '../../static/assets/images/roadjet-cities/large/webp/city_frankfurt.webp';
import karlsruheWebpImageLarge from '../../static/assets/images/roadjet-cities/large/webp/city_karlsruhe.webp';
import leipzigWebpImageLarge from '../../static/assets/images/roadjet-cities/large/webp/city_leipzig.webp';
import mannheimWebpImageLarge from '../../static/assets/images/roadjet-cities/large/webp/city_mannheim.webp';
import munchenWebpImageLarge from '../../static/assets/images/roadjet-cities/large/webp/city_munchen.webp';
import nurnbergWebpImageLarge from '../../static/assets/images/roadjet-cities/large/webp/city_nurnberg.webp';
import stuttgartWebpImageLarge from '../../static/assets/images/roadjet-cities/large/webp/city_stuttgart.webp';
import ulmWebpImageLarge from '../../static/assets/images/roadjet-cities/large/webp/city_ulm.webp';
import augsburgFallbackImageMobile from '../../static/assets/images/roadjet-cities/mobile/city_augsburg-sm.jpg';
import berlinFallbackImageMobile from '../../static/assets/images/roadjet-cities/mobile/city_berlin-sm.jpg';
import frankfurtFallbackImageMobile from '../../static/assets/images/roadjet-cities/mobile/city_frankfurt-sm.jpg';
import karlsruheFallbackImageMobile from '../../static/assets/images/roadjet-cities/mobile/city_karlsruhe-sm.jpg';
import leipzigFallbackImageMobile from '../../static/assets/images/roadjet-cities/mobile/city_leipzig-sm.jpg';
import mannheimFallbackImageMobile from '../../static/assets/images/roadjet-cities/mobile/city_mannheim-sm.jpg';
import munchenFallbackImageMobile from '../../static/assets/images/roadjet-cities/mobile/city_munchen-sm.jpg';
import nurnbergFallbackImageMobile from '../../static/assets/images/roadjet-cities/mobile/city_nurnberg-sm.jpg';
import stuttgartFallbackImageMobile from '../../static/assets/images/roadjet-cities/mobile/city_stuttgart-sm.jpg';
import ulmFallbackImageMobile from '../../static/assets/images/roadjet-cities/mobile/city_ulm-sm.jpg';
import augsburgWebpImageMobile from '../../static/assets/images/roadjet-cities/mobile/webp/city_augsburg-sm.webp';
import berlinWebpImageMobile from '../../static/assets/images/roadjet-cities/mobile/webp/city_berlin-sm.webp';
import frankfurtWebpImageMobile from '../../static/assets/images/roadjet-cities/mobile/webp/city_frankfurt-sm.webp';
import karlsruheWebpImageMobile from '../../static/assets/images/roadjet-cities/mobile/webp/city_karlsruhe-sm.webp';
import leipzigWebpImageMobile from '../../static/assets/images/roadjet-cities/mobile/webp/city_leipzig-sm.webp';
import mannheimWebpImageMobile from '../../static/assets/images/roadjet-cities/mobile/webp/city_mannheim-sm.webp';
import munchenWebpImageMobile from '../../static/assets/images/roadjet-cities/mobile/webp/city_munchen-sm.webp';
import nurnbergWebpImageMobile from '../../static/assets/images/roadjet-cities/mobile/webp/city_nurnberg-sm.webp';
import stuttgartWebpImageMobile from '../../static/assets/images/roadjet-cities/mobile/webp/city_stuttgart-sm.webp';
import ulmWebpImageMobile from '../../static/assets/images/roadjet-cities/mobile/webp/city_ulm-sm.webp';

export const cityImages = [
  {
    id: 10,
    name: 'Frankfurt',
    image: {
      altText: 'City of Frankfurt',
      source: {
        mobile: {
          fallback: frankfurtFallbackImageMobile,
          webp: frankfurtWebpImageMobile
        },
        large: {
          fallback: frankfurtFallbackImageLarge,
          webp: frankfurtWebpImageLarge
        }
      }
    }
  },
  {
    id: 9,
    name: 'Mannheim',
    image: {
      altText: 'City of Mannheim',
      source: {
        mobile: {
          fallback: mannheimFallbackImageMobile,
          webp: mannheimWebpImageMobile
        },
        large: {
          fallback: mannheimFallbackImageLarge,
          webp: mannheimWebpImageLarge
        }
      }
    }
  },
  {
    id: 8,
    name: 'Karlsruhe',
    image: {
      altText: 'City of Karlsruhe',
      source: {
        mobile: {
          fallback: karlsruheFallbackImageMobile,
          webp: karlsruheWebpImageMobile
        },
        large: {
          fallback: karlsruheFallbackImageLarge,
          webp: karlsruheWebpImageLarge
        }
      }
    }
  },
  {
    id: 7,
    name: 'München',
    image: {
      altText: 'City of München',
      source: {
        mobile: {
          fallback: munchenFallbackImageMobile,
          webp: munchenWebpImageMobile
        },
        large: {
          fallback: munchenFallbackImageLarge,
          webp: munchenWebpImageLarge
        }
      }
    }
  },
  {
    id: 6,
    name: 'Augsburg',
    image: {
      altText: 'City of Augsburg',
      source: {
        mobile: {
          fallback: augsburgFallbackImageMobile,
          webp: augsburgWebpImageMobile
        },
        large: {
          fallback: augsburgFallbackImageLarge,
          webp: augsburgWebpImageLarge
        }
      }
    }
  },
  {
    id: 5,
    name: 'Ulm',
    image: {
      altText: 'City of Ulm',
      source: {
        mobile: {
          fallback: ulmFallbackImageMobile,
          webp: ulmWebpImageMobile
        },
        large: {
          fallback: ulmFallbackImageLarge,
          webp: ulmWebpImageLarge
        }
      }
    }
  },

  {
    id: 4,
    name: 'Berlin',
    image: {
      altText: 'City of Berlin',
      source: {
        mobile: {
          fallback: berlinFallbackImageMobile,
          webp: berlinWebpImageMobile
        },
        large: {
          fallback: berlinFallbackImageLarge,
          webp: berlinWebpImageLarge
        }
      }
    }
  },

  {
    id: 3,
    name: 'Leipzig',
    image: {
      altText: 'City of Leipzig',
      source: {
        mobile: {
          fallback: leipzigFallbackImageMobile,
          webp: leipzigWebpImageMobile
        },
        large: {
          fallback: leipzigFallbackImageLarge,
          webp: leipzigWebpImageLarge
        }
      }
    }
  },

  {
    id: 2,
    name: 'Nürnberg',
    image: {
      altText: 'City of Nürnberg',
      source: {
        mobile: {
          fallback: nurnbergFallbackImageMobile,
          webp: nurnbergWebpImageMobile
        },
        large: {
          fallback: nurnbergFallbackImageLarge,
          webp: nurnbergWebpImageLarge
        }
      }
    }
  },

  {
    id: 1,
    name: 'Stuttgart',
    image: {
      altText: 'City of Stuttgart',
      source: {
        mobile: {
          fallback: stuttgartFallbackImageMobile,
          webp: stuttgartWebpImageMobile
        },
        large: {
          fallback: stuttgartFallbackImageLarge,
          webp: stuttgartWebpImageLarge
        }
      }
    }
  }
];

export const initialState = {
  departureCity: {
    id: 1,
    name: 'Stuttgart',
    abbr: 'STU',
    busStopName: 'Stuttgart Flughafen',
    image: {
      altText: 'City of Stuttgart',
      source: {
        mobile: {
          fallback: stuttgartFallbackImageMobile,
          webp: stuttgartWebpImageMobile
        },
        large: {
          fallback: stuttgartFallbackImageLarge,
          webp: stuttgartWebpImageLarge
        }
      }
    }
  },
  departureBusStop: {
    //NEW-30-06-21
    cityId: 1,
    id: 1,
    name: 'Stuttgart Flughafen'
  },
  arrivalCity: {
    id: 4,
    name: 'Berlin',
    abbr: 'BER',
    busStopName: 'Berlin ZOB',
    image: {
      altText: 'City of Berlin',
      source: {
        mobile: {
          fallback: berlinFallbackImageMobile,
          webp: berlinWebpImageMobile
        },
        large: {
          fallback: berlinFallbackImageLarge,
          webp: berlinWebpImageLarge
        }
      }
    }
  },
  arrivalBusStop: {
    //NEW-30-06-21
    cityId: 4,
    id: 4,
    name: 'Berlin ZOB'
  },
  departureDate: moment.tz('Europe/Berlin').toDate(),
  arrivalDate: moment.tz('Europe/Berlin').add(1, 'days').toDate(),
  passengerCount: 1,
  isRoundTrip: 0
};

export const dateLocaleOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric'
};

export const parseDate = (date) => {
  const splittedDate = date.split(/[\s,.]+/);
  return splittedDate;
};

export const actionTypes = {
  SET_ARRIVAL_CITY: 'SET_ARRIVAL_CITY', //NEW-30-06-21
  SET_ARRIVAL_BUS_STOP: 'SET_ARRIVAL_BUS_STOP', //NEW-30-06-21
  SET_ARRIVAL_DATE: 'SET_ARRIVAL_DATE',
  SET_DEPARTURE_CITY: 'SET_DEPARTURE_CITY',
  SET_DEPARTURE_BUS_STOP: 'SET_DEPARTURE_BUS_STOP',
  SET_DEPARTURE_DATE: 'SET_DEPARTURE_DATE',
  SET_EXCHANGE_CITIES: 'SET_EXCHANGE_CITIES',
  SET_ONE_WAY: 'SET_ONE_WAY',
  SET_PASSENGER_COUNT: 'SET_PASSENGER_COUNT',
  SET_ROUND_TRIP: 'SET_ROUND_TRIP',
  SET_RETURN_ROUTE_AVAILABLE: 'SET_SET_RETURN_ROUTE_AVAILABLE',
  SET_RETURN_ROUTE_IS_NOT_AVAILABLE: 'SET_RETURN_ROUTE_IS_NOT_AVAILABLE'
};
