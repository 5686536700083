import { Backdrop, Button, Modal } from '.';

import './LogoutModal.scss';

const LogoutModal = ({ isLogoutModalActivated, closeLogoutModal, logout }) => {
  return (
    <>
      <Backdrop isActivated={isLogoutModalActivated} onDismiss={closeLogoutModal} />
      <Modal
        customClass="flex-column align-items-center py-5 logout-modal"
        isModalActivated={isLogoutModalActivated}>
        <h2 className="popup-title mr-auto font-weight-bold font-20 mb-5">Bist du sicher?</h2>
        <span className="text-dark font-16 mb-5">
          Möchtest du dich wirklich von deinem Konto abmelden?
        </span>

        <div className="d-flex align-content-center justify-content-between w-100">
          <Button
            borderRadiusSize="-sm"
            customClass="popup-cancel-btn"
            isRelativelyPositioned
            onClick={closeLogoutModal}>
            <span className="font-16">Abbrechen</span>
          </Button>
          <Button
            borderRadiusSize="-sm"
            color="white"
            customClass="popup-accept-btn"
            isRelativelyPositioned
            onClick={() => logout()}>
            <span className="text-white font-16">Ausloggen</span>
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default LogoutModal;
