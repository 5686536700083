import React, { useState, useRef } from 'react';

import { Link } from 'react-router-dom';

import './AuthHeaderBox.scss';

import { useUser, useClickOutsideElement } from '../../hooks';

import { LogoutModal } from '.';

const AuthHeaderBox = ({ setIsAuthenticatedUserBoxDisplayedForDesktop }) => {
  const [isLogoutModalActivated, setIsLogoutModalActivated] = useState(false);

  const [isClickOutsideOfAuthHeaderBoxEnabled, setIsClickOutsideOfAuthHeaderBoxEnabled] =
    useState(true);

  const { user, logout } = useUser();

  const ref = useRef(null);

  let avatarName = '';
  let userFullName;
  if (user) {
    avatarName = user.email[0];
    userFullName = '';

    if (user.name) {
      avatarName = user.name[0];
      userFullName = user.name;
    }

    if (user.name && user.surname) {
      avatarName += user.surname[0];
      userFullName += ` ${user.surname}`;
    }
  }

  useClickOutsideElement(isClickOutsideOfAuthHeaderBoxEnabled ? ref : null, () =>
    setIsAuthenticatedUserBoxDisplayedForDesktop(false)
  );

  return (
    user && (
      <div className="authenticated-user-box bg-white p-3" ref={ref}>
        <div>
          <div className="text-right mb-4">
            <span className="font-13 cursor-pointer d-flex justify-content-end">
              <button
                className="btn my-auto"
                type="button"
                onClick={() => {
                  setIsLogoutModalActivated(true);
                  setIsClickOutsideOfAuthHeaderBoxEnabled(false);
                }}>
                <i className="fas fa-sign-out-alt font-16 mr-1"></i>
                Abmelden
              </button>
              <LogoutModal
                isLogoutModalActivated={isLogoutModalActivated}
                closeLogoutModal={() => setIsLogoutModalActivated(false)}
                logout={() => logout()}
              />
            </span>
          </div>
          <div className="d-flex justify-content-around align-items-center">
            <div className="user-profile-photo d-inline-flex justify-content-center align-items-center font-28 font-weight-medium overflow-hidden">
              {user.profilePhotoUrl ? (
                <img src={user.profilePhotoUrl} alt="user" className="userAvatar w-100 h-100" />
              ) : (
                <span>{avatarName.toUpperCase()}</span>
              )}
            </div>
            <div>
              <div className="font-18 font-weight-medium">{userFullName}</div>
              <div className="font-13">{`${user.email}`}</div>
              <div className="font-13 mt-1">
                <Link to="/account/meine-buchungen" style={{ color: '#0078d6' }}>
                  Meine Buchungen
                </Link>
              </div>
              <div style={{ fontSize: '13px', color: '#0078d6' }}>
                <Link to="/account/profil" style={{ color: '#0078d6' }}>
                  Profile
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

AuthHeaderBox.displayName = 'AuthHeaderBox';

export default AuthHeaderBox;
