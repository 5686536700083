import React from 'react';

import PropTypes from 'prop-types';

import './Icon.scss';

/**
 * this component will render an Icon which is either custom defined by using CSS or is a FontAwesome icon.
 * this component may take a bunch of different props in order to properly customize the rendered icon based on the needs.
 * --borderColor: (should be used with "isBordered", "isRoundedBorder" or both props) display border color of the icon
 * --color: display color of the icon
 * --dimensions: (object with properties "width" and "height") display width and height (size) of the icon for customly defined ones
 * --fontAwesomeIconClass: icon className from FontAwesome
 * --fontAwesomeIconSize: FontAwesome icon size
 * --id: (may be used for reference to Icon for click actions) Icon component id
 * --isBordered: if true, it means icon will be bordered around
 * --isRoundedBorder: if true, it means icon has a full circle border around itself
 * --isRelativelyPositioned: if true, icon will be positioned relatively within its container. if false, it will be positioned absolutely relative to its nearest relatively positioned ancestor
 * --name: (used for custom icons) based on this prop, customly defined icon will be assigned a CSS class `icon-${name}`, which is defined in './Icon.scss'
 * --onClick: handler function reference for icon if its role is defined as "button"
 * --position: (object with properties "top", "left", "bottom" and "right" // used for custom icons) in order to determine the exact position of icon within its container
 * --size: (used for custom icons) based on this prop, customly defined icon will be assigned a CSS class `icon-${size}`, which is defined in './Icon.scss'
 * --type: (default to "custom") prop to determine to render either custom defined or FontAwesome icon
 */

const Icon = ({
  //borderColor,
  color,
  dimensions,
  fontAwesomeIconClass,
  fontAwesomeIconSize,
  iconBaseClass,
  id,
  isBordered,
  isRelativelyPositioned,
  isRoundedBorder,
  name,
  onClick,
  position,
  size,
  type
}) => {
  if (type === 'fontAwesome') {
    // if icon is a FontAwesome icon,
    if (onClick) {
      // and if it is pressable, i.e. serves as a button,
      return (
        <i
          aria-label="Icon as Button"
          className={`no-focus-outline ${fontAwesomeIconClass} font-${fontAwesomeIconSize}  position-${
            isRelativelyPositioned ? 'relative' : 'absolute'
          } text-${color} ${iconBaseClass || ''}`}
          id={id}
          onClick={onClick}
          role="button"
          tabIndex="0"
        />
      );
    }

    return (
      <i
        className={`${fontAwesomeIconClass} font-${fontAwesomeIconSize} position-${
          isRelativelyPositioned ? 'relative' : 'absolute'
        } text-${color} ${isBordered && isRoundedBorder ? 'rounded-circle' : ''} ${
          iconBaseClass || ''
        }`}
        id={id}
      />
    );
  }

  if (type === 'custom') {
    // if icon is a custom defined icon apart from being a FontAwesome icon,
    if (onClick) {
      // means icon is used as a pressable object (button)
      return (
        <span
          className={`icon-${name} d-inline-block ${!dimensions && `icon-${size}`} position-${
            isRelativelyPositioned ? 'relative' : 'absolute'
          } bg-${color} ${iconBaseClass || ''} no-focus-outline `}
          id={id}
          onClick={onClick}
          role="button"
          tabIndex="0"
          style={{ ...position, ...dimensions }}
        />
      );
    }

    return (
      // means it is just a representative icon
      <span
        className={`icon-${name} d-inline-block ${!dimensions && `icon-${size}`} position-${
          isRelativelyPositioned ? 'relative' : 'absolute'
        } bg-${color} ${iconBaseClass || ''}`}
        id={id}
        style={{ ...position, ...dimensions }}
      />
    );
  }
};

Icon.propTypes = {
  borderColor: PropTypes.string,
  color: PropTypes.string,
  dimensions: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string
  }),
  fontAwesomeIconClass: PropTypes.string,
  fontAwesomeIconSize: PropTypes.string,
  iconBaseClass: PropTypes.string,
  id: PropTypes.string,
  isBordered: PropTypes.bool,
  isRelativelyPositioned: PropTypes.bool,
  isRoundedBorder: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  position: PropTypes.shape({
    top: PropTypes.string,
    left: PropTypes.string,
    bottom: PropTypes.string,
    right: PropTypes.string
  }),
  size: PropTypes.string,
  type: PropTypes.string // type = fontAwesome || custom
};

Icon.defaultProps = {
  borderColor: null,
  color: 'primary',
  dimensions: null,
  fontAwesomeIconClass: null,
  fontAwesomeIconSize: '1rem',
  iconBaseClass: null,
  id: '',
  isBordered: false,
  isRelativelyPositioned: false,
  isRoundedBorder: false,
  name: null,
  onClick: null,
  position: null,
  size: 'md',
  type: 'fontAwesome'
};

export default Icon;
