import Swal from 'sweetalert2';

const toast = ({ icon = 'info', timer = 3000, title, html }) => {
  try {
    const MyToast = Swal.mixin({
      toast: true,
      position: 'top-end',
      timer,
      showConfirmButton: false,
      timerProgressBar: true
    });

    return MyToast.fire({
      icon,
      title,
      html
    });
  } catch (error) {
    // console.log('[sweetAlert.js] error: ', error);
  }
};

export default toast;
