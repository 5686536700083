import React, { useEffect } from 'react';

import de from 'date-fns/locale/de';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { useRoadJetAPI } from '../../hooks';

import './ReactDatePicker.scss';

import { maxDate, minDate } from './util';

import { Backdrop, Modal } from '.';

/**
 * Date picker component provided by react-date-picker package.
 */
const ReactDatePicker = ({
  arrivalDate,
  datePickerId,
  departureDate,
  isPickerDisplayed,
  onDismissPicker,
  onSetArrivalDate,
  onSetDepartureDate,
  onSetReturnRouteAvailable,
  selectedDepartureBusStopCode,
  selectedArrivalBusStopCode
}) => {
  const [departureDates] = useRoadJetAPI(
    `/busStops/dates?departureBusStopCode=${selectedDepartureBusStopCode}&arrivalBusStopCode=${selectedArrivalBusStopCode}`
  );

  const [returnDates] = useRoadJetAPI(
    `/busStops/dates?departureBusStopCode=${selectedArrivalBusStopCode}&arrivalBusStopCode=${selectedDepartureBusStopCode}`
  );

  // This useEffect handles disabling not available departure dates on selected route
  useEffect(() => {
    if (
      departureDates?.disableDates?.filter((date) => {
        return moment(date).format('YYYY-MM-DD') === moment(departureDate).format('YYYY-MM-DD');
      }).length > 0
    ) {
      let currentDates = [];
      let germanTime = moment.tz('Europe/Berlin');

      for (let i = 0; i < 7; i++) {
        let uniqueDate = germanTime.clone();
        currentDates.push(uniqueDate.add(i, 'days').format('YYYY-MM-DD'));
      }

      let disabledMomentDates = departureDates?.disableDates?.map((date) =>
        moment(date).format('YYYY-MM-DD')
      );

      // Get available Dates
      let diff = currentDates.filter((date) => !disabledMomentDates.includes(date));

      diff.length > 0 ? onSetDepartureDate(new Date(diff[0])) : onSetDepartureDate(new Date());
    }
  }, [departureDates]);

  // This useEffect handles disabling not available return(arrival) dates on selected route
  useEffect(() => {
    onSetReturnRouteAvailable(returnDates?.isRouteAvailable);

    if (
      returnDates?.disableDates?.filter((date) => {
        return moment(date).format('YYYY-MM-DD') === moment(arrivalDate).format('YYYY-MM-DD');
      }).length > 0
    ) {
      let currentDates = [];
      let germanTime = moment.tz('Europe/Berlin');

      for (let i = 0; i < 90; i++) {
        let uniqueDate = germanTime.clone();
        currentDates.push(uniqueDate.add(i, 'days').format('YYYY-MM-DD'));
      }

      let disabledMomentDates = returnDates?.disableDates?.map((date) =>
        moment(date).format('YYYY-MM-DD')
      );

      // Get available Dates
      let diff = currentDates.filter(
        (date) => !disabledMomentDates.includes(date) && moment(date).isAfter(departureDate, 'day')
      );

      diff.length > 0 ? onSetArrivalDate(new Date(diff[0])) : onSetArrivalDate(new Date());
    }
  }, [returnDates]);

  let datePicker = ( // by default, it is departure date picker.
    <DatePicker
      selected={departureDate}
      onChange={onSetDepartureDate}
      dateFormat="MM/dd/yyyy"
      locale={de}
      excludeDates={departureDates?.disableDates?.map((date) => new Date(date))}
      inline
      startDate={departureDate}
      minDate={minDate}
      maxDate={maxDate}
    />
  );

  if (datePickerId === 'arrival') {
    // if arrival date picker is opened in search area, then assign "datePicker" to arrival date picker
    datePicker = (
      <DatePicker
        selected={arrivalDate}
        onChange={onSetArrivalDate}
        dateFormat="MM/dd/yyyy"
        locale={de}
        excludeDates={returnDates?.disableDates?.map((date) => new Date(date))}
        inline
        startDate={departureDate}
        minDate={departureDate}
        maxDate={maxDate}
      />
    );
  }

  return (
    <>
      <Backdrop isActivated={isPickerDisplayed} onDismiss={onDismissPicker} />
      <Modal isFixedHeight isModalActivated={isPickerDisplayed}>
        {datePicker}
      </Modal>
    </>
  );
};

ReactDatePicker.propTypes = {
  arrivalDate: PropTypes.instanceOf(Date),
  datePickerId: PropTypes.string,
  departureDate: PropTypes.instanceOf(Date),
  id: PropTypes.string,
  isDateInput: PropTypes.bool,
  isDateInputError: PropTypes.bool,
  isPickerDisplayed: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onDismissPicker: PropTypes.func,
  onSetArrivalDate: PropTypes.func,
  onSetDepartureDate: PropTypes.func,
  onSetReturnRouteAvailable: PropTypes.func,
  value: PropTypes.instanceOf(Date)
};

ReactDatePicker.defaultProps = {
  arrivalDate: null,
  datePickerId: '',
  departureDate: null,
  id: null,
  isDateInput: false,
  isDateInputError: false,
  isPickerDisplayed: false,
  name: null,
  onChange: null,
  onDismissPicker: null,
  onSetArrivalDate: null,
  onSetDepartureDate: null,
  value: null
};

export default ReactDatePicker;
