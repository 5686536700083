import roadjetLandingBusImageFallback from '../../static/assets/images/roadjet-bus/roadjet-landing-night.jpg';
import roadjetLandingBusImageWebp from '../../static/assets/images/roadjet-bus/webp/roadjet-landing-night.webp';

import PageLanding from './PageLanding';

export default PageLanding;
export const landingImageSrc = {
  webp: roadjetLandingBusImageWebp,
  fallback: roadjetLandingBusImageFallback
};
