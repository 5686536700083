import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import './Header.scss';

import { useWindowDimensions } from '../../../hooks';

import { desktopNavigationMenuItems, mobileNavigationMenuItems, roadjetLogoBlack } from './util';

import { AuthHeaderBox, Button, Icon, Image, LoginButton, MobileNavMenu, NavigationItem } from '.';

/**
 * this component is served as the header for all pages.
 * it will be rendered differently for mobile/tablet and desktop versions.
 */
const Header = ({ isBuyTicketButtonHidden, isHideHeaderInDesktop, isOnDashboard }) => {
  const [openMobileNavMenu, setOpenMobileNavMenu] = useState(false); // state to control open/closed status of mobile navigation menu
  const [isAuthenticatedUserBoxDisplayedForDesktop, setIsAuthenticatedUserBoxDisplayedForDesktop] =
    useState(false);

  const { width } = useWindowDimensions();

  const openMobileNavMenuHandler = () => setOpenMobileNavMenu(true); // handler function to open mobile navigation menu
  const closeMobileNavMenuHandler = () => setOpenMobileNavMenu(false); // handler function to close mobile navigation menu

  const toggleAuthenticatedUserBoxDisplayedForDesktopHandler = () => {
    setIsAuthenticatedUserBoxDisplayedForDesktop((prevState) => !prevState);
  };

  const headerNode = document.querySelector('#header');
  const headerContent = (
    <>
      {/* HEADER IN MOBILE & TABLET */}
      {width < 1024 && (
        <header
          className={`fixed-top bg-white px-4 border-bottom d-flex align-items-center d-lg-none ${
            isHideHeaderInDesktop && isOnDashboard ? 'd-lg-none' : ''
          }`}>
          <LoginButton onToggleAuthBox={toggleAuthenticatedUserBoxDisplayedForDesktopHandler} />
          {isAuthenticatedUserBoxDisplayedForDesktop && (
            <AuthHeaderBox
              setIsAuthenticatedUserBoxDisplayedForDesktop={
                setIsAuthenticatedUserBoxDisplayedForDesktop
              }
            />
          )}

          <Icon
            color="secondary"
            fontAwesomeIconClass="fas fa-bars"
            fontAwesomeIconSize="24"
            iconBaseClass="menu-bars"
            onClick={openMobileNavMenuHandler}
          />

          <Button
            customClass="mx-auto"
            id="RoadJet Logo Home Page Link"
            isRelativelyPositioned
            to="/">
            <Image
              imgAltText="Roadjet Black Colored Logo on White Background for Desktop Header"
              figureClass="roadjet-logo"
              id="RoadJet Logo Home Page Link"
              imgFallbackSrc={roadjetLogoBlack}
              isRelativelyPositioned
              isSvg
            />
          </Button>
          <MobileNavMenu
            isOpen={openMobileNavMenu}
            hideBuyTicketButton={isBuyTicketButtonHidden}
            onCloseMenu={closeMobileNavMenuHandler}
            menuItems={mobileNavigationMenuItems}
          />
        </header>
      )}

      {/* HEADER IN DESKTOP */}
      {width >= 1024 && (
        <header
          className={`fixed-top bg-white px-4 d-none d-lg-${
            isHideHeaderInDesktop ? 'none' : 'flex'
          } align-items-lg-center `}>
          <div className="container-fluid position-relative px-xl-0 d-flex align-items-center justify-content-between">
            {isAuthenticatedUserBoxDisplayedForDesktop && (
              <AuthHeaderBox
                setIsAuthenticatedUserBoxDisplayedForDesktop={
                  setIsAuthenticatedUserBoxDisplayedForDesktop
                }
              />
            )}

            <Button id="RoadJet Logo Home Page Link" to="/">
              <Image
                imgAltText="Roadjet Black Colored Logo on White Background for Desktop Header"
                figureClass="roadjet-logo mb-0"
                id="RoadJet Logo Home Page Link"
                imgFallbackSrc={roadjetLogoBlack}
                isRelativelyPositioned
                isSvg
              />
            </Button>

            <div className="d-flex justify-content-around align-items-center">
              {desktopNavigationMenuItems.map((menuItem) => (
                <NavigationItem
                  key={menuItem.name}
                  expandableMenuItems={menuItem.type === 'dropdown' ? menuItem.subLinks : null}
                  isButton={menuItem.type === 'button'}
                  isDesktop
                  isExpandable={menuItem.type === 'dropdown'}
                  itemName={menuItem.name}
                  isItemHidden={menuItem.isHidden && isBuyTicketButtonHidden}
                  onToggleAuthBox={toggleAuthenticatedUserBoxDisplayedForDesktopHandler}
                  to={menuItem.to}
                />
              ))}
            </div>
          </div>
        </header>
      )}

      {/* Auxiliary DOM Element */}
      {!isOnDashboard && width >= 1024 ? <div className="header-placeholder" /> : null}
      {width < 1024 && <div className="header-placeholder" />}
    </>
  );

  return createPortal(headerContent, headerNode);
};

Header.propTypes = {
  isBuyTicketButtonHidden: PropTypes.bool,
  isHideHeaderInDesktop: PropTypes.bool,
  isOnDashboard: PropTypes.bool
};

Header.defaultProps = {
  isBuyTicketButtonHidden: false,
  isHideHeaderInDesktop: false,
  isOnDashboard: false
};

export default Header;
