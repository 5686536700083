import { dateMonths } from './constants';

const parseDateString = (dateString) => {
  if (!dateString) return null;

  // split given date string by "-" character (e.g. dateString = "2021-06-01" --> tokens = ["2021", "06", "01"])
  const tokens = dateString.split('-');

  // extract actual month name as string from "dateMonths" object by using "tokens[1]". (e.g. "06" for above example given in comment)
  const monthName = dateMonths[tokens[1]];

  // copy the content of "tokens" into new variable called "dateTokens"
  const dateTokens = [...tokens];

  // replace the 2nd element with its corresponding string. (e.g. for "06", the corresponding string = "Juni")
  dateTokens[1] = monthName;

  return dateTokens;
};

export default parseDateString;
