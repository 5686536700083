import { useState } from 'react';

/**
 * this custom hook is responsible for opening/closing, i.e. toggling, of any Card structured element,
 * in which it has a downward facing arrow when content is not expanded and upon clicking to that arrow,
 * the full content will be displayed to user.
 * it takes two arguments one of which is the "initialState" of each Card structured elements (unless otherwise specified,
 * initial state of each card is given as closed) and the other of which is the actual class name of each Card element.
 */
const useToggle = (initialState, cardClass) => {
  const [expandCardContent, setExpandCardContent] = useState({
    ...initialState
  });

  /**
   * this handler function is responsible for actually performing toggle action
   * for a Card element upon clicking the arrow icon within that Card element.
   */
  const toggleDisplayStateHandler = (id) => {
    setExpandCardContent((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  /**
   * this handler function is responsible for attaching the toggling action,
   * which is defined above to the respective Card element whose class name
   * matches with the parameter "cardClass"
   */
  const toggleCardContentDisplayHandler = ({ currentTarget }) => {
    const cardElement = currentTarget.closest(`.${cardClass}`);
    toggleDisplayStateHandler(cardElement.id);
  };

  return { expandCardContent, toggleCardContentDisplayHandler }; // return current display state of Card element and the handler function responsible for toggling
};

export default useToggle;
