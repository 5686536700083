import { useState, useEffect } from 'react';

import { Button, Input, ReCaptcha } from '.';

const CancellationByBookingIdTab = ({
  cancellationByBookingIdInputs,
  cancellationByBookingIdFormInputsState,
  email,
  errorMessage,
  isEmailSent,
  isError,
  isLoading,
  onChange,
  onSubmit,
  onVerificationCodeSubmit
}) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);

  useEffect(() => {
    if (isError) {
      setIsCaptchaChecked(false);
    }
  }, [isError]);

  const onVerificationCodeChangeHandler = (event) => {
    setVerificationCode(event.target.value);
  };

  return isLoading ? (
    <div className="loading-cancellation-by-booking-id d-flex">
      <div className=" spinner-border text-primary m-auto" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  ) : isEmailSent ? (
    <>
      <p className="mb-4">
        Ein Authentifizierungscode wurde an deine E-Mail
        <strong className="text-primary"> ({email})</strong> gesendet. Bitte gib den Code oben in
        das dafür vorgesehene Feld ein.
      </p>
      <div className="row">
        <div className="col-12 col-md-8 mb-3">
          <Input
            htmlFor="verificationCode"
            id="verificationCode"
            label="Verifizierungscode"
            name="verificationCode"
            onChange={onVerificationCodeChangeHandler}
            placeholder="XXXX"
            type="text"
            value={verificationCode}
          />
        </div>
        <div className="col-24 d-flex mb-3">
          <Button
            borderRadiusSize="-sm"
            isRelativelyPositioned
            onClick={onVerificationCodeSubmit.bind(this, verificationCode)}
            type="primary">
            Einreichen
          </Button>
        </div>
        {errorMessage && <div className="text-danger  ml-3">{errorMessage}</div>}
      </div>
    </>
  ) : (
    <div className="row">
      {cancellationByBookingIdInputs.map((field) => (
        <div key={field.id} className="col-24 col-md-12  mb-4">
          <Input
            htmlFor={field.id}
            id={field.id}
            customClass="ticket-cancellation-by-booking-id-input"
            label={field.label}
            name={field.id}
            onChange={onChange}
            placeholder={field.placeholder}
            type={field.type}
            value={cancellationByBookingIdFormInputsState[field.id].value}
          />
        </div>
      ))}
      <div className="col-24 mb-4">
        <ReCaptcha onChecked={() => setIsCaptchaChecked(true)} />
      </div>

      <div className="col-24 d-flex mb-3">
        <Button
          borderRadiusSize="-sm"
          isRelativelyPositioned
          isDisabled={!isCaptchaChecked}
          onClick={onSubmit}
          type="primary">
          Suchen
        </Button>
      </div>
      {errorMessage && <div className="text-danger  ml-3">{errorMessage}</div>}
    </div>
  );
};

export default CancellationByBookingIdTab;
