import React, { useCallback } from 'react';

import Loading from '../components/Loading';
import { errorMessages } from '../pages/Auth/NotAuthenticated/util';
import toast from '../utils/toast';

// Create a context that will hold the values that we are going to expose to our components.
// Don't worry about the `null` value. It's gonna be *instantly* overriden by the component below
export const UserContext = React.createContext(null);

// Create a "controller" component that will calculate all the data that we need to give to our
// components bellow via the `UserContext.Provider` component. This is where the Amplify will be
// mapped to a different interface, the one that we are going to expose to the rest of the app.
export const UserProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [cognitoUser, setCognitoUser] = React.useState(null);
  const [isAuthLoading, setIsAuthLoading] = React.useState(true);

  React.useEffect(() => {
    // Configure the keys needed for the Auth module. Essentially this is
    // like calling `Amplify.configure` but only for `Auth`.
    // Auth.configure({
    //   // ...
    // });

    const authHandler = async () => {
      const { Auth, API } = await import('aws-amplify');

      Auth.currentSession()
        .then((datax) => {
          // Auth check on current session is not needed to print to console because of not using authentication now
          // console.log(datax);
          return datax;
        })
        .catch((err) => {
          // console.log(err);
          return err;
        });

      // attempt to fetch the info of the user that was already logged in
      Auth.currentAuthenticatedUser()
        .then((data) => {
          setCognitoUser(data);

          // console.log('cognitoUser', data);

          // Get user details from database
          const apiName = 'RoadJetAPI';
          const path = '/users/current';

          API.get(apiName, path)
            .then((userDetails) => {
              setUser({ ...data, ...userDetails });
              setIsAuthLoading(false);
            })
            .catch(() => {
              // console.log(err);
              setUser(data);
              setIsAuthLoading(false);
            });
        })
        .catch(() => {
          setUser(null);
          setIsAuthLoading(false);
        });
    };

    authHandler();
  }, [isAuthLoading]);

  const refreshUser = useCallback(() => {
    const refreshUserHandler = async () => {
      // Get user details from database
      const apiName = 'RoadJetAPI';
      const path = '/users/current';

      const { API } = await import('aws-amplify');

      API.get(apiName, path)
        .then((userDetails) => {
          setUser({ ...cognitoUser, ...userDetails });
          setIsAuthLoading(false);
        })
        .catch(() => {
          // console.log(err);
          setUser(cognitoUser);
          setIsAuthLoading(false);
        });
    };

    refreshUserHandler();
  }, [cognitoUser]);

  // We make sure to handle the user update here, but return the resolve value in order for our components to be
  // able to chain additional `.then()` logic. Additionally, we `.catch` the error and "enhance it" by providing
  // a message that our React components can use.
  const login = async (usernameOrEmail, password) => {
    const { Auth, API } = await import('aws-amplify');

    try {
      let cognitoUser;
      cognitoUser = await Auth.signIn(usernameOrEmail, password);

      setCognitoUser(cognitoUser);

      // Get user details from database
      const apiName = 'RoadJetAPI';
      const path = '/users/current';

      API.get(apiName, path)
        .then((userDetails) => {
          setUser({ ...cognitoUser, ...userDetails });
          setIsAuthLoading(false);
        })
        .catch(() => {
          setUser(cognitoUser);
          setIsAuthLoading(false);
        });
      return cognitoUser;
    } catch (err) {
      const errorMessage = errorMessages.filter((error) => error.message === err.message)[0].german;

      toast({
        icon: 'error',
        title: 'Ungültige Anmeldedaten',
        html: `<p>${errorMessage}</p>`
      });

      // ... (other checks)

      setUser(null);
      setIsAuthLoading(false);
      throw err;
    }
  };

  // same thing here
  const logout = async () => {
    const { Auth } = await import('aws-amplify');
    Auth.signOut().then((data) => {
      setUser(null);
      setCognitoUser(null);
      return data;
    });
  };

  // Make sure to not force a re-render on the components that are reading these values,
  // unless the `user` value has changed. This is an optimisation that is mostly needed in cases
  // where the parent of the current component re-renders and thus the current component is forced
  // to re-render as well. If it does, we want to make sure to give the `UserContext.Provider` the
  // same value as long as the user data is the same. If you have multiple other "controller"
  // components or Providers above this component, then this will be a performance booster.
  const values = React.useMemo(
    () => ({
      user,
      cognitoUser,
      isAuthLoading,
      refreshUser,
      login,
      logout
    }),
    [user, cognitoUser, refreshUser]
  );

  // Finally, return the interface that we want to expose to our other components
  return (
    <UserContext.Provider value={values}>
      {isAuthLoading ? <Loading /> : children}
    </UserContext.Provider>
  );
};

// We also create a simple custom hook to read these values from. We want our React components
// to know as little as possible on how everything is handled, so we are not only abtracting them from
// the fact that we are using React's context, but we also skip some imports.
export const useUser = () => {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error('`useUser` hook must be used within a `UserProvider` component');
  }
  return context;
};
