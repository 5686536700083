import React from 'react';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const ReactHelmet = ({ metaInfo }) => (
  <Helmet>
    <title>{metaInfo.title}</title>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    {/* <meta name="robots" content="noindex" /> */}
    <meta name="description" content={metaInfo.description} />
    <meta name="keywords" content="Busreisen, Reiseerlebnis" />

    {/* Google / Search Engine Tags */}
    <meta itemProp="name" content={metaInfo.title} />
    <meta itemProp="description" content={metaInfo.description} />
    <meta itemProp="image" content={metaInfo.cardImageSrc} />

    {/* Facebook Meta Tags */}
    <meta property="og:url" content={metaInfo.pagePath} />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={metaInfo.title} />
    <meta property="og:description" content={metaInfo.description} />
    <meta property="og:image" content={metaInfo.cardImageSrc} />

    {/* Twitter Meta Tags */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={metaInfo.title} />
    <meta name="twitter:description" content={metaInfo.description} />
    <meta name="twitter:image" content={metaInfo.cardImageSrc} />
  </Helmet>
);

ReactHelmet.propTypes = {
  metaInfo: PropTypes.shape({
    cardImageSrc: PropTypes.string,
    description: PropTypes.string,
    pagePath: PropTypes.string,
    title: PropTypes.string
  }).isRequired
};

export default ReactHelmet;
