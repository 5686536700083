import React from 'react';

import PropTypes from 'prop-types';

import './AppDownloadButtonsGroup.scss';

// import { useWindowDimensions } from '../../hooks';

import { playStoreConnectionButtons } from './util';

import { Button, Image } from '.';

const AppDownloadButtonsGroup = ({ customClass, stackAlignment }) => {
  // const { width } = useWindowDimensions();

  if (stackAlignment === 'vertical') {
    return (
      // <div className={width < 768 && width > 1200 ? 'd-none' : 'invisible'}>
      <div className="d-flex flex-column justify-content-start">
        {playStoreConnectionButtons.map((storeButton) => (
          <Button
            key={storeButton.id}
            anchorLinkClass="app-store-btn mb-4 w-100"
            href={storeButton.href}
            isRelativelyPositioned>
            <Image
              figureClass="app-store-logo"
              imgAltText={storeButton.image.altText}
              imgFallbackSrc={storeButton.image.source}
              isRelativelyPositioned
              isSvg
            />
          </Button>
        ))}
      </div>
    );
  }

  return (
    // <div
    //   className={
    //     width < 768 || width > 1200
    //       ? `${customClass} d-none`
    //       : `${customClass} invisible on-invisible`
    //   }>
    <div className={`${customClass} d-flex justify-content-around align-items-center`}>
      {playStoreConnectionButtons.map((storeButton) => (
        <Button
          key={storeButton.id}
          anchorLinkClass="app-store-btn"
          href={storeButton.href}
          isRelativelyPositioned>
          <Image
            figureClass="app-store-logo"
            imgAltText={storeButton.image.altText}
            imgFallbackSrc={storeButton.image.source}
            imgScaleFactor={0.85}
            isRelativelyPositioned
            isSvg
          />
        </Button>
      ))}
    </div>
  );
};

AppDownloadButtonsGroup.propTypes = {
  customClass: PropTypes.string,
  stackAlignment: PropTypes.string
};

AppDownloadButtonsGroup.defaultProps = {
  customClass: '',
  stackAlignment: 'horizontal'
};

export default AppDownloadButtonsGroup;
