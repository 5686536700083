import { useState } from 'react';

// const useCityPicker = (onSetArrivalCity, onSetDepartureCity) => {
const useCityPicker = (onSetArrivalBusStop, onSetDepartureBusStop) => {
  const [displayCityPickerBackdropModal, setDisplayCityPickerBackdropModal] = useState(false);
  const [cityPickerId, setCityPickerId] = useState(null);

  /**
   * this handler function is responsible for opening departure city modal and the associated backdrop element
   */
  const openDepartureCityModalHandler = () => {
    setDisplayCityPickerBackdropModal(true);
    setCityPickerId('departure');
  };

  /**
   * this handler function is responsible for opening arrival city modal and the associated backdrop element
   */
  const openArrivalCityModalHandler = () => {
    setDisplayCityPickerBackdropModal(true);
    setCityPickerId('arrival');
  };

  /**
   * this handler function is responsible for closing city picker modal and dismissing the associated backdrop element
   */
  const closeCityPickerHandler = () => {
    setDisplayCityPickerBackdropModal(false); // dismiss both the modal and the backdrop
    setCityPickerId(''); // reset city picker id
  };

  /**
   * this handler function is responsible for setting departure city and closing the modal after selection
   */
  // const setDepartureCityAndDismissBackdropModalHandler = ({ currentTarget }) => {
  //   onSetDepartureCity(currentTarget.id);
  //   closeCityPickerHandler();
  // };

  /**
   * this handler function is responsible for setting departure city and closing the modal after selection
   */
  const setDepartureBusStopAndDismissBackdropModalHandler = ({ currentTarget }) => {
    onSetDepartureBusStop(currentTarget.id);
    closeCityPickerHandler();
  };

  /**
   * this handler function is responsible for setting arrival city and closing the modal after selection
   */
  // const setArrivalCityAndDismissBackdropModalHandler = ({ currentTarget }) => {
  //   onSetArrivalCity(currentTarget.id);
  //   closeCityPickerHandler();
  // };

  /**
   * this handler function is responsible for setting arrival city and closing the modal after selection
   */
  const setArrivalBusStopAndDismissBackdropModalHandler = ({ currentTarget }) => {
    onSetArrivalBusStop(currentTarget.id);

    closeCityPickerHandler();
  };

  const setArrivalBusStopAfterArrivalOptionsRefreshedHandler = (id) => {
    onSetArrivalBusStop(id);
  };

  return {
    cityPickerId,
    displayCityPickerBackdropModal,
    openDepartureCityModalHandler,
    openArrivalCityModalHandler,
    closeCityPickerHandler,
    // setDepartureCityAndDismissBackdropModalHandler,
    // setArrivalCityAndDismissBackdropModalHandler
    setDepartureBusStopAndDismissBackdropModalHandler,
    setArrivalBusStopAndDismissBackdropModalHandler,
    setArrivalBusStopAfterArrivalOptionsRefreshedHandler
  };
};

export default useCityPicker;
