import { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import './Backdrop.scss';

const Backdrop = ({ isActivated, isLoadingBackdrop, isTransparent, onDismiss }) => {
  const backdropRef = useRef();

  useEffect(() => {
    let timer;
    if (isActivated) {
      backdropRef.current.classList.add('bd-fade-in');
      backdropRef.current.classList.remove('d-none');
      backdropRef.current.classList.remove('bd-fade-out');
    } else {
      if (!backdropRef.current.classList.contains('d-none')) {
        backdropRef.current.classList.add('bd-fade-out');
        backdropRef.current.classList.remove('bd-fade-in');

        timer = setTimeout(() => {
          backdropRef.current.classList.add('d-none');
        }, 750);
      }
    }

    return () => clearTimeout(timer);
  }, [isActivated]);

  return (
    <div
      aria-label="Dismissable backdrop"
      onClick={onDismiss}
      ref={backdropRef}
      role="presentation"
      tabIndex="-1"
      className={`${isLoadingBackdrop ? 'loading-backdrop' : 'backdrop'} d-none ${
        isTransparent ? 'transparent-bg' : ''
      }`}
    />
  );
};

Backdrop.propTypes = {
  isActivated: PropTypes.bool.isRequired,
  isLoadingBackdrop: PropTypes.bool,
  isTransparent: PropTypes.bool,
  onDismiss: PropTypes.func
};

Backdrop.defaultProps = {
  isLoadingBackdrop: false,
  isTransparent: false,
  onDismiss: null
};

export default Backdrop;
