import React from 'react';

import PropTypes from 'prop-types';

import { Icon } from '.';

const InputError = ({ isInputError, isNativeTextInput, errorMessage }) => {
  return (
    <>
      {isInputError && (
        <Icon
          fontAwesomeIconClass="fas fa-exclamation-circle"
          fontAwesomeIconSize="14"
          iconBaseClass={`error-icon${isNativeTextInput ? '' : '--right-intended'}`}
        />
      )}
      {isInputError && <span className="d-inline-block mb-4 input-error-text">{errorMessage}</span>}
    </>
  );
};

InputError.propTypes = {
  isInputError: PropTypes.bool,
  isNativeTextInput: PropTypes.bool,
  errorMessage: PropTypes.string
};

InputError.defaultProps = {
  isInputError: false,
  isNativeTextInput: false,
  errorMessage: 'Erforderlich'
};

export default InputError;
