import { useHistory } from 'react-router-dom';

const useSyncNavigateToPage = (link) => {
  const history = useHistory();

  const navigateToPageHandler = () => history.push(link);

  return navigateToPageHandler;
};

export default useSyncNavigateToPage;
