import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isPopUpDisplayed: false,
  isShownByButtonClick: false,
  isVisitCountChecked: false,
  fullName: '',
  email: '',
  departure_date: new Date(),
  arrival_date: new Date(),
  phone: '',
  content: '',
  visitCount: +localStorage.getItem('rj_charter_popup_display_counter') || 0,
  result: null,
  unvalidatedFields: []
};

export const charterPopUpSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    SET_FORM_FIELD: (state, action) => {
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value
      };
    },
    OPEN_POP_UP: (state) => {
      return {
        ...state,
        isPopUpDisplayed: true
      };
    },
    CLOSE_POP_UP: (state) => {
      return {
        ...state,
        isPopUpDisplayed: false
      };
    },
    RESET: () => initialState,
    INCREMENT_VISIT_COUNT: (state) => {
      return {
        ...state,
        visitCount: state.visitCount + 1,
        isVisitCountChecked: true,
        isPopUpDisplayed: state.visitCount < 2 ? true : state.isPopUpDisplayed
      };
    },
    DECREMENT_VISIT_COUNT: (state) => {
      return {
        ...state,
        visitCount: state.visitCount + 1,
        isVisitCountChecked: true
      };
    },
    SHOW_BY_BUTTON_CLICK: (state) => {
      return {
        ...state,
        isShownByButtonClick: true,
        isPopUpDisplayed: true
      };
    },
    SET_UNVALIDATED_FIELDS: (state, action) => {
      return {
        ...state,
        unvalidatedFields: action.payload
      };
    },
    START_LOADING: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    STOP_LOADING: (state) => {
      return {
        ...state,
        isLoading: false
      };
    },
    SET_RESULT: (state, action) => {
      return {
        ...state,
        result: action.payload
      };
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  CLOSE_POP_UP,
  DECREMENT_VISIT_COUNT,
  INCREMENT_VISIT_COUNT,
  OPEN_POP_UP,
  RESET,
  SET_FORM_FIELD,
  SET_UNVALIDATED_FIELDS,
  SHOW_BY_BUTTON_CLICK,
  START_LOADING,
  STOP_LOADING,
  SET_RESULT
} = charterPopUpSlice.actions;

export default charterPopUpSlice.reducer;
