import React from 'react';

import PropTypes from 'prop-types';

import Image from '../Image';

import { landingImageSrc } from '.';

import './PageLanding.scss';

/**
 * "children" prop for this component may be provided for example putting a text over the
 * page landing image as it is done in Magazine page. But it is not required, since by default
 * this component does not take any children.
 */
const PageLanding = ({ children, customLandingImageSrc, isDifferentLandingImageSrcUsed }) => {
  if (children) {
    // if any children is provided for the component,
    if (isDifferentLandingImageSrcUsed) {
      // if a different page landing image source other than default one is used,
      return (
        <div className="page-landing-img position-relative d-flex justify-content-center align-items-center">
          <Image
            imgAltText={customLandingImageSrc.altText}
            figureClass="w-100 h-100"
            imgFallbackSrc={customLandingImageSrc.fallback}
            imgWebpSrc={!customLandingImageSrc.isSvg && customLandingImageSrc.webp}
            isSvg={customLandingImageSrc.isSvg}
          />
          {children}
        </div>
      );
    }

    // DO NOT add an alternative render for which "children" are provided but default page landing image is used, since there is no such page currently! If so, then it can be added as well
  } else if (isDifferentLandingImageSrcUsed) {
    return (
      <Image
        imgAltText={customLandingImageSrc.altText}
        figureClass="page-landing-img"
        imgFallbackSrc={customLandingImageSrc.fallback}
        imgWebpSrc={!customLandingImageSrc.isSvg && customLandingImageSrc.webp}
        isRelativelyPositioned
        isSvg={customLandingImageSrc.isSvg}
      />
    );
  }

  return (
    <Image
      imgAltText="RoadJet Bus on Street in Night for Page Landing"
      figureClass="page-landing-img"
      imgFallbackSrc={landingImageSrc.fallback}
      imgWebpSrc={landingImageSrc.webp}
      isRelativelyPositioned
    />
  );
};

PageLanding.propTypes = {
  children: PropTypes.node,
  customLandingImageSrc: PropTypes.shape({
    altText: PropTypes.string,
    fallback: PropTypes.string,
    isSvg: PropTypes.bool,
    webp: PropTypes.string
  }),
  isDifferentLandingImageSrcUsed: PropTypes.bool
};

PageLanding.defaultProps = {
  children: null,
  customLandingImageSrc: null,
  isDifferentLandingImageSrcUsed: false
};

export default PageLanding;
