import PropTypes from 'prop-types';

import './LoginButton.scss';

import { useUser } from '../../hooks';

import { Button, Icon } from '.';

const LoginButton = ({ isRelativelyPositioned, onToggleAuthBox }) => {
  const { user } = useUser();

  let avatarName = '';
  if (user) {
    avatarName = user?.email[0];

    if (user?.name) {
      avatarName = user?.name[0];
    }

    if (user?.name && user?.surname) {
      avatarName += user?.surname[0];
    }
  }

  return user ? (
    <>
      <Button
        customClass="toggle-authBox-btn cursor-pointer font-13 font-weight-medium overflow-hidden"
        isInlineFlex
        isRelativelyPositioned={isRelativelyPositioned}
        onClick={onToggleAuthBox}>
        {user.profilePhotoUrl ? (
          <img src={user.profilePhotoUrl} alt="user" className="userAvatar w-100 h-100" />
        ) : (
          <span className="text-secondary font-16">{avatarName.toUpperCase()}</span>
        )}
      </Button>
    </>
  ) : (
    <Button customClass={`position-${isRelativelyPositioned ? 'relative' : 'absolute'}`} to="/auth">
      <Icon
        color="secondary"
        fontAwesomeIconClass="fas fa-user"
        fontAwesomeIconSize="26"
        iconBaseClass="d-lg-none mr-1"
        isRelativelyPositioned
      />
      <Icon
        color="secondary"
        fontAwesomeIconClass="fas fa-user"
        fontAwesomeIconSize="20"
        iconBaseClass="d-none d-lg-inline-block mr-2"
        isRelativelyPositioned
      />
      <span className="text-secondary font-16 d-none d-lg-inline-block">LOGIN</span>
    </Button>
  );
};

LoginButton.propTypes = {
  isRelativelyPositioned: PropTypes.bool,
  onToggleAuthBox: PropTypes.func.isRequired
};

LoginButton.defaultProps = {
  isRelativelyPositioned: false
};

export default LoginButton;
