import React, { lazy, Suspense } from 'react';

import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Loading from '../components/Loading';
import ScrollHandler from '../components/ScrollHandler';
import { UserProvider } from '../hooks';
// import NetworkStatusProvider from '../store/NetworkStatusProvider';
import SearchDataProvider from '../store/SearchDataProvider';
import store from '../store/store';

const PrivateRoute = lazy(() => import('../components/PrivateRoute'));

const About = lazy(() => import('../pages/About'));
const Auth = lazy(() => import('../pages/Auth'));
const Berlin = lazy(() => import('../pages/SEO/Berlin'));
const BusFahrer = lazy(() => import('../pages/BusFahrer'));
const BusTours = lazy(() => import('../pages/SEO/BusTours'));
const BusTravelFuture = lazy(() => import('../pages/SEO/BusTravelFuture'));
const Corona = lazy(() => import('../pages/Covid-19'));
const Contact = lazy(() => import('../pages/Contact'));
const DataAnalyst = lazy(() => import('../pages/DataAnalyst'));
const DataPrivacy = lazy(() => import('../pages/DataPrivacy'));
const FAQ = lazy(() => import('../pages/FAQ'));
const Features = lazy(() => import('../pages/Features'));
const GeneralTermsAndConditions = lazy(() => import('../pages/GTC'));
const Hamburg = lazy(() => import('../pages/SEO/Hamburg'));
const Home = lazy(() => import('../pages/Home'));
const Jobs = lazy(() => import('../pages/Jobs'));
const LegalNotice = lazy(() => import('../pages/LegalNotice'));
const Leipzig = lazy(() => import('../pages/SEO/Leipzig'));
const Magazine = lazy(() => import('../pages/Magazine'));
const Marketing = lazy(() => import('../pages/Marketing'));
const MyBookings = lazy(() => import('../pages/Dashboard/MyBookings'));
const MyProfile = lazy(() => import('../pages/Dashboard/MyProfile'));
const Newsroom = lazy(() => import('../pages/Newsroom'));
const NoMatchPage = lazy(() => import('../pages/NoMatchPage'));
const Nurnberg = lazy(() => import('../pages/SEO/Nurnberg'));
const Overview = lazy(() => import('../pages/Overview'));
const PassengersDetails = lazy(() => import('../pages/PassengersDetails'));
const PaymentSuccess = lazy(() => import('../pages/PaymentSuccess'));
const RoadJetCorporate = lazy(() => import('../pages/RoadJetCorporate'));
const SearchList = lazy(() => import('../pages/SearchList'));
const SeatSelection = lazy(() => import('../pages/SeatSelection'));
const Stuttgart = lazy(() => import('../pages/SEO/Stuttgart'));
const TicketCancellationWithoutLogin = lazy(() =>
  import('../pages/TicketCancellationWithoutLogin')
);
const TouristicPlaces = lazy(() => import('../pages/SEO/TouristicPlaces'));

/**
 * Routing Pages
 * Add all site pages here
 */

/**
 * For detailed explanation of how <NetworkStatusProvider> is used, please refer to the following and read till
 * the end, since all of the steps written in the article are implemented in the application as well!
 * https://levelup.gitconnected.com/useonlinestatus-a-react-hook-to-know-when-your-app-is-offline-2d06e4e536a
 */
export default (
  // <NetworkStatusProvider>
  <Router>
    {process.env.REACT_APP_ENV === 'prod' && <ScrollHandler />}
    <Suspense fallback={<Loading />}>
      <Provider store={store}>
        <UserProvider>
          <SearchDataProvider>
            <Switch>
              <PrivateRoute path="/account/meine-buchungen" component={MyBookings} />
              <PrivateRoute path="/account/profil" component={MyProfile} />
              <PrivateRoute path="/account" component={MyBookings} />
              <Route path="/agb" component={GeneralTermsAndConditions} />
              <Route path="/auth" component={Auth} />
              <Route path="/berlin" component={Berlin} />
              <Route path="/corporate" component={RoadJetCorporate} />
              <Route path="/covid19-hygienekonzept" component={Corona} />
              <Route path="/datenschutz" component={DataPrivacy} />
              <Route path="/entdecken-busreisen" component={BusTours} />
              <Route path="/faq" component={FAQ} />
              <Route path="/features" component={Features} />
              <Route path="/hamburg" component={Hamburg} />
              <Route path="/hinfahrt" component={SearchList} />
              <Route path="/impressum" component={LegalNotice} />
              <Route path="/jobs/bus-fahrer" component={BusFahrer} />
              <Route path="/jobs/data-analyst" component={DataAnalyst} />
              <Route path="/jobs/marketing" component={Marketing} />
              <Route path="/jobs" component={Jobs} />
              <Route path="/kontakt" component={Contact} />
              <Route path="/leipzig" component={Leipzig} />
              <Route path="/magazin" component={Magazine} />
              <Route path="/newsroom" component={Newsroom} />
              <Route path="/nurnberg" component={Nurnberg} />
              <Route path="/passenger-form" component={PassengersDetails} />
              <Route path="/payment-success" component={PaymentSuccess} />
              <Route path="/ruckfahrt" component={SearchList} />
              <Route path="/sitzauswahl-hinfahrt" component={SeatSelection} />
              <Route path="/sitzauswahl-ruckfahrt" component={SeatSelection} />
              <Route path="/stadtereisen-in-deutschland" component={TouristicPlaces} />
              <Route path="/stuttgart" component={Stuttgart} />
              <Route path="/ticketstornierung" component={TicketCancellationWithoutLogin} />
              <Route path="/uber-uns" component={About} />
              <Route path="/uberblick-zahlung" component={Overview} />
              <Route path="/zukunft-der-reisebranche" component={BusTravelFuture} />
              <Route path="/" exact component={Home} />
              <Route component={NoMatchPage} />
            </Switch>
          </SearchDataProvider>
        </UserProvider>
      </Provider>
    </Suspense>
  </Router>
  // </NetworkStatusProvider>
);
