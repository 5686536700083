import { useEffect, useState } from 'react';

import './TicketCancellationCard.scss';
import { Backdrop, Icon, Input, Modal, QRCode } from '.';

const TCCItemMobile = ({
  ticket,
  selectedTickets,
  setSelectedTickets,
  type,
  isSummaryItem = false,
  isSelectable
}) => {
  const QRPromise = QRCode.toDataURL(ticket.qrCode, {
    color: { light: '#0000' },
    margin: 0
  });

  const [qrCodeDataUrl, setQrCodeDataUrl] = useState('');
  const [isQrCodeDisplayed, setIsQrCodeDisplayed] = useState(false);

  useEffect(() => {
    QRPromise.then((qr) => {
      setQrCodeDataUrl(qr);
    });
  }, []);

  return (
    <div
      className={`tcc-item-mobile d-flex mx-0 py-2 my-4 ${
        !ticket.isCancellable ? 'cancelled-ticket' : ''
      }`}>
      {type === 'booking-id' &&
        (ticket.isCancellable ? (
          <div className={`my-auto ml-3 mr-2 ${!isSelectable ? 'd-none' : ''}`}>
            <Input
              customClass=""
              htmlFor="selectTicket"
              id="selectTicket"
              isBorderedCheckbox
              isChecked={selectedTickets.includes(ticket.id)}
              name="selectTicket"
              onChange={() => {
                !selectedTickets.includes(ticket.id)
                  ? setSelectedTickets([...selectedTickets, ticket.id])
                  : setSelectedTickets(
                      selectedTickets.filter((ticketId) => ticketId !== ticket.id)
                    );
              }}
              type="checkbox"
            />
          </div>
        ) : (
          isSelectable && <div className="tcc-blank-32 ml-3 mr-2"></div>
        ))}

      {type === 'ticket-id' && !isSummaryItem && (
        <div className={`my-auto ml-3 mr-2 d-none`}>
          <Input type="checkbox" />
        </div>
      )}

      <div
        className={`d-inline-block w-80 ${isSummaryItem ? 'mx-auto' : ''} ${
          !isSelectable || type === 'ticket-id' ? 'mx-auto' : ''
        } position-relative`}>
        <div className="row mx-0 d-flex justify-content-between  pb-1">
          <span className="font-weight-bold text-dark">
            {`${ticket.passengerName} ${ticket.passengerSurname}`}
          </span>
          {ticket.isCancellable && !isSummaryItem && (
            <>
              {/* <button
                className="text-primary bg-transparent border-0"
                onClick={() => setIsQrCodeDisplayed(true)}>
                Siehe QR
              </button> */}
              <Backdrop
                isActivated={isQrCodeDisplayed}
                onDismiss={() => setIsQrCodeDisplayed(false)}
              />
              <Modal customClass="qr-modal" isModalActivated={isQrCodeDisplayed}>
                <div className="modal-header ">
                  Ticketnummer: <span className="text-primary font-weight-bold">{ticket.id}</span>
                </div>
                <div className="modal-body text-center">
                  <div>
                    <img src={qrCodeDataUrl} className="qr img-fluid " alt="qr" />
                  </div>
                  <div className="mb-1 mt-3 text-dark">
                    {ticket.fromBusStopName} - {ticket.toBusStopName}
                  </div>
                  {/* <div className="my-1 text-dark">
                    {ticket?.dateDetails.day} {ticket?.dateDetails.monthName}{' '}
                    {ticket?.dateDetails.fullYear} / {ticket.departureTime}
                  </div> */}
                  <div className="my-1 text-dark font-weight-bold">
                    {ticket.passengerName} {ticket.passengerSurname} / {ticket.seatNu}
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-primary" onClick={() => setIsQrCodeDisplayed(false)}>
                    OK
                  </button>
                </div>
              </Modal>
            </>
          )}
          {ticket.status === 'ONGOING' && <div className="badge badge-warning ">Geplant</div>}
          {ticket.status === 'CANCELLED' && <div className="badge badge-danger ">Storniert</div>}
          {ticket.status === 'COMPLETED' && (
            <div className="badge badge-primary my-auto">Abgeschlossen</div>
          )}
        </div>

        <hr className="ticket-cancellation-border mt-1 mb-2" />
        <div className="row mx-0 d-flex justify-content-between">
          <div className="d-flex">
            <div className="w-50 position-relative">
              <Icon
                fontAwesomeIconClass="fas fa-circle"
                fontAwesomeIconSize="10"
                color="primary"
                iconBaseClass="tcc-departure-icon"
              />

              <hr className="tcc-road-icon border-primary" />
              <Icon
                fontAwesomeIconClass="far fa-circle"
                fontAwesomeIconSize="10"
                color="primary"
                iconBaseClass="tcc-arrival-icon"
              />
            </div>
            <div className="d-flex flex-wrap">
              <span className="w-100">{ticket.fromCityAbbr}</span>
              <span className="">{ticket.toCityAbbr}</span>
            </div>
          </div>
          <div className="d-flex">
            <span className="bg-primary text-white font-12 tcc-seat-icon mr-3">
              {ticket.seatNu}
            </span>
            <div>
              <span>
                <Icon fontAwesomeIconClass="fas fa-bus mr-2" isRelativelyPositioned color="dark" />
              </span>
              <span className="">{ticket.date}</span>
            </div>
          </div>
        </div>
        <span className="text-dark tcc-ticket-id">#{ticket.id}</span>
        <span className="font-weight-bold text-dark tcc-price">{`${ticket.netPrice}€`}</span>
      </div>
    </div>
  );
};

export default TCCItemMobile;
