import { useState } from 'react';

/**
 * this hook is responsible for selecting a tab nav item and
 * display the respective content within the customized tab view area.
 */
const useTab = (tabNames = ['']) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0); // state to keep track of the index of the currently selected tab
  const [selectedTab, setSelectedTab] = useState(tabNames[selectedTabIndex]); // state to keep track of which tab nav item is currently selected

  // this handler function is responsible for updating the selected tab
  const selectTabHandler = ({ currentTarget }) => {
    const activeTab = tabNames.find((tab) => tab.id === currentTarget.id); // extract which tab is selected
    const activeTabIndex = tabNames.findIndex((tab) => tab.id === currentTarget.id); // extract the index of currently selected tab
    setSelectedTab(activeTab); // perform necessary state update
    setSelectedTabIndex(activeTabIndex); // perform necessary state update
  };

  return [selectedTab, selectedTabIndex, selectTabHandler];
};

export default useTab;
