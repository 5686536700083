import { useState } from 'react';

import './TicketCancellationWithoutLogin.scss';
import { useTab, useForm, useToggle, useTicketCancellationSearch } from '../../hooks';

import {
  cancellationByBookingIdInputs,
  cancellationByBookingIdFormInitialState,
  cancellationByTicketIdInputs,
  cancellationByTicketIdFormInitialState,
  faqCardContent,
  initialState,
  ticketCancellationTableCaptions,
  ticketCancellationWithoutLoginTabs,
  metaInfo
} from './util';

import {
  FaqComponent,
  Footer,
  Header,
  PageLanding,
  PageTitle,
  ReactHelmet,
  TabView,
  TicketCancellationCard,
  TicketCancellationModal,
  CancellationByBookingIdTab,
  CancellationByTicketIdTab
} from '.';

const TicketCancellationWithoutLogin = () => {
  const [isTicketCancellationModalDisplayed, setIsTicketCancellationModalDisplayed] =
    useState(false);
  const [ticketsToCancel, setTicketsToCancel] = useState([]);

  const [cancellationByBookingIdFormInputsState, cancellationByBookingIdInputChangedHandler] =
    useForm(cancellationByBookingIdFormInitialState);

  const [cancellationByTicketIdFormInputsState, cancellationByTicketIdInputChangedHandler] =
    useForm(cancellationByTicketIdFormInitialState);

  const [
    tableData,
    isError,
    isLoading,
    email,
    isEmailSent,
    ticketCancellationCardType,
    payerName,
    processId,
    ticketErrorMessage,
    bookingErrorMessage,
    searchHandler,
    verificationHandler
  ] = useTicketCancellationSearch({
    ticketBody: {
      ticketId: cancellationByTicketIdFormInputsState.ticket_id.value,
      surname: cancellationByTicketIdFormInputsState.cancellation_surname.value
    },
    bookingBody: {
      bookingId: cancellationByBookingIdFormInputsState.booking_id.value
    }
  });

  const { expandCardContent, toggleCardContentDisplayHandler } = useToggle(
    initialState,
    'faq-card'
  );

  const [selectedTab, selectedTabIndex, selectTabHandler] = useTab(
    ticketCancellationWithoutLoginTabs
  );

  const cancelButtonClickHandler = (selectedTickets) => {
    setIsTicketCancellationModalDisplayed(true);

    setTicketsToCancel(tableData.filter((ticket) => selectedTickets.includes(ticket.id)));
  };

  return (
    <>
      <ReactHelmet metaInfo={metaInfo} />
      <Header />

      <main className="ticket-cancellation-without-login position-relative mb-8">
        <PageLanding />
        <PageTitle pageTitle="Ticketstornierung" />

        <div className="container-fluid">
          <h2 className="text-secondary text-center font-weight-bold mb-6 mb-md-8">
            Stornierungen & Umbuchungen
          </h2>
          <p className="text-center text-dark mb-6 ">
            Dir kommt kurzfristig etwas dazwischen? Dein Reiseziel hat sich geändert? Oder ein
            Termin zwingt dich die Fahrtzeit deiner gebuchten Reise zu ändern?
          </p>
          <p className="text-center text-dark mb-12 ">
            Dann kannst du jetzt ganz einfach deine Buchungsdetails bearbeiten. Mithilfe deiner
            Buchungsnummer kannst du deine gebuchte Fahrt verwalten und deinen wünschen entsprechend
            anpassen. Viel Spaß!
          </p>
        </div>

        <div className="ticket-cancellation-tab-wrapper container-fluid mx-auto">
          <TabView
            tabs={ticketCancellationWithoutLoginTabs}
            height={150}
            selectedTab={selectedTab}
            selectedTabIndex={selectedTabIndex}
            onSelectTab={selectTabHandler}
            tabViewChildren={[
              <CancellationByBookingIdTab
                key={ticketCancellationWithoutLoginTabs[0].id}
                cancellationByBookingIdInputs={cancellationByBookingIdInputs}
                cancellationByBookingIdFormInputsState={cancellationByBookingIdFormInputsState}
                email={email}
                errorMessage={bookingErrorMessage}
                isLoading={isLoading}
                isEmailSent={isEmailSent}
                onChange={cancellationByBookingIdInputChangedHandler}
                onSubmit={searchHandler.bind(this, 'booking')}
                onVerificationCodeSubmit={verificationHandler}
              />,
              <CancellationByTicketIdTab
                key={ticketCancellationWithoutLoginTabs[1].id}
                cancellationByTicketIdInputs={cancellationByTicketIdInputs}
                cancellationByTicketIdFormInputsState={cancellationByTicketIdFormInputsState}
                errorMessage={ticketErrorMessage}
                isError={isError}
                isLoading={isLoading}
                onChange={cancellationByTicketIdInputChangedHandler}
                onSubmit={searchHandler.bind(this, 'ticket')}
              />
            ]}
          />
        </div>

        {tableData.length > 0 && (
          <div id="ticket-cancellation-card-section" className="bg-primary-light py-10 px-4">
            <TicketCancellationCard
              rowData={tableData}
              type={ticketCancellationCardType}
              payerName={payerName}
              captions={ticketCancellationTableCaptions}
              onCancelButtonClick={cancelButtonClickHandler}
            />
          </div>
        )}

        <TicketCancellationModal
          isTicketCancellationModalDisplayed={isTicketCancellationModalDisplayed}
          onDismiss={() => setIsTicketCancellationModalDisplayed(false)}
          processId={processId}
          ticketsToCancel={ticketsToCancel}
        />

        <div className="container-fluid">
          <h2 className="text-secondary text-center font-weight-bold mb-6 mb-md-8 mt-12">
            Fragen zur Ticketumbuchung- oder Stornierung
          </h2>
          <FaqComponent
            faqCardContent={faqCardContent}
            expandCardContent={expandCardContent}
            toggleCardContentDisplayHandler={toggleCardContentDisplayHandler}
          />
        </div>
      </main>

      <Footer />
    </>
  );
};

export default TicketCancellationWithoutLogin;
