import React, { useRef, useState } from 'react';

import { useNavigateToPage } from '.';

const useSeatSelection = (apiData, isRoundTrip, queryResult) => {
  const [activeDeck, setActiveDeck] = useState('top'); // state to keep actively displayed deck id in mobile version
  const [selectedSeats, setSelectedSeats] = useState([]); // state to keep all currently selected seats by user
  const [isPopupModalActivated, setIsPopupModalActivated] = useState(false); // state to control display of popup modal for selection of D5, E5, F4 and F5
  const [isInvalidNumberOfSeatsSelected, setIsInvalidNumberOfSeatsSelected] = useState(false);
  const [popupModalContent, setPopupModalContent] = useState(null);
  const selectedIsofixOrDisabledSeatRef = useRef();

  const body = {
    seats: selectedSeats
  };
  const path = `/reservations/${queryResult?.reservationId}/seats/${queryResult?.step}`;

  const nextPagePath =
    isRoundTrip && queryResult.step === 'departure' ? '/ruckfahrt' : '/passenger-form';

  const [isError, isLoading, navigateToPageHandler] = useNavigateToPage(
    body,
    path,
    nextPagePath,
    'put'
  );

  // following 2 handler functions will be used only for mobile version
  const setActiveDeckToBottomHandler = () => setActiveDeck('bottom'); // handler function to set active deck to "bottom"
  const setActiveDeckToTopHandler = () => setActiveDeck('top'); // handler function to set active deck to "top"

  const openDisabledOrIsofixSeatSelectionPopupModalHandler = (selectedSeat) => {
    selectedIsofixOrDisabledSeatRef.current = selectedSeat;
    /**
     * the following if/else block is used to assign the respective modal title
     * and info text based on the selected seat is either isofix or disabled seat
     */
    if (selectedSeat === 'D5' || selectedSeat === 'E5') {
      setPopupModalContent(() => (
        <>
          <h2 className="popup-title mr-auto font-weight-bold font-20 mb-5">
            Isofixbefestigung für Kindersitze
          </h2>
          <span className="text-dark font-16 mb-5">
            Dieser Sitz ist mit einem Dreipunktgurt sowie einer Isofixbefestigung für Kindersitze
            ausgestattet.
          </span>
        </>
      ));
    } else if (selectedSeat === 'F4' || selectedSeat === 'F5') {
      setPopupModalContent(() => (
        <>
          <h2 className="popup-title mr-auto font-weight-bold font-20 mb-5">
            <span>Rollstuhl</span>
          </h2>
          <span className="text-dark font-16 mb-5">
            Dieser Sitz ist für Rollstuhlfahrer vorgehalten. Gegebenenfalls kann sich dein finaler
            Sitzplatz ändern.
          </span>
        </>
      ));
    }

    setIsPopupModalActivated(() => {
      const isIsofixOrDisabledSeatSelectedBefore = selectedSeats.some(
        (seat) => seat === selectedSeat
      );
      if (
        selectedSeat === 'D5' ||
        selectedSeat === 'E5' ||
        selectedSeat === 'F4' ||
        selectedSeat === 'F5'
      ) {
        if (isIsofixOrDisabledSeatSelectedBefore) return false;
        return true;
      }
      return false;
    });
  };

  /**
   * this handler function is responsible for closing the modal which is
   * activated whenever a selected seat is either isofix or disabled seat
   */
  const closeDisabledOrIsofixSeatSelectionPopupModalHandler = () => setIsPopupModalActivated(false);

  /**
   * this handler function is responsible for populating/depopulating the list of all selected seats by user in one session.
   * if a seat is already selected before, it will be removed from the list, otherwise it will be added.
   * this handler function is passed through the component "BusSeat" and its actual functionality can be seen in that component.
   */
  const seatSelectionHandler = (seatSelected) => {
    const selectedSeat = seatSelected || selectedIsofixOrDisabledSeatRef.current;

    setSelectedSeats((prevState) => {
      const isSeatAlreadySelected = [...prevState].some((seat) => seat === selectedSeat); // try to find if currently selected seat is already selected before
      if (isSeatAlreadySelected) {
        return [...prevState].filter((seat) => seat !== selectedSeat); // extract the selected seat from the list of all selected seats (toggle seat selection status)
      }

      /**
       * if required number of passenger seats is selected and yet another seat selection is to be made,
       * then replace the last seat selected with the currently selected one.
       */
      if ([...prevState].length === apiData?.passengerCount) {
        const currentSelectedSeats = [...prevState];
        const lastIndex = [...prevState].length - 1;
        currentSelectedSeats[lastIndex] = selectedSeat;

        return currentSelectedSeats;
      }

      return [...prevState, selectedSeat]; // if seat is not selected yet, add it to the list of all selected seats
    });

    closeDisabledOrIsofixSeatSelectionPopupModalHandler();
  };

  /**
   * this handler function is responsible for providing navigation for user to the next page conditionally.
   * if user has not selected the required number of seats yet, s/he cannot move to the next page, otherwise s/he can continue.
   */
  const conditionallyNavigateToPassengerFormPageHandler = () => {
    if (selectedSeats.length === apiData?.passengerCount) return navigateToPageHandler();
    return setIsInvalidNumberOfSeatsSelected(true);
  };

  const closeInvalidSeatsSelectionModalHandler = () => setIsInvalidNumberOfSeatsSelected(false);

  return {
    activeDeck,
    isError,
    isLoading,
    isPopupModalActivated,
    isInvalidNumberOfSeatsSelected,
    popupModalContent,
    selectedSeats,
    setActiveDeckToBottomHandler,
    setActiveDeckToTopHandler,
    openDisabledOrIsofixSeatSelectionPopupModalHandler,
    closeDisabledOrIsofixSeatSelectionPopupModalHandler,
    seatSelectionHandler,
    conditionallyNavigateToPassengerFormPageHandler,
    closeInvalidSeatsSelectionModalHandler
  };
};

export default useSeatSelection;
