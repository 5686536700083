const mobileCarouselInitOptions = {
  initialSlide: 0,
  spaceBetween: 20,
  loop: true,
  // threshold: 45,
  speed: 500,
  slideToClickedSlide: true,
  centeredSlides: true,
  roundLengths: true,
  setWrapperSize: true,
  preloadImages: false,
  a11y: true
};

export default mobileCarouselInitOptions;
