import PropTypes from 'prop-types';

import './TabView.scss';

const TabView = ({ tabs, selectedTab, selectedTabIndex, onSelectTab, tabViewChildren }) => {
  return (
    <>
      <div className="tab-view-navbar d-flex align-items-center">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            id={tab.id}
            aria-label={`${tab.name} button`}
            className={`tab-nav-item h-100 d-flex justify-content-center align-items-center text-secondary font-weight-bold tab-${
              selectedTab.name === tab.name ? 'selected' : 'unselected'
            }`}
            onClick={onSelectTab}
            role="button"
            tabIndex="0">
            {tab.name}
          </div>
        ))}
      </div>
      <div className="tab-content py-6 px-4 p-md-6 container-fluid shadow bg-light-container mb-8">
        {tabViewChildren[selectedTabIndex]}
      </div>
    </>
  );
};

TabView.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  selectedTab: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  selectedTabIndex: PropTypes.number.isRequired,
  onSelectTab: PropTypes.func.isRequired,
  tabViewChildren: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default TabView;
