import { useState, useEffect } from 'react';

import { useWindowDimensions } from '../../hooks';

import './TicketCancellationCard.scss';
import { TCCContentDesktop, TCCContentMobile, TCCHeaderDesktop, TCCHeaderMobile } from '.';

const TicketCancellationCard = ({
  captions,
  createdAt,
  isOnDashboard = false,
  // isFirst,
  rowData,
  type,
  payerName,
  onCancelButtonClick
}) => {
  const [isContentHidden, setIsContentHidden] = useState(false);
  const [isSelectable, setIsSelectable] = useState(false);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [isAllTicketsSelected, setIsAllTicketsSelected] = useState(false);

  const allTicketsExceptCancelled = rowData
    .filter((ticket) => ticket.isCancellable)
    .map((ticket) => ticket.id);

  // This useEffect hook turns the "all selected" checkbox to checked if all selectable items are selected
  useEffect(() => {
    selectedTickets.length === allTicketsExceptCancelled.length &&
    allTicketsExceptCancelled.length > 0
      ? setIsAllTicketsSelected(true)
      : setIsAllTicketsSelected(false);
  }, [selectedTickets]);

  const { width } = useWindowDimensions();

  return (
    <div
      className={`container  ${isContentHidden ? 'hide-content' : 'show-content'} ${
        isOnDashboard ? 'on-dashboard' : ''
      }`}>
      {width > 1023 ? (
        <>
          <TCCHeaderDesktop
            bookingId={rowData[0]?.bookingId || ''}
            isAllCancelled={allTicketsExceptCancelled.length < 1}
            createdAt={createdAt}
            payerName={payerName}
            isChecked={isAllTicketsSelected}
            isCancelButtonDisabled={type === 'booking-id' && !(selectedTickets.length > 0)}
            isContentHidden={isContentHidden}
            isOnDashboard={isOnDashboard}
            isSelectable={isOnDashboard ? isSelectable : true}
            setIsSelectable={setIsSelectable}
            onCancelButtonClick={onCancelButtonClick.bind(
              this,
              type == 'booking-id' ? selectedTickets : allTicketsExceptCancelled
            )}
            onChange={() =>
              !isAllTicketsSelected
                ? setSelectedTickets(allTicketsExceptCancelled)
                : setSelectedTickets([])
            }
            onToggleContentShow={() => setIsContentHidden(!isContentHidden)}
            type={type}
          />
          <TCCContentDesktop
            allTickets={rowData}
            captions={captions}
            selectedTickets={selectedTickets}
            setSelectedTickets={setSelectedTickets}
            type={type}
            isSelectable={isOnDashboard ? isSelectable : true}
          />
        </>
      ) : (
        <>
          <TCCHeaderMobile
            bookingId={rowData[0]?.bookingId || ''}
            isAllCancelled={allTicketsExceptCancelled.length < 1}
            createdAt={createdAt}
            payerName={payerName}
            isChecked={isAllTicketsSelected}
            isCancelButtonDisabled={type === 'booking-id' && !(selectedTickets.length > 0)}
            isContentHidden={isContentHidden}
            isOnDashboard={isOnDashboard}
            isSelectable={isOnDashboard ? isSelectable : true}
            onCancelButtonClick={onCancelButtonClick.bind(
              this,
              type == 'booking-id' ? selectedTickets : allTicketsExceptCancelled
            )}
            onChange={() =>
              !isAllTicketsSelected
                ? setSelectedTickets(allTicketsExceptCancelled)
                : setSelectedTickets([])
            }
            onToggleContentShow={() => setIsContentHidden(!isContentHidden)}
            setIsSelectable={setIsSelectable}
            type={type}
          />
          <TCCContentMobile
            allTickets={rowData}
            captions={captions}
            isSelectable={isOnDashboard ? isSelectable : true}
            selectedTickets={selectedTickets}
            setIsSelectable={setIsSelectable}
            setSelectedTickets={setSelectedTickets}
            type={type}
          />
        </>
      )}
    </div>
  );
};

export default TicketCancellationCard;
