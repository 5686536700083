const useTimeSchedule = (apiData) => {
  const timeScheduleData = apiData
    ? apiData?.map((data) => {
        return {
          col1: data.fromBusStopName,
          col2: data.toBusStopName,
          col3: data.departureTime,
          col4: data.arrivalTime,
          col5: data.days
        };
      })
    : [{ col1: '', col2: '', col3: '', col4: '', col5: '' }];

  return { timeScheduleData }; // return ready-to-use time schedule data
};

export default useTimeSchedule;
