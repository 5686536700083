import { useState } from 'react';

import { sweetAlert } from '../utils';

const useDatePicker = (
  onSetRoundTrip,
  onSetDepartureDate,
  onSetArrivalDate,
  isReturnRouteAvailable
) => {
  const [displayDatePickerBackdropModal, setDisplayDatePickerBackdropModal] = useState(false);
  const [datePickerId, setDatePickerId] = useState(null);

  /**
   * this handler function is responsible for opening departure date picker modal and the associated backdrop element
   */
  const openDepartureDatePickerHandler = () => {
    setDisplayDatePickerBackdropModal(true);
    setDatePickerId('departure');
  };

  /**
   * this handler function is responsible for opening arrival date picker modal and the associated backdrop element
   */
  const openArrivalDatePickerHandler = () => {
    if (isReturnRouteAvailable) {
      setDisplayDatePickerBackdropModal(true);
      onSetRoundTrip();
      setDatePickerId('arrival');
    } else {
      sweetAlert('error', 'Rückfahrt ist nicht vorhanden!');
    }
  };

  /**
   * this handler function is responsible for closing date picker modal and dismissing the associated backdrop element
   */
  const closeDatePickerHandler = () => {
    setDisplayDatePickerBackdropModal(false); // dismiss both the modal and the backdrop
    setDatePickerId(''); // reset date picker id
  };

  /**
   * this handler function is responsible for setting departure date and closing the modal after selection
   */
  const setDepartureDateAndDismissBackdropModalHandler = (date) => {
    onSetDepartureDate(date);
    closeDatePickerHandler();
  };

  /**
   * this handler function is responsible for setting arrival date and closing the modal after selection
   */
  const setArrivalDateAndDismissBackdropModalHandler = (date) => {
    onSetArrivalDate(date);
    closeDatePickerHandler();
  };

  return {
    datePickerId,
    displayDatePickerBackdropModal,
    openDepartureDatePickerHandler,
    openArrivalDatePickerHandler,
    closeDatePickerHandler,
    setDepartureDateAndDismissBackdropModalHandler,
    setArrivalDateAndDismissBackdropModalHandler
  };
};

export default useDatePicker;
