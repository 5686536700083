import './Datatable.scss';

const Datatable = ({ captions, rowData, fields }) => {
  return (
    <>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              {captions.map((caption, i) => {
                return (
                  <th key={i} scope="col">
                    {caption}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {rowData?.map((data) => (
              <tr key={data.id}>
                {fields.map((field) => (
                  <td key={field}>{data[field]}</td>
                ))}

                <td>
                  <button className="btn btn-outline-danger btn-sm">Stornieren</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Datatable;
