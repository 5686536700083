import React from 'react';

import PropTypes from 'prop-types';

import './Input.scss';

import { InputError, PhoneInput } from '.';

const Input = ({
  checkboxLabelColor,
  checkboxSize,
  customClass,
  htmlFor,
  id,
  inputSize,
  isBorderedCheckbox,
  isChecked,
  isDisabled,
  isInputError,
  isLabelHidden,
  label,
  labelLeftMarginClass = 'mr-3',
  labelFontSize,
  name,
  onChange,
  onSelect,
  placeholder,
  radioInputLabelFontSize,
  radioInputSize,
  refEl,
  rows,
  type,
  value,
  defaultValue
}) => {
  const InputElement = type === 'textarea' ? 'textarea' : 'input';

  if (type === 'checkbox') {
    // return checkbox element with its associated label (satisfies accessibility criterion)
    return (
      <div className={`d-flex justify-content-start align-items-center ${customClass}`}>
        <input
          checked={isChecked}
          className={`checkbox-input no-focus-outline position-relative cursor-pointer d-inline-flex align-items-center bg-white rounded-sm ${labelLeftMarginClass} checkbox-input--${
            isBorderedCheckbox ? 'bordered' : 'no-border'
          } checkbox-input--${checkboxSize}`}
          id={id}
          disabled={isDisabled}
          name={name}
          onChange={onChange}
          type="checkbox"
        />
        {!isLabelHidden && (
          <label
            className={`line-height--1 text-left text-${checkboxLabelColor} font-${labelFontSize} mb-0 cursor-pointer`}
            htmlFor={htmlFor}>
            {label}
          </label>
        )}
      </div>
    );
  }

  /* if (type === 'date') {
    return (
      <div className={`date-input-wrapper d-flex flex-column align-items-start ${customClass}`}>
        {!isLabelHidden && (
          <label className="mb-2 line-height--1 text-left text-dark font-12" htmlFor={htmlFor}>
            {label}
          </label>
        )}
        <ReactDatePicker
          id={id}
          isDateInput
          isDateInputError={isInputError}
          name={name}
          onChange={onChange}
          value={value}
        />
        <InputError isInputError={isInputError} />
      </div>
    );
  } */

  if (type === 'phone') {
    return (
      <div className={`${customClass} d-flex flex-column align-items-start`}>
        {!isLabelHidden && (
          <label className="mb-2 line-height--1 text-left text-secondary" htmlFor={htmlFor}>
            {label}
          </label>
        )}
        <PhoneInput
          htmlFor={htmlFor}
          id={id}
          isPhoneInputError={isInputError}
          name={name}
          onChange={onChange}
          value={value}
        />
        <InputError isInputError={isInputError} />
      </div>
    );
  }

  if (type === 'radio') {
    return (
      <div
        aria-label="Custom radio input with control"
        className={`d-flex w-100 mr-4 no-focus-outline ${customClass}`}
        onClick={onSelect}
        role="button"
        tabIndex="0">
        <div
          className={`my-auto radio-input radio-input--${radioInputSize}`}
          style={{ padding: '2px' }}>
          {isChecked && <div></div>}
        </div>
        <span className={`w-90 ml-2 radio-input-label font-${radioInputLabelFontSize}`}>
          {label}
        </span>
      </div>
    );
  }

  return (
    // return either normal(single-lined) text input or multi-line text area element with its associated label (satisfies accessibility criterion)
    <div className="d-flex flex-column align-items-start position-relative w-100">
      {!isLabelHidden && (
        <label className="mb-2 line-height--1 text-left text-secondary" htmlFor={htmlFor}>
          {label}
        </label>
      )}
      <InputElement
        className={`text-input font-14 w-100 px-3 py-2 ${
          isInputError ? 'text-input--error' : ''
        } text-input--${inputSize} mb-${isInputError ? '1' : '4'} ${isDisabled ? 'disabled' : ''}`}
        id={id}
        disabled={isDisabled}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        ref={refEl}
        rows={rows}
        type={type}
        defaultValue={defaultValue}
        value={value}
      />
      <InputError isInputError={isInputError} isNativeTextInput />
    </div>
  );
};

Input.propTypes = {
  checkboxLabelColor: PropTypes.string,
  checkboxSize: PropTypes.string,
  customClass: PropTypes.string,
  htmlFor: PropTypes.string,
  id: PropTypes.string,
  inputSize: PropTypes.string,
  isBorderedCheckbox: PropTypes.bool,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isInputError: PropTypes.bool,
  isLabelHidden: PropTypes.bool,
  label: PropTypes.string,
  labelFontSize: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  radioInputLabelFontSize: PropTypes.string,
  radioInputSize: PropTypes.string,
  rows: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
};

Input.defaultProps = {
  checkboxLabelColor: 'white',
  checkboxSize: 'lg',
  customClass: '',
  htmlFor: null,
  id: null,
  inputSize: 'lg',
  isBorderedCheckbox: false,
  isChecked: false,
  isDisabled: false,
  isInputError: false,
  isLabelHidden: false,
  label: null,
  labelFontSize: '16',
  name: null,
  onChange: null,
  onSelect: null,
  placeholder: '',
  radioInputLabelFontSize: '14',
  radioInputSize: 'sm',
  rows: '5',
  type: 'text',
  value: null
};

export default Input;
