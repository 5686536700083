import { useEffect, useState } from 'react';

import './TicketCancellationCard.scss';
import { Backdrop, Input, Modal, QRCode } from '.';

const TCCItemDesktop = ({
  ticket,
  ticketCount,
  index,
  isSelectable,
  selectedTickets,
  setSelectedTickets,
  type
}) => {
  const QRPromise = QRCode.toDataURL(ticket?.qrCode, {
    color: { light: '#0000' },
    margin: 0
  });

  const [qrCodeDataUrl, setQrCodeDataUrl] = useState('');
  const [isQrCodeDisplayed, setIsQrCodeDisplayed] = useState(false);

  useEffect(() => {
    QRPromise.then((qr) => {
      setQrCodeDataUrl(qr);
    });
  }, []);

  return (
    <>
      <div className={`row py-3 px-6 ${!ticket.isCancellable ? 'cancelled-ticket' : ''}`}>
        {type === 'booking-id' && isSelectable ? (
          <div className={`${ticket.isCancellable ? '' : 'invisible'} col-2 my-auto`}>
            <Input
              customClass=""
              htmlFor="selectTicket"
              id="selectTicket"
              isBorderedCheckbox
              isChecked={selectedTickets.includes(ticket.id)}
              name="selectTicket"
              onChange={() => {
                !selectedTickets.includes(ticket.id)
                  ? setSelectedTickets([...selectedTickets, ticket.id])
                  : setSelectedTickets(
                      selectedTickets.filter((ticketId) => ticketId !== ticket.id)
                    );
              }}
              type="checkbox"
            />
          </div>
        ) : (
          ''
        )}

        <div className={`${type === 'ticket-id' ? 'col-6' : 'col-4'} my-auto`}>
          <div className="d-flex  align-content-center">
            <span className="passenger-name-icon mr-3 my-auto">
              {ticket.passengerName[0]}
              {ticket.passengerSurname[0]}
            </span>
            <span className="d-flex flex-wrap align-content-center">
              {`${ticket.passengerName} ${ticket.passengerSurname}`}
            </span>
          </div>
        </div>
        <div className="col-3 my-auto text-center">{`${ticket.fromCityAbbr} - ${ticket.toCityAbbr}`}</div>
        <div className={`${isSelectable ? 'col-3' : 'col-4'} my-auto text-center`}>
          {ticket.date}
        </div>

        <div className="col-2 my-auto text-center">{ticket.seatNu}</div>
        <div className="col-2 my-auto text-center">{`${ticket.netPrice}€`}</div>
        <div className={`${isSelectable ? 'col-2' : 'col-3'} my-auto text-center`}>{ticket.id}</div>
        <div className={`${isSelectable ? 'col-3' : 'col-4'} my-auto text-center`}>
          {ticket.status === 'ONGOING' && <div className="badge badge-warning ">Geplant</div>}
          {ticket.status === 'CANCELLED' && <div className="badge badge-danger ">Storniert</div>}
          {ticket.status === 'COMPLETED' && (
            <div className="badge badge-primary ">Abgeschlossen</div>
          )}
        </div>

        <div className="col-2 my-auto text-center">
          <button
            type="button"
            className="btn btn-link p-0"
            onClick={() => setIsQrCodeDisplayed(true)}>
            <img src={qrCodeDataUrl} className="img-fluid w-50 " alt="qr" />
            <span className="text-primary w-100 font-14 mt-3">Siehe</span>
          </button>
          <Backdrop isActivated={isQrCodeDisplayed} onDismiss={() => setIsQrCodeDisplayed(false)} />
          <Modal customClass="qr-modal" isModalActivated={isQrCodeDisplayed}>
            <div className="modal-header ">
              Ticketnummer: <span className="text-primary font-weight-bold">{ticket.id}</span>
            </div>
            <div className="modal-body text-center">
              <div>
                <img src={qrCodeDataUrl} className="qr img-fluid " alt="qr" />
              </div>
              <div className="mb-3 mt-6 text-dark">
                {ticket.fromBusStopName} - {ticket.toBusStopName}
              </div>

              <div className="my-3 text-dark font-weight-bold">
                {ticket.passengerName} {ticket.passengerSurname} / {ticket.seatNu}
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary" onClick={() => setIsQrCodeDisplayed(false)}>
                OK
              </button>
            </div>
          </Modal>
        </div>
      </div>
      {index < ticketCount - 1 && <hr className="ticket-cancellation-border mx-5 my-0" />}
    </>
  );
};

export default TCCItemDesktop;
