import { createContext } from 'react';

const SearchContext = createContext({
  isContextStarted: false,
  searchData: null,
  setSearchData: () => {},
  setContextStarted: () => {}
});

export default SearchContext;
