import {
  useEffect,
  useReducer,
  useRef
  // useState
} from 'react';

import { actionTypes } from '../utils';

/**
 * reducer function definition which is responsible for Form Input state updates
 * this function will update each respective state depending on dispatched action type.
 * All action types are kept in "actionTypes" object variable defined in "constants.js" file.
 * Note that state update is performed dynamically based on the dispatched properties
 * either inside "inputChangedHandler" function or "useEffect" hook.
 */
const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes[action.type]:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          isValid: !!action?.isValid,
          isTouched: action.touched,
          value: action.value
        }
      };

    default:
      return state;
  }
};

const useForm = (initialInputsState, apiDataArguments = null) => {
  const [formInputsState, dispatch] = useReducer(reducer, initialInputsState);
  const inputRef = useRef(); // ref object to keep track of currently updated form input
  // const [selectedDate, setSelectedDate] = useState(null);

  /**
   * this use effect hook is used to update only payer form fields in pasenger details page
   * when a visitor comes back to this page from the next page, which is Overview page.
   * otherwise, while landing this page from the previous page, which is Seat Selection page,
   * this useEffect hook will have no functionality, since "apiDataArguments.payerInfo.name" will be
   * an empty string in this case and the logic inside the "if" statement will not be triggered
   */
  useEffect(() => {
    if (apiDataArguments?.payerInfo?.name) {
      const payerDispatchActionTypes = [
        actionTypes.SET_PAYER_FIRSTNAME,
        actionTypes.SET_PAYER_LASTNAME,
        actionTypes.SET_PAYER_EMAIL,
        actionTypes.SET_PAYER_PHONE
      ];

      const payerDispatchIds = ['payer_firstName', 'payer_lastName', 'payer_email', 'payer_phone'];
      const payerKeys = ['name', 'surname', 'email', 'phone'];

      payerDispatchActionTypes.forEach((type, idx) => {
        dispatch({
          type,
          name: payerDispatchIds[idx],
          isValid: true,
          touched: true,
          value: apiDataArguments.payerInfo[payerKeys[idx]]
        });
      });
    }
  }, [
    apiDataArguments?.payerInfo?.name,
    apiDataArguments?.payerInfo?.surname,
    apiDataArguments?.payerInfo?.email,
    apiDataArguments?.payerInfo?.phone
  ]);

  /**
   * this use effect hook is used to update only guest passengers' form fields in pasenger details page
   * when a visitor comes back to this page from the next page, which is Overview page.
   * otherwise, while landing this page from the previous page, which is Seat Selection page,
   * this useEffect hook will have no functionality, since "apiDataArguments.numOfPassengers" will be
   * undefined in this case and the logic inside the "if" statement will not be triggered
   */
  useEffect(() => {
    if (apiDataArguments?.numOfPassengers) {
      const indexArray = Array.from(
        { length: apiDataArguments.numOfPassengers },
        (_, idx) => idx + 1
      );

      const passengersDispatchIds = [];
      const passengersNameIds = [];
      const identifiers = ['firstName', 'lastName', 'email'];
      const passengersKeys = ['name', 'surname', 'email'];
      indexArray.forEach((num) => {
        identifiers.forEach((el) => {
          passengersDispatchIds.push(`SET_PASSENGER${num}_${el.toUpperCase()}`);
          passengersNameIds.push(`passenger${num}_${el}`);
        });
      });

      apiDataArguments.passengersInfo.forEach((passenger, idx) => {
        [1, 2, 3].forEach((item) => {
          dispatch({
            type: passengersDispatchIds[item - 1 + 3 * idx],
            name: passengersNameIds[item - 1 + 3 * idx],
            isValid: true,
            touched: true,
            value: passenger[passengersKeys[item - 1]]
          });
        });
      });
    }
  }, [apiDataArguments?.numOfPassengers, apiDataArguments?.passengersInfo]);

  /**
   * this handler function is responsible for updating the form input field, whose value is being changed currently.
   * IMPORTANT NOTE: this handler function is NOT applicable to any date input field, since date input field handling
   * is performed by "dateInputChangedHandler" handler function, whose definiton is below "inputChangedHandler".
   */
  const inputChangedHandler = (...handlerVars) => {
    /**
     * if phone input is being updated, it is observed that handlerVars.length = 4
     * and handlerVars[2] is the "event" object that we are looking for.
     * Remember that phone input is provided by a 3rd party package and its event handling
     * is different than usual pure text input field. Whenever handling phone input value,
     * its handler function does not only have event object, but has 3 additional parameters as well.
     * in order for proper event (input value) handling, event object, which will be "handlerVars[2]"
     * should be extracted first.
     */
    const { target } = handlerVars.length > 1 ? handlerVars[2] : handlerVars[0];

    // whenever a country flag is chosen from phone input dropdown menu at the left, target object is not dispatched.
    // in that case, since there is no input value update occurs, this handler function should immediately be returned.
    // otherwise, some properties like "name" and "value" are tried to be accessed on an object whose value is null!
    if (!target) return;

    inputRef.current = target; // before updating, assign the respective DOM Input element to "ref" object
    const { name, value } = target; // destructure "target" object

    const type = `SET_${name?.toUpperCase()}`; // dynamically set action type. (Remember, all action types are defined in "actionTypes" object variable)

    dispatch({
      // dispatch the required action for synchronous update of the form input field
      type,
      name,
      value,
      isValid: true,
      touched: true
    });
  };

  // const dateInputChangedHandler = (...dateInputArgs) => {
  //   /**
  //    * in order to dispatch a proper action for the reducer in order to update date input state,
  //    * we first need to access the modified date input field in the DOM. To do this, we can extract
  //    * "target" object from "dateInputArgs[1]" and then by proper traversing of the DOM tree, we can
  //    * access the date input field being currently updated (changed). The reason why we need to access
  //    * the input element is that we need "name", "type" and "value" properties of the input element
  //    * which should be fed into "dispatch" action as properties of the argument object.
  //    */

  //   // first, get "target" object
  //   const { target } = dateInputArgs[1];

  //   // second, traverse DOM tree to find DOM element whose class is "date-input-wrapper".
  //   // this is the <div> element which wraps around <ReactDatePicker /> component in Input.jsx file.
  //   const dateInputWrapperElement = target.closest('.date-input-wrapper');

  //   // third, obtain the second children of "dateInputWrapperElement" which automatically wraps around
  //   // date input field provided by ReactDatePicker
  //   const datePickerContainerElement = dateInputWrapperElement.children[1];

  //   // fourth, extract date input element by reaching the 1st children of "datePickerContainerElement"
  //   // and then by reaching the 1st children of the 1st children of "datePickerContainerElement"
  //   const dateInputElement = datePickerContainerElement.firstElementChild.firstElementChild;

  //   setSelectedDate(dateInputArgs[0]); // this state update is required so that date input is updated with the selected date in UI

  //   inputRef.current = dateInputElement; // before updating, assign the respective DOM Input element to "ref" object
  //   const { name, value } = dateInputElement; // destructure "dateInputElement" element object
  //   const type = `SET_${name.toUpperCase()}`; // dynamically set action type. (Remember, all action types are defined in "actionTypes" object variable)

  //   dispatch({
  //     // dispatch the required action for synchronous update of the form input field
  //     type,
  //     name,
  //     value,
  //     touched: true
  //   });
  // };

  /**
   * this useEffect hook is responsible for basic checking of input validity, i.e.,
   * after updating an input field via "inputChangedHandler" function given above,
   * its value is checked for whether its value is empty (or null) or not.
   */
  useEffect(() => {
    const checkInputValidityHandler = async () => {
      if (inputRef.current) {
        // unless "ref" object is assigned to a DOM Input element, do not run this effect!
        const type = `CHECK_${inputRef.current.name.toUpperCase()}_VALIDITY`; // dynamically set action type.

        let validatedInputType = 'text';
        if (inputRef.current.name.includes('email')) {
          validatedInputType = 'email';
        } else if (inputRef.current.name.includes('phone')) {
          validatedInputType = 'phone';
        }

        /**
         * this condition is necessary to deal with some problems for the internal logic of phone input element
         * which is provided by a 3rd party package. If currently updated input is phone input element, then
         * dispatching action should be occurred only if the length of the phone input value is greater than 1,
         * otherwise, it will cause infinite re-rendering loop and the application breaks. If an input field
         * different than phone input field is being updated, dispatching action logic is preserved as it should be.
         */
        const isPhoneInputElementBeingUpdated =
          inputRef.current.name.includes('phone') &&
          inputRef.current.value.startsWith('+') &&
          inputRef.current.value.length > 1;
        if (isPhoneInputElementBeingUpdated || !inputRef.current.name.includes('phone')) {
          const { checkInputValidity } = await import('../utils');
          dispatch({
            // dispatch the required action for updating "isValid" property of the currently changed input field
            type,
            isValid: checkInputValidity(inputRef.current.value, validatedInputType),
            name: inputRef.current.name,
            value: inputRef.current.value,
            touched: true
          });
        }
      }
    };

    checkInputValidityHandler();
  }, [inputRef.current?.value]);

  const currentInputRef = inputRef.current;

  return [
    formInputsState,
    inputChangedHandler,
    dispatch,
    currentInputRef
    // dateInputChangedHandler,
    // selectedDate
  ];
};

export default useForm;
