import React, { useState } from 'react';

import SearchContext from '../context/SearchContext';

/**
 * this store provider component is used to pass the context value from "Search" context
 
 */
const SearchDataProvider = (props) => {
  const [searchData, setSearchData] = useState({});
  const [isContextStarted, setContextStarted] = useState(false);
  const value = { searchData, setSearchData, isContextStarted, setContextStarted };

  return <SearchContext.Provider value={value}>{props.children}</SearchContext.Provider>;
};

export default SearchDataProvider;
