import { useState } from 'react';

import { scrollTo } from '../pages/TicketCancellationWithoutLogin';
import { API_METHODS } from '../utils';

/**
 * this hook is responsible for ....
 */
const useTicketCancellationSearch = ({ ticketBody, bookingBody }) => {
  const [tableData, setTableData] = useState([]);
  const [ticketCancellationCardType, setTicketCancellationCardType] = useState('booking-id');
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [email, setEmail] = useState('');
  const [isError, setIsError] = useState(false);
  const [payerName, setPayerName] = useState('');
  const [processId, setProcessId] = useState('');
  const [ticketErrorMessage, setTicketErrorMessage] = useState('');
  const [bookingErrorMessage, setBookingErrorMessage] = useState('');

  // This handler function is responsible for search by booking or ticket ID
  const searchHandler = async (type = 'ticket') => {
    setIsLoading(true);
    setIsError(false);
    setBookingErrorMessage('');
    setTicketErrorMessage('');

    let body = ticketBody;

    if (type === 'booking') {
      body = bookingBody;
    }

    const { API } = await import('aws-amplify');

    try {
      const apiData = await API[API_METHODS.POST](
        'RoadJetAPI',
        `${type == 'ticket' ? '/tickets/request/cancellation' : '/bookings/request/cancellation'}`,
        { headers: {}, body: body }
      );
      if (type === 'ticket') {
        setTableData(apiData);
        setTicketCancellationCardType('ticket-id');
        setProcessId(apiData[0].processId);

        scrollTo({ id: 'ticket-cancellation-card-section', duration: 400 });
      } else {
        setEmail(apiData.email);
        setIsEmailSent(true);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      type === 'ticket'
        ? setTicketErrorMessage(error?.response?.data?.err)
        : setBookingErrorMessage(error?.response?.data?.err);
    }
  };

  const verificationHandler = async (verificationCode) => {
    setIsLoading(true);
    setIsError(false);
    setBookingErrorMessage('');
    setPayerName('');

    let body = {
      bookingId: bookingBody.bookingId,
      verificationCode
    };

    // console.log(body);

    const { API } = await import('aws-amplify');

    try {
      const apiData = await API[API_METHODS.POST](
        'RoadJetAPI',
        `${'/bookings/request/cancellation/verify'}`,
        { headers: {}, body: body }
      );

      setTableData(apiData.tickets);
      setTicketCancellationCardType('booking-id');
      setPayerName(`${apiData.payerName} ${apiData.payerSurname}`);
      setProcessId(apiData.processId);
      // console.log(apiData.processId);
      scrollTo({ id: 'ticket-cancellation-card-section', duration: 400 });
      setIsEmailSent(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      setBookingErrorMessage(error?.response?.data?.err);
    }
  };

  return [
    tableData,
    isError,
    isLoading,
    email,
    isEmailSent,
    ticketCancellationCardType,
    payerName,
    processId,
    ticketErrorMessage,
    bookingErrorMessage,
    searchHandler,
    verificationHandler
  ];
};

export default useTicketCancellationSearch;
