import { useState, useEffect } from 'react';

import { Button, Input, ReCaptcha } from '.';

const CancellationByTicketIdTab = ({
  cancellationByTicketIdInputs,
  cancellationByTicketIdFormInputsState,
  errorMessage,
  isError,
  isLoading,
  onChange,
  onSubmit
}) => {
  const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);

  useEffect(() => {
    if (isError) {
      setIsCaptchaChecked(false);
    }
  }, [isError]);

  return isLoading ? (
    <div className="loading-cancellation-by-ticket-id d-flex">
      <div className=" spinner-border text-primary m-auto" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  ) : (
    <div className="row">
      {cancellationByTicketIdInputs.map((field) => (
        <div key={field.id} className="col-24 col-md-12 mb-4">
          <Input
            htmlFor={field.id}
            id={field.id}
            //isInputError={!cancellationByTicketIdFormInputsState[field.id].isValid}
            label={field.label}
            name={field.id}
            onChange={onChange}
            placeholder={field.placeholder}
            type={field.type}
            value={cancellationByTicketIdFormInputsState[field.id].value}
          />
        </div>
      ))}
      <div className="col-24 mb-4">
        <ReCaptcha onChecked={() => setIsCaptchaChecked(true)} />
      </div>

      <div className="col-24 d-flex align-items-center mb-3">
        <Button
          borderRadiusSize="-sm"
          isRelativelyPositioned
          onClick={onSubmit}
          isDisabled={!isCaptchaChecked}
          type="primary">
          Suchen
        </Button>
      </div>
      {errorMessage && <div className="text-danger  ml-3">{errorMessage}</div>}
    </div>
  );
};

export default CancellationByTicketIdTab;
