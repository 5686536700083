import React from 'react';

import PropTypes from 'prop-types';

import './Loading.scss';

import { Backdrop } from '.';

const Loading = ({ animationName, loadingText }) => (
  <>
    <Backdrop isActivated isLoadingBackdrop />
    <div className="loading-wrapper d-flex flex-column justify-content-center align-items-center w-100 h-100 position-fixed bg-transparent">
      <div className={`animation animate-${animationName} mb-5`} />
      <p className="d-flex justify-content-center align-items-center text-primary font-weight-medium font-15 w-100">
        {loadingText.split('').map((letter, index) => (
          <span
            style={{ animationDelay: `${index / 10}s` }}
            key={Math.random()}
            className={`animate-letter-${index} ml-${index === 4 ? '3' : '2'}`}>
            {letter}
          </span>
        ))}
      </p>
    </div>
  </>
);

Loading.propTypes = {
  animationName: PropTypes.string,
  loadingText: PropTypes.string
};

Loading.defaultProps = {
  animationName: null,
  loadingText: 'WIRDGELADEN'
};

export default Loading;
