export const ticketCancellationWithoutLoginTabs = [
  {
    id: 'booking-id',
    name: 'Buchungsnummer'
  },
  {
    id: 'ticket-id',
    name: 'Ticketnummer'
  }
];

export const ticketCancellationTableCaptions = [
  { title: 'Name', colWidth: 4, colWidthOnSelect: 4 },
  { title: 'Abf.-Ank.', colWidth: 3, colWidthOnSelect: 3 },
  { title: 'Fahrtdatum', colWidth: 4, colWidthOnSelect: 3 },
  { title: 'Sitz', colWidth: 2, colWidthOnSelect: 2 },
  { title: 'Preis', colWidth: 2, colWidthOnSelect: 2 },
  { title: 'Ticket Nu.', colWidth: 3, colWidthOnSelect: 2 },
  { title: 'Status', colWidth: 4, colWidthOnSelect: 3 },
  { title: 'QR-Code', colWidth: 2, colWidthOnSelect: 2 }
];

export const cancellationByTicketIdInputs = [
  {
    id: 'ticket_id',
    label: 'Ticketnummer*',
    placeholder: 'Ticketnummer',
    type: 'text'
  },

  {
    id: 'cancellation_surname',
    label: 'Nachname*',
    placeholder: 'Nachname',
    type: 'text'
  }
];

export const cancellationByTicketIdFormInitialState = {
  ticket_id: {
    isValid: false,
    isTouched: false,
    value: '',
    isRequired: true
  },

  cancellation_surname: {
    isValid: false,
    isTouched: false,
    value: '',
    isRequired: true
  }
};

export const cancellationByBookingIdInputs = [
  {
    id: 'booking_id',
    label: 'Buchungsnummer*',
    placeholder: 'Buchungsnummer',
    type: 'text'
  }
];

export const cancellationByBookingIdFormInitialState = {
  booking_id: {
    isValid: false,
    isTouched: false,
    value: '',
    isRequired: true
  }
};

export const captions = [
  'Vorname',
  'Nachname',
  'Abfahrt',
  'Ankunft',
  'Fahrdatum',
  'Sitznummer',
  'Preis',
  'Erstellungsdatum',
  ''
];

export const fields = [
  'passengerName',
  'passengerSurname',
  'departure',
  'arrival',
  'date',
  'seatNu',
  'netPrice',
  'createdAt'
];

// this variable holds FAQ card text contents for which text color is uniform throughout the whole text
export const faqCardContent = [
  {
    id: '1',
    beforeTitle: 'Stornierungen',
    title:
      '1. Ich möchte meine Fahrt nicht antreten. Bis zu welchem Zeitpunkt kann ich meine Buchung stornieren?',
    titleLineCountOnDesktop: 2,
    titleLineCountOnMobile: 4,
    content: ['a. Eine Stornierung der gebuchten Tickets ist bei uns immer möglich.']
  },

  {
    id: '2',
    title: '2. Wird mir für die Stornierung eine Gebühr berechnet?',
    titleLineCountOnDesktop: 1,
    titleLineCountOnMobile: 2,
    content: [
      'a. Eine Stornierung ist bis zu 48h vor Fahrtantritt kostenlos möglich, danach behalten wir uns es vor den halben Ticketpreis zu berechnen.'
    ]
  },

  {
    id: '3',

    title: '3. Wo kann ich meine Buchung stornieren oder umbuchen?',
    titleLineCountOnDesktop: 1,
    titleLineCountOnMobile: 2,
    content: [
      'a. Auf unserer Website, in unserer RoadJet App und natürlich mit deinem persönlichen Kundenkonto'
    ]
  },

  {
    id: '4',
    title: '4. Was benötige ich um meine Buchung zu stornieren?',
    titleLineCountOnDesktop: 1,
    titleLineCountOnMobile: 2,
    content: [
      'a. Die Fahrt kann entweder mit dem Buchungscode oder der Ticketnummer storniert werden.'
    ]
  }
];

export const initialState = {
  1: false,
  2: false,
  3: false,
  4: false,
  5: false
};

export const cancellationReasons = [
  'Mein Reiseziel hat sich geändert.',
  'Ich möchte zu einem anderen Zeitpunkt reisen.',
  'Ich habe das falsche Ticket gekauft.',
  'Andere Gründe'
];

export const metaInfo = {
  title: 'Ticketstornierung',
  description:
    'RoadJet ist Europas erster premium Fernbusanbieter. Wir haben es uns zur Aufgabe gemacht `First-class Busreisen zu Economy Preisen` anzubieten. Dabei versprechen wir ein hoch komfortables, effizientes, preiswertes und ökologisches Reiseerlebnis zugleich.',
  cardImageSrc: 'https://www.roadjet.de/roadjet-landing-night.jpg',
  pagePath: 'https://www.roadjet.de/ticketstornierung'
};
