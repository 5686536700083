import React from 'react';

import { createPortal } from 'react-dom';
import LazyLoad from 'react-lazyload';

import './Footer.scss';

import { useWindowDimensions } from '../../../hooks';

import {
  footerAboutSection,
  footerContactPhoneAndEmailFields,
  footerLinksSection,
  footerPaymentIcons,
  footerSocialMediaSection,
  RoadJetLogoWhiteSvg
} from './util';

import {
  Button,
  AppDownloadButtonsGroup,

  //  HubspotForm,
  Icon,
  Image
} from '.';

const Footer = () => {
  const { width } = useWindowDimensions();

  const footerNode = document.querySelector('#footer');
  const footerContent = (
    <LazyLoad height={200} offset={100}>
      <footer className="fixed-bottom position-relative">
        <div className="bg-ternary footer-up py-4">
          <div className="container-fluid py-md-4">
            <div className="row">
              <div className="col-md-6 pb-3">
                <Image
                  figureClass={`footer-logo mx-auto mx-md-0 mb-3`}
                  imgAltText={RoadJetLogoWhiteSvg.altText}
                  imgFallbackSrc={RoadJetLogoWhiteSvg.src}
                  isRelativelyPositioned
                  isSvg
                />
                <div className={`footer-title-lg text-center text-md-left mb-3`}>
                  {/* RoadJet Charter <br />
                  Service GmbH <hr className="border-white" /> */}
                  <p className="text-white">Premium Fernbusanbieter</p>
                </div>
                <p className={`font-14 text-center text-md-left text-white`}>
                  Wir sind Europas erster premium Fernbusanbieter. Wir haben es uns zur Aufgabe
                  gemacht `First-class Reisen zu Economy Preisen` anzubieten
                </p>
              </div>
              <div className="col-12 col-md-4  offset-lg-2 pb-3">
                <div className="footer-title mb-3">Über Uns</div>
                {footerAboutSection.map((item) => (
                  <div key={item.name} className="footer-item">
                    <Button caption={item.name} to={item.to} isRelativelyPositioned />
                  </div>
                ))}
              </div>
              <div className="col-12 col-md-4  pb-3">
                <div className="footer-title mb-3">Links</div>
                {footerLinksSection.map((item) => (
                  <div key={item.name} className="footer-item">
                    <Button caption={item.name} to={item.to} isRelativelyPositioned />
                  </div>
                ))}
              </div>
              <div className="col-12 col-md-5 col-lg-4 pb-3">
                <div className="footer-title mb-3">Kontaktiere Uns</div>
                {footerContactPhoneAndEmailFields.map((item) => (
                  <div key={item.id} className="footer-item mb-3">
                    <Button
                      className="d-flex"
                      href={item.href}
                      isMailLink={item.isMailLink}
                      isPhoneLink={item.isPhoneLink}>
                      <Icon
                        fontAwesomeIconClass={`fas fa-${item.icon}`}
                        color="white"
                        iconBaseClass={`mr-2 mt-1`}
                      />

                      <span className="pl-5"> {item.isMapLink ? item.address : item.href}</span>
                    </Button>
                  </div>
                ))}
              </div>
              <div className="col-12 col-md-5 col-lg-4 pb-3 footer-download-section">
                <div className="footer-title mb-4">Download</div>
                <AppDownloadButtonsGroup stackAlignment="vertical" />
                <div className="d-flex flex-column follow-us-icons">
                  {footerSocialMediaSection.map((item) => (
                    <Button key={item.id} isRelativelyPositioned customClass="px-0" href={item.to}>
                      <Icon
                        iconBaseClass="py-3 px-1 text-center"
                        fontAwesomeIconClass={item.icon}
                        color="white"
                        isRelativelyPositioned
                      />
                    </Button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-down bg-dark-ternary py-5">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-md-8 mb-4 mb-md-0">
                <div className="payment-icons d-flex align-items-center justify-content-center justify-content-md-start mx-md-0">
                  {footerPaymentIcons.map((payment, index) => (
                    <Icon
                      key={payment.icon}
                      color=""
                      fontAwesomeIconClass={payment.icon}
                      fontAwesomeIconSize={width < 920 ? '20' : `24`}
                      iconBaseClass={`payment-icon ${index !== 0 ? 'ml-5' : ''}`}
                      isRelativelyPositioned
                    />
                  ))}
                </div>
              </div>
              <div className="col-md-16 my-auto">
                <div className="copyright-text text-center text-md-left ">
                  © 2023 All Rights Reserved
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </LazyLoad>
  );

  return createPortal(footerContent, footerNode);
};

export default Footer;
