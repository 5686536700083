import { configureStore } from '@reduxjs/toolkit';

import charterPopUpReducer from '../components/Landing/LandingCharterPopUp/charterPopUpSlice';

export default configureStore({
  reducer: {
    charterPopUp: charterPopUpReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
});
