import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { useWindowDimensions } from '../../hooks';

let offsetTop = 0;

const ScrollHandler = ({ location }) => {
  const { width } = useWindowDimensions();
  //console.log('location: ', location);

  useEffect(() => {
    if (location.pathname.includes('covid')) {
      if (location.hash === '#socialDistance') {
        offsetTop = 1020;
      } else if (location.hash === '#uvLightTechnology') {
        offsetTop = 1420;
      } else if (location.hash === '#faceMask') {
        offsetTop = 1800;
      } else if (location.hash === '#disinfectant') {
        offsetTop = 2200;
      } else if (location.hash === '#extraWashroom') {
        offsetTop = 2600;
      } else if (location.hash === '#temperatureControl') {
        offsetTop = 2980;
      } else if (location.hash === '#ozonePro') {
        offsetTop = 3300;
      } else if (location.hash === '#maskControl') {
        offsetTop = 3720;
      }
    }

    if (location.hash === '') {
      offsetTop = 0;
    }

    if (location.pathname.includes('features')) {
      if (width > 320 && width < 760) {
        // scrolling to section position should be implemented for medium and larger screen sizes
        if (location.hash === '#wifi') {
          offsetTop = 100;
        } else if (location.hash === '#massageSeat') {
          offsetTop = 180;
        } else if (location.hash === '#handWash') {
          offsetTop = 460;
        } else if (location.hash === '#snacks') {
          offsetTop = 740;
        } else if (location.hash === '#safety') {
          offsetTop = 1040;
        } else if (location.hash === '#ecology') {
          offsetTop = 1280;
        }
      }

      if (width > 760 && width < 1023) {
        // scrolling to section position should be implemented for medium and larger screen sizes
        if (location.hash === '#wifi') {
          offsetTop = 320;
        } else if (location.hash === '#massageSeat') {
          offsetTop = 760;
        } else if (location.hash === '#handWash') {
          offsetTop = 1240;
        } else if (location.hash === '#snacks') {
          offsetTop = 1540;
        } else if (location.hash === '#safety') {
          offsetTop = 1820;
        } else if (location.hash === '#ecology') {
          offsetTop = 2180;
        }
      }

      if (width > 1023 && width < 1199) {
        // scrolling to section position should be implemented for medium and larger screen sizes
        if (location.hash === '#wifi') {
          offsetTop = 560;
        } else if (location.hash === '#massageSeat') {
          offsetTop = 940;
        } else if (location.hash === '#handWash') {
          offsetTop = 1460;
        } else if (location.hash === '#snacks') {
          offsetTop = 1800;
        } else if (location.hash === '#safety') {
          offsetTop = 2100;
        } else if (location.hash === '#ecology') {
          offsetTop = 2480;
        }
      }

      if (width > 1199) {
        // scrolling to section position should be implemented for medium and larger screen sizes
        if (location.hash === '#wifi') {
          offsetTop = 540;
        } else if (location.hash === '#massageSeat') {
          offsetTop = 940;
        } else if (location.hash === '#handWash') {
          offsetTop = 1380;
        } else if (location.hash === '#snacks') {
          offsetTop = 1760;
        } else if (location.hash === '#safety') {
          offsetTop = 2120;
        } else if (location.hash === '#ecology') {
          offsetTop = 2620;
        }
      }
    }

    const timer = setTimeout(() => {
      window.scrollTo({
        behavior: 'smooth',
        top: offsetTop
      });
    }, 10);

    return () => clearTimeout(timer);
  }, [location, width]);

  return null;
};

ScrollHandler.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string
  }).isRequired
};

export default withRouter(ScrollHandler);
