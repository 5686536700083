import Amplify from 'aws-amplify';

const getAmplifyConfig = () => {
  if (process.env.REACT_APP_ENV === 'prod') {
    Amplify.configure({
      // OPTIONAL - if your API requires authentication
      // OPTIONAL - if your API requires authentication
      Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: 'eu-central-1:d8a5a7ad-0ffb-4f99-8633-9d500839e22a',
        // REQUIRED - Amazon Cognito Region
        region: 'eu-central-1',
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'eu-central-1_oGjfFhbDX',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '3msvjr3tuj0v4rkrdl0rvojeqn'
      },
      API: {
        endpoints: [
          {
            name: 'RoadJetAPI',
            endpoint: 'https://mps3r5i0nh.execute-api.eu-central-1.amazonaws.com/prod',
            region: 'eu-central-1'
          }
        ]
      }
    });
  } else if (process.env.REACT_APP_ENV === 'local') {
    Amplify.configure({
      // OPTIONAL - if your API requires authentication
      // OPTIONAL - if your API requires authentication
      Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: 'eu-central-1:8ad60715-4949-49c2-89b6-3208b2a45025',
        // REQUIRED - Amazon Cognito Region
        region: 'eu-central-1',
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'eu-central-1_OxhhaCqQy',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '7vnb0l50h6sstml5nj5c052n7e'
      },
      API: {
        endpoints: [
          {
            name: 'RoadJetAPI',
            endpoint: 'http://localhost:4000',
            region: 'eu-central-1'
          }
        ]
      }
    });
  } else {
    Amplify.configure({
      // OPTIONAL - if your API requires authentication
      // OPTIONAL - if your API requires authentication
      Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: 'eu-central-1:8ad60715-4949-49c2-89b6-3208b2a45025',
        // REQUIRED - Amazon Cognito Region
        region: 'eu-central-1',
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'eu-central-1_OxhhaCqQy',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '7vnb0l50h6sstml5nj5c052n7e'
      },
      API: {
        endpoints: [
          {
            name: 'RoadJetAPI',
            endpoint: 'https://lg825w5qva.execute-api.eu-central-1.amazonaws.com/dev',
            region: 'eu-central-1'
          }
        ]
      }
    });
  }
};

export default getAmplifyConfig;
