import './FaqComponent.scss';

import { Card, Icon } from '.';

const FaqComponent = ({ faqCardContent, expandCardContent, toggleCardContentDisplayHandler }) => {
  return (
    <div className="container px-lg-8 px-xl-4 mt-12">
      <div className="row">
        <div className="col-24 col-lg-16 col-xl-17 mx-auto">
          {faqCardContent.map((faqCard, index) => (
            <div key={faqCard.title}>
              {faqCard?.beforeTitle && (
                <h2 className="faq-before-title pl-1  mx-auto mb-5">{faqCard?.beforeTitle}</h2>
              )}
              <Card
                key={faqCard.title}
                id={faqCard.id}
                cardClass={`faq-card px-4 pb-3 pt-4 mx-auto mb-5 ${
                  expandCardContent[index + 1]
                    ? 'expanded-faq'
                    : `shrinked-faq-mobile-${faqCard.titleLineCountOnMobile} shrinked-faq-desktop-${faqCard.titleLineCountOnDesktop}`
                }`}
                radiusSize="-sm">
                <div
                  className="d-flex justify-content-between align-items-center"
                  role="button"
                  tabIndex="-1"
                  aria-label="Expand/Shrink FAQ Card"
                  onClick={toggleCardContentDisplayHandler}>
                  <h2
                    className={`faq-card-title font-16  mb-0 mr-1 text-${
                      expandCardContent[index + 1] ? 'primary' : 'secondary'
                    }`}>
                    {faqCard.title}
                  </h2>
                  <Icon
                    color={`${expandCardContent[index + 1] ? 'primary' : 'secondary'}`}
                    fontAwesomeIconClass={`fas fa-chevron-${
                      expandCardContent[index + 1] ? 'up' : 'down'
                    }`}
                    fontAwesomeIconSize="16"
                    id={faqCard.title}
                    isRelativelyPositioned
                  />
                </div>

                <div
                  className={`faq-card-text ${
                    expandCardContent[index + 1] ? 'visible' : 'invisible'
                  }`}>
                  {faqCard.content.map((paragraph) => (
                    <p key={paragraph} className="text-dark pt-2">
                      {paragraph}
                    </p>
                  ))}
                </div>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FaqComponent;
