import RoadJetLogoWhiteSvgImage from '../../../static/assets/images/svg/logo/roadjet-logo-white.svg';

export const RoadJetLogoWhiteSvg = {
  src: RoadJetLogoWhiteSvgImage,
  altText: 'RoadJet Logo'
};

export const footerAboutSection = [
  {
    name: 'Wer sind wir?',
    to: '/uber-uns'
  },

  {
    name: 'Jobs',
    to: '/jobs'
  },

  {
    name: 'Newsroom',
    to: '/newsroom'
  },

  {
    name: 'Unser Team',
    to: '/uber-uns'
  },

  {
    name: 'Kontakt',
    to: '/kontakt'
  },

  {
    name: 'Impressum',
    to: '/impressum'
  }
];

export const footerLinksSection = [
  {
    name: 'RoadJet Corporate',
    to: '/corporate'
  },

  {
    name: 'Datenschutz',
    to: '/datenschutz'
  },

  {
    name: 'AGB',
    to: '/agb'
  },

  {
    name: 'Features',
    to: '/features'
  },

  {
    name: 'FAQ',
    to: '/faq'
  },

  {
    name: 'Magazin',
    to: '/magazin'
  },

  {
    name: 'Corona Maßnahmen',
    to: '/covid19-hygienekonzept'
  },
  {
    name: 'Ticketstornierung',
    to: '/ticketstornierung'
  }
];

export const footerSocialMediaSection = [
  {
    icon: 'fab fa-facebook-f',
    id: 'Facebook',
    to: 'https://www.facebook.com/thisisroadjet/'
  },

  {
    icon: 'fab fa-twitter',
    id: 'Twitter',
    to: 'https://twitter.com/itsroadjet'
  },

  {
    icon: 'fab fa-instagram',
    id: 'Instagram',
    to: 'https://www.instagram.com/itsroadjet/?hl=de'
  },

  {
    icon: 'fab fa-linkedin-in',
    id: 'Linkedin',
    to: 'https://www.linkedin.com/company/roadjet'
  }
];

export const footerContactPhoneAndEmailFields = [
  {
    id: 'address',
    icon: 'map-marker',
    href: 'https://www.google.com/maps/dir//RoadJet+GmbH,+Rainwiesen+15,+71686+Remseck+am+Neckar/@48.8899847,9.2637181,17z/data=!4m17!1m7!3m6!1s0x4799cfb633bd36f5:0x7c26d7a17a462cda!2sRoadJet+GmbH!8m2!3d48.8899812!4d9.266293!16s%2Fg%2F11pzp0wf6s!4m8!1m0!1m5!1m1!1s0x4799cfb633bd36f5:0x7c26d7a17a462cda!2m2!1d9.2663501!2d48.8900073!3e3?entry=ttu',
    address: (
      <div className="d-inline-block">
        {/* <span className="d-block">RoadJet Charter Service GmbH</span> */}
        <span className="d-block">Rainwiesen 15,</span>
        <span className="d-block">71686 Remseck am Neckar</span>
      </div>
    ),
    isMapLink: true
  },
  {
    id: 'phone',
    icon: 'phone',
    href: '+49 176 15313570',
    isPhoneLink: true
  },

  {
    id: 'email',
    icon: 'envelope',
    href: 'info@roadjet.de',
    isMailLink: true
  }
];

export const footerPaymentIcons = [
  {
    icon: 'fab fa-cc-paypal'
  },

  {
    icon: 'fab fa-cc-visa'
  },

  {
    icon: 'fab fa-cc-mastercard'
  },

  {
    icon: 'fab fa-cc-amex'
  },

  {
    icon: 'fab fa-cc-discover'
  },

  {
    icon: 'fas fa-lock'
  }
];
