import queryString from 'query-string';

const getQueryResult = () => {
  const query = queryString.parse(window.location.search);
  const path = queryString.parse(window.location.href);

  const step = Object.keys(path)[0].includes('hinfahrt') ? 'departure' : 'return';

  return {
    reservationId: query.rId,
    step
  };
};

export default getQueryResult;
