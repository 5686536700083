import './TicketCancellationCard.scss';
import { TCCItemMobile } from '.';

const TCCContentMobile = ({
  allTickets,
  isSelectable,
  selectedTickets,
  setSelectedTickets,
  type
}) => {
  return (
    <div>
      {allTickets.map((ticket, index) => (
        <TCCItemMobile
          key={ticket.id}
          ticket={ticket}
          ticketCount={allTickets.length}
          index={index}
          isSelectable={isSelectable}
          selectedTickets={selectedTickets}
          setSelectedTickets={setSelectedTickets}
          type={type}
        />
      ))}
    </div>
  );
};

export default TCCContentMobile;
