import roadjetBusFallbackImage from '../../../static/assets/images/roadjet-bus/roadjet-landing@6x.jpg';
import roadjetBus4xWebpImage from '../../../static/assets/images/roadjet-bus/webp/roadjet-landing@4x.webp';
import roadjetBus5xWebpImage from '../../../static/assets/images/roadjet-bus/webp/roadjet-landing@5x.webp';
import roadjetBus6xWebpImage from '../../../static/assets/images/roadjet-bus/webp/roadjet-landing@6x.webp';

const errorMessages = [
  // Login
  {
    type: 'UserNotFoundException',
    message: 'User does not exist.',
    german: 'Der Benutzer existiert nicht'
  },
  {
    type: 'NotAuthorizedException',
    message: 'Incorrect username or password.',
    german: 'Falscher Benutzername oder falsches Passwort'
  },
  {
    type: 'UserNotFoundException',
    message: 'Password attempts exceeded',
    german: 'Passwortversuche überschritten'
  },

  // Register
  {
    type: 'InvalidParameterException',
    message:
      "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$",
    german: 'Dein Passwort muss mindestens 8 Zeichen vorweisen können'
  },
  {
    type: 'InvalidParameterException',
    message: 'Invalid email address format.',
    german: 'Ungültiges E-Mail-Adressformat'
  },
  {
    type: 'InvalidPasswordException',
    message: 'Password did not conform with policy: Password not long enough',
    german: 'Das Passwort entsprach nicht den Richtlinien: Passwort nicht lang genug'
  },
  {
    type: 'UsernameExistsException',
    message: 'An account with the given email already exists.',
    german: 'Ein Konto mit der angegebenen E-Mail existiert bereits'
  },
  // Confirm SignUp
  {
    type: 'CodeMismatchException',
    message: 'Invalid verification code provided, please try again.',
    german: 'Der angegebene Verifizierungscode ist ungültig, bitte versuchen Sie es erneut'
  },

  // Forgot Password
  {
    type: 'UserNotFoundException',
    message: 'Username/client id combination not found.',
    german: 'Ein Benutzer mit dieser E-Mail wird nicht gefunden'
  },
  {
    type: 'CodeMismatchException',
    message: 'Invalid verification code provided, please try again.',
    german: 'Der angegebene Verifizierungscode ist ungültig, bitte versuchen Sie es erneut.'
  },
  {
    type: 'InvalidPasswordException',
    message: 'Password does not conform to policy: Password not long enough',
    german: 'Das Passwort entspricht nicht den Richtlinien: Passwort nicht lang genug'
  }
];

export {
  errorMessages,
  roadjetBus4xWebpImage,
  roadjetBus5xWebpImage,
  roadjetBus6xWebpImage,
  roadjetBusFallbackImage
};
