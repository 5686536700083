import { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { getQueryResult } from '../utils';

const useNavigateToPage = (body, apiPath, pathname, method = 'post', apiName = 'RoadJetAPI') => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const query = getQueryResult();

  const navigateToPageHandler = async () => {
    if (body.fromBusStopId === 9999) {
      delete body.fromBusStopId;
    } else {
      delete body.fromCityId;
    }
    if (body.toBusStopId === 9999) {
      delete body.toBusStopId;
    } else {
      delete body.toCityId;
    }

    // console.log(body);

    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      body
    };

    try {
      setIsLoading(true);
      setIsError(false);

      const { API } = await import('aws-amplify');
      API[method](apiName, apiPath, myInit).then((result) => {
        history.push({
          pathname,
          // search: `?rId=${result.id || query.reservationId}`
          search: `?rId=${query.reservationId || result.id}`
        });
      });
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      // console.log(error);
    }
  };

  return [isError, isLoading, navigateToPageHandler];
};

export default useNavigateToPage;
