import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './Button.scss';

/**
 * this component actually serves as the collection of 3 different HTML elements depending on provided props.
 * since anchor tag and normal button elements are both pressable by their nature, they are rendered under one
 * "Button" component.
 */
const Button = ({
  anchorLinkClass,
  borderColor,
  borderRadiusSize,
  caption,
  children,
  color,
  customClass,
  flexDir,
  href,
  id,
  isBordered,
  isButtonLink,
  isDisabled,
  isInlineFlex,
  isMailLink,
  isPhoneLink,
  isPointerButton,
  isRelativelyPositioned,
  isSectionLink,
  mainAxisAlignment,
  markElementClass,
  onClick,
  secondaryAxisAlignment,
  size,
  to,
  type
}) => {
  if (to) {
    // if "to" prop is provided, render "Link" element provided by "react-router-dom"
    return (
      <Link className={`text-decoration-none ${customClass}`} to={to}>
        {children || caption}
      </Link>
    );
  }

  if (href) {
    let anchorElementHrefPrefix = '';
    if (isMailLink) anchorElementHrefPrefix = 'mailto:';
    if (isPhoneLink) anchorElementHrefPrefix = 'tel:';

    // if "href" prop is provided, render native "<a>" HTML element
    if (isButtonLink) {
      // rendered for such buttons for redirection to external links upon which they are clicked like "Mehr" buttons in Newsroom page
      return (
        <a className="text-decoration-none" href={href} target="_blank" rel="noopener noreferrer">
          <button
            className={`btn btn-primary btn-${size} d-flex justify-content-center align-items-center rounded-sm ${customClass}`}
            type="button">
            {children || caption}
          </button>
        </a>
      );
    }

    if (isSectionLink) {
      return (
        <a className={`text-decoration-none ${customClass}`} href={href} id={id}>
          {children || caption}
        </a>
      );
    }

    return (
      <a
        className={`text-decoration-none ${anchorLinkClass}`}
        href={anchorElementHrefPrefix + href}
        id={id}
        target="_blank"
        rel="noopener noreferrer">
        <mark className={`marked-element ${markElementClass}`}>{children || caption}</mark>
      </a>
    );
  }

  if (isPointerButton) {
    return (
      <button
        className={`btn btn-primary pointer-btn h-100 d-flex justify-content-center align-items-center position-${
          isRelativelyPositioned ? 'relative' : 'absolute'
        } text-white`}
        type="button"
        onClick={onClick}>
        {children || caption}
      </button>
    );
  }

  // if neither "href" or "to" prop are provided, render native "button" HTML element
  return (
    <button
      className={`btn btn-${type} btn-${size} d-${
        isInlineFlex ? 'inline-flex' : 'flex'
      } flex-${flexDir} justify-content-${mainAxisAlignment} align-items-${secondaryAxisAlignment} position-${
        isRelativelyPositioned ? 'relative' : 'absolute'
      } rounded${borderRadiusSize} text-${color} ${
        isBordered ? `border-${borderColor}` : ''
      } ${customClass}`}
      id={id}
      disabled={isDisabled}
      type="button"
      onClick={onClick}>
      {children || caption}
    </button>
  );
};

Button.propTypes = {
  anchorLinkClass: PropTypes.string,
  borderColor: PropTypes.string,
  borderRadiusSize: PropTypes.string,
  caption: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  customClass: PropTypes.string,
  flexDir: PropTypes.string,
  href: PropTypes.string,
  id: PropTypes.string,
  isBordered: PropTypes.bool,
  isButtonLink: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isInlineFlex: PropTypes.bool,
  isMailLink: PropTypes.bool,
  isPhoneLink: PropTypes.bool,
  isPointerButton: PropTypes.bool,
  isRelativelyPositioned: PropTypes.bool,
  mainAxisAlignment: PropTypes.string,
  markElementClass: PropTypes.string,
  onClick: PropTypes.func,
  secondaryAxisAlignment: PropTypes.string,
  size: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.string
};

Button.defaultProps = {
  anchorLinkClass: '',
  borderColor: null,
  borderRadiusSize: '',
  caption: null,
  children: null,
  color: 'white',
  customClass: null,
  flexDir: 'row',
  href: '',
  id: null,
  isBordered: false,
  isButtonLink: false,
  isDisabled: false,
  isInlineFlex: false,
  isMailLink: false,
  isPhoneLink: false,
  isPointerButton: false,
  isRelativelyPositioned: false,
  mainAxisAlignment: 'center',
  markElementClass: '',
  onClick: null,
  secondaryAxisAlignment: 'center',
  size: 'default',
  to: '',
  type: 'white'
};

export default Button;
