import QRCode from 'qrcode';

import Image from '../Image';
import Backdrop from '../shared/Backdrop';
import Button from '../shared/Button';
import Icon from '../shared/Icon';
import Input from '../shared/Input';
import Modal from '../shared/Modal';

import TCCContentDesktop from './TCCContentDesktop';
import TCCContentMobile from './TCCContentMobile';
import TCCHeaderDesktop from './TCCHeaderDesktop';
import TCCHeaderMobile from './TCCHeaderMobile';
import TCCItemDesktop from './TCCItemDesktop';
import TCCItemMobile from './TCCItemMobile';
import TicketCancellationCard from './TicketCancellationCard';

export default TicketCancellationCard;
export {
  Backdrop,
  Button,
  Icon,
  Image,
  Input,
  Modal,
  QRCode,
  TCCHeaderDesktop,
  TCCHeaderMobile,
  TCCContentDesktop,
  TCCContentMobile,
  TCCItemDesktop,
  TCCItemMobile
};
