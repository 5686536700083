import { useState, useRef } from 'react';

import { API_METHODS } from '../../utils';

import './TicketCancellationModal.scss';
import { Backdrop, Button, cancellationReasons, Icon, Input, Modal, TCCItemMobile } from '.';

const TicketCancellationModal = ({
  isTicketCancellationModalDisplayed,
  isOnDashboard = false,
  onCancelFinish,
  onDismiss,
  processId,
  ticketsToCancel
}) => {
  const [currentStep, setCurrentStep] = useState(1);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [summary, setSummary] = useState(null);

  const [selectedReason, setSelectedReason] = useState(1);
  const [customReason, setCustomReason] = useState('');

  const customReasonInputRef = useRef(null);

  const clickNextButtonHandler = async () => {
    setLoading(true);

    if (selectedReason === cancellationReasons.length) {
      // console.log(customReasonInputRef.current.value);
      setCustomReason(customReasonInputRef.current.value);
    }

    const body = isOnDashboard
      ? {
          tickets: ticketsToCancel.map((ticket) => ticket.id)
        }
      : {
          processId,
          tickets: ticketsToCancel.map((ticket) => ticket.id)
        };

    setCurrentStep(currentStep + 1);
    const { API } = await import('aws-amplify');

    try {
      const apiData = await API[API_METHODS.POST](
        'RoadJetAPI',
        `/tickets/cancel/summary${isOnDashboard ? '' : '/public'}`,
        {
          headers: {},
          body: body
        }
      );
      setSummary(apiData);
    } catch (error) {
      setError(true);
      setCurrentStep(currentStep + 1);
    }

    setLoading(false);
  };

  const completeCancelHandler = async () => {
    setLoading(true);

    // console.log(customReason);

    const body = isOnDashboard
      ? {
          tickets: ticketsToCancel.map((ticket) => ticket.id),
          description:
            selectedReason === cancellationReasons.length
              ? customReason
              : cancellationReasons[selectedReason - 1]
        }
      : {
          processId,
          tickets: ticketsToCancel.map((ticket) => ticket.id),
          description:
            selectedReason === cancellationReasons.length
              ? customReason
              : cancellationReasons[selectedReason - 1]
        };
    const { API } = await import('aws-amplify');

    try {
      const apiData = await API[API_METHODS.POST](
        'RoadJetAPI',
        `/tickets/cancel${isOnDashboard ? '' : '/public'}`,
        {
          headers: {},
          body: body
        }
      );

      apiData && setCurrentStep(currentStep + 2);
    } catch (error) {
      setError(true);
      setCurrentStep(currentStep + 1);
    }

    setLoading(false);
  };

  // const finishHandler = () => {
  //   setCurrentStep(1);
  //   return onCancelFinish;
  // };

  const Step1 = () => (
    <div className="modal-body py-0">
      <div className="py-6 text-center">
        <Icon fontAwesomeIconClass="fas fa-exclamation-circle fa-4x" isRelativelyPositioned />
      </div>
      <p className="px-6 py-6 text-center lead ">Möchtest du Dein Ticket wirklich stornieren?</p>
      <div className="d-flex justify-content-around mb-4">
        <Button caption="ABBRECHEN" type="primary" isRelativelyPositioned onClick={onDismiss} />
        <Button
          caption="STORNIEREN"
          type="danger"
          isRelativelyPositioned
          onClick={() => setCurrentStep(currentStep + 1)}
        />
      </div>
    </div>
  );

  const Step2 = () => (
    <div className="modal-body py-0">
      <p className="px-3 py-3 lead font-weight-bold">
        Bitte teile uns mit, warum du dein Ticket stornieren möchtest
      </p>

      <div className="pl-3 mb-6">
        {cancellationReasons.map((reason, i) => (
          <Input
            key={`reason-${i + 1}`}
            id={`reason-${i + 1}`}
            type="radio"
            label={reason}
            customClass="py-2"
            isChecked={selectedReason === i + 1}
            onSelect={() => setSelectedReason(i + 1)}
            radioInputLabelFontSize="1rem"
            radioInputSize="lg"
          />
        ))}

        {selectedReason === cancellationReasons.length && (
          <Input
            refEl={customReasonInputRef}
            key="customReason"
            name="customReason"
            id="customReason"
            rows="3"
            type="textarea"
            customClass="pt-2 mb-2"
            placeholder="Hier schreiben"
            defaultValue=""
            // onChange={(e) => setCustomReason(e.target.value)}
          />
        )}
      </div>

      <div className="modal-footer">
        <Button
          caption="Abbrechen"
          type="dark"
          size="sm"
          isRelativelyPositioned
          onClick={onDismiss}
          borderRadiusSize="2px"
        />
        <Button
          caption="Weiter"
          type="primary"
          size="sm"
          isRelativelyPositioned
          onClick={clickNextButtonHandler}
          borderRadiusSize="2px"
        />
      </div>
    </div>
  );

  const Step3 = () =>
    loading ? (
      <div className="loading-cancellation-by-booking-id d-flex">
        <div className=" spinner-border text-primary m-auto" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    ) : (
      <div className="modal-body py-0">
        <p className="px-3 py-3  font-weight-bold">Stornierungsinformationen</p>
        <p className="px-3 pb-3">
          Bitte beachte, dass wir es uns vorbehalten bei einer Stornierung von weniger als 48h vor
          der Fahrt 50 % des Ticketpreises einzubehalten.
        </p>
        <div className="bg-light-container px-2 ticket-container">
          {summary.tickets.map((ticket) => (
            <TCCItemMobile
              key={ticket.id}
              ticket={ticket}
              ticketCount={1}
              type="ticket-id"
              isSummaryItem
            />
          ))}

          <div className="px-5 px-lg-7">
            <table className="table text-right font-14 mb-0 pr-6">
              <tbody>
                <tr>
                  <th scope="row">Ticketpreis:</th>
                  <td>{summary.totalTicketsPrice}€</td>
                </tr>
                <tr>
                  <th scope="row">Einbehaltung:</th>
                  <td>{summary.cutPrice}€</td>
                </tr>
                <tr>
                  <th scope="row">Erstattung:</th>
                  <td>{summary.totalRefund}€</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="modal-footer">
          <Button
            caption="Zurück"
            type="dark"
            size="sm"
            isRelativelyPositioned
            onClick={() => setCurrentStep(currentStep - 1)}
            borderRadiusSize="2px"
          />
          <Button
            caption="Stornierung beenden"
            type="danger"
            size="sm"
            isRelativelyPositioned
            onClick={completeCancelHandler}
            borderRadiusSize="2px"
          />
        </div>
      </div>
    );

  const Step4 = () => (
    <div className="modal-body text-center">
      <h1 className="h4">{error && 'Error'} </h1>
      <p className=" text-center">There is an error</p>

      <Button
        caption="OK"
        onClick={() => {
          onDismiss();
          setError(false);
          setCurrentStep(1);
        }}
        type="primary"
        isRelativelyPositioned
        customClass="mx-auto mt-6 mb-3"
      />
    </div>
  );

  const Step5 = () => (
    <div className="py-6">
      <p className="px-md-12 px-8 text-center py-3 lead font-weight-bold">
        Ihre Stornierung wurde erfolgreich durchgeführt
      </p>
      <div className="text-center">
        <Icon
          fontAwesomeIconClass="fas fa-check-square fa-3x"
          color="primary"
          isRelativelyPositioned
        />
      </div>
      <div className="text-center my-3">
        {isOnDashboard ? (
          <button className="btn text-primary" onClick={onCancelFinish}>
            OK
          </button>
        ) : (
          <Button to="/" caption="Zurück zur Homepage" />
        )}
      </div>
    </div>
  );

  const stepFuncs = {
    1: <Step1 />,
    2: <Step2 />,
    3: <Step3 />,
    4: <Step4 />,
    5: <Step5 />
  };

  return (
    <>
      <Backdrop
        isActivated={isTicketCancellationModalDisplayed}
        onDismiss={currentStep !== 5 ? onDismiss : () => {}}
      />
      <Modal
        bgColor="white"
        customClass="ticket-cancellation-modal"
        isModalActivated={isTicketCancellationModalDisplayed}>
        {stepFuncs[currentStep]}
      </Modal>
    </>
  );
};

export default TicketCancellationModal;
