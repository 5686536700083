const parsePrice = (price) => {
  if (!price) return null;
  const priceTokens = typeof price === 'string' ? price.split('.') : price.toString().split('.');

  return {
    decimal: priceTokens[0],
    float: priceTokens[1]
  };
};

export default parsePrice;
