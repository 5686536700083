const checkInputValidity = (value, type = 'text') => {
  if (type === 'email') {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(value);
  }

  if (type === 'phone') {
    if (value.length === 1) return false;
  }

  if (!value || value.length === 0) return false;
  return true;
};

export default checkInputValidity;
