import AppDownloadButtonsGroup from '../../AppDownloadButtonsGroup';
import HubspotForm from '../../HubspotForm';
import Image from '../../Image';
import Button from '../Button';
import Icon from '../Icon';

import Footer from './Footer';

export default Footer;
export { AppDownloadButtonsGroup, Button, HubspotForm, Icon, Image };
