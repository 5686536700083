import networkErrorSvgImage from '../../static/assets/images/svg/error/error-2.svg';
import maintenanceErrorSvgImage from '../../static/assets/images/svg/error/error-3.svg';

export const networkErrorImage = {
  altText: 'A bus in primary green color with a couple of warning signs',
  source: networkErrorSvgImage
};

export const maintenanceErrorImage = {
  altText: 'A hoisting vehicle carrying a broken bus',
  source: maintenanceErrorSvgImage
};
