/**
 * this handler function is responsible for splitting "Page Title",
 * if its content is longer than allowed for fitting into one line
 * based on the specified separator character.
 * by splitting "Page Title", it will be fit on top of the green background
 * image, which is used as the background for page title at the top of each page.
 */
const splitPageTitle = (title) => title.split('_');

export default splitPageTitle;
