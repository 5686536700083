import { dayNames } from './constants';

const parseDateDay = (dateString) => {
  // if (!dateString) return null;

  // first, convert "dateString" to date object
  const dateObj = new Date(dateString);

  // second, convert "dateObj" to desired stirng format using "toDateString()" method
  const date = dateObj.toDateString();

  // third, split "date" string by space (" ") character
  const dateTokens = date.split(' ');

  // fourth, grab day name abbreviation from "dateTokens"
  const dayName = dateTokens[0];

  // fifth, return German correspondence of "dayName" using "dayNames" variable
  return dayNames[dayName];
};

export default parseDateDay;
