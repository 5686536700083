import { useState } from 'react';

import './TicketCancellationCard.scss';
import { Button, Input } from '.';

const TCCHeaderMobile = ({
  bookingId,
  createdAt,
  isAllCancelled,
  isChecked,
  isCancelButtonDisabled,
  isContentHidden,
  isOnDashboard,
  isSelectable,
  setIsSelectable,
  onCancelButtonClick,
  onChange,
  onToggleContentShow,
  payerName,
  type
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      {!isOnDashboard && (
        <div className="mb-5">
          <Button
            isRelativelyPositioned
            caption="Stornieren"
            size="sm"
            type="danger"
            borderRadiusSize="4px"
            isDisabled={isCancelButtonDisabled}
            onClick={onCancelButtonClick}
          />
        </div>
      )}

      {type === 'booking-id' && (
        <div
          className={`ticket-cancellation-card-title   ${
            isOnDashboard ? 'on-dashboard-mobile ' : ''
          } ${isAllCancelled ? 'cancelled-booking-header' : ''}`}>
          <div
            className={`${
              isAllCancelled ? 'd-none' : !isSelectable ? 'd-none' : 'd-inline-block'
            }   mx-3 `}>
            <Input
              labelLeftMarginClass="mr-0"
              htmlFor="selectAll"
              id="selectAll"
              isBorderedCheckbox
              isChecked={isChecked}
              name="selectAll"
              onChange={onChange}
              type="checkbox"
            />
          </div>
          <div className={`${isSelectable ? 'w-83' : 'w-95'} d-inline-block`}>
            {type === 'booking-id' ? (
              isOnDashboard ? (
                <div className="py-2 d-flex align-content-center">
                  <div className="text-primary font-12 my-auto">
                    <div className="w-100">
                      Buchungsnu.{' '}
                      <span className="font-14  text-primary font-weight-bold">#{bookingId}</span>
                    </div>
                    <span className="font-12 w-100 text-dark">{createdAt}</span>
                  </div>

                  <div className="ml-auto mr-1 my-auto">
                    <span className="d-flex">
                      {isOnDashboard &&
                        (isSelectable ? (
                          <span className="d-inline-flex ml-1">
                            <Button
                              className="cancel-button-outline mr-3 d-inline-block"
                              isRelativelyPositioned
                              caption="Stornieren"
                              size="sm"
                              type="outline-danger"
                              color="danger"
                              borderRadiusSize="4px"
                              isDisabled={isCancelButtonDisabled}
                              onClick={onCancelButtonClick}
                            />
                            <button
                              className="btn font-14 d-flex align-content-center px-1 px-sm-3"
                              onClick={() => {
                                setIsSelectable(false);
                              }}>
                              <i className="fas fa-times"></i>
                            </button>
                          </span>
                        ) : (
                          <div className="dropdown d-inline-block my-auto ml-3">
                            <button
                              className="btn d-flex"
                              type="button"
                              onClick={() => setIsMenuOpen(true)}>
                              <i className="fas fa-ellipsis-v"></i>
                            </button>
                            <div
                              className={`${
                                isMenuOpen ? 'd-block' : 'd-none'
                              }  p-1 position-absolute bg-light-container dropdown-menu`}>
                              <div className="dropdown-item d-flex">
                                <button
                                  className="btn btn-sm "
                                  onClick={() => setIsMenuOpen(false)}>
                                  <i className="fas fa-times"></i>
                                </button>
                                <button
                                  className={'btn text-secondary font-14'}
                                  disabled={isAllCancelled}
                                  onClick={() => {
                                    setIsMenuOpen(false);
                                    return !isAllCancelled && setIsSelectable(true);
                                  }}>
                                  Bearbeiten
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}

                      {/* {isOnDashboard && (
                        <div className="mb-5 my-auto">
                          {isAllCancelled ? (
                            <div className="badge badge-light p-2 invisible">
                              <span className="">Abgesagt</span>
                            </div>
                          ) : (
                            <Button
                              isRelativelyPositioned
                              caption="Stornieren"
                              size="sm"
                              type="outline-danger"
                              color="danger"
                              customClass="bg-white"
                              borderRadiusSize="4px"
                              isDisabled={isCancelButtonDisabled}
                              onClick={onCancelButtonClick}
                            />
                          )}
                        </div>
                      )} */}
                    </span>
                  </div>
                  <div className="toggle-button-booking my-auto">
                    <i
                      className={`fas fa-chevron-${isContentHidden ? 'down' : 'up'}`}
                      role="button"
                      tabIndex={0}
                      onClick={onToggleContentShow}></i>
                  </div>
                </div>
              ) : (
                <div className="py-2 my-auto">
                  <div className="text-primary font-12">Buchungsinhaber</div>
                  <div className="d-flex justify-content-between">
                    <span className="font-14 ">{payerName}</span>
                    <span className="font-14  text-primary font-weight-bold">#{bookingId}</span>
                  </div>
                </div>
              )
            ) : (
              ''
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TCCHeaderMobile;
