import React from 'react';

import PropTypes from 'prop-types';

import './PageTitle.scss';

import Image from '../Image';

import { pageTitleBackgroundImage } from '.';

const PageTitle = ({ lineNumber, pageTitle }) => {
  if (lineNumber > 1) {
    return (
      <div className="page-title-wrapper position-relative overflow-hidden d-flex justify-content-center align-items-start mb-4 mb-md-6 mb-lg-8">
        {pageTitle.map((titlePiece, idx) => (
          <h1
            key={titlePiece}
            className={`page-title--multi-line--${
              idx + 1
            } text-dark-gray text-center position-absolute`}>
            {titlePiece}
          </h1>
        ))}

        <Image
          imgAltText="Page Title Background in Company Primary Color and Full Width"
          figureClass="w-100 page-title-bg align-self-end"
          imgFallbackSrc={pageTitleBackgroundImage}
          // isRelativelyPositioned
          isSvg
        />
      </div>
    );
  }

  return (
    <div className="page-title-wrapper position-relative overflow-hidden d-flex justify-content-center align-items-start mb-4 mb-md-6 mb-lg-8">
      <h1 className="page-title--single-line text-dark-gray text-center position-absolute">
        {pageTitle}
      </h1>

      <Image
        imgAltText="Page Title Background in Company Primary Color and Full Width"
        figureClass="w-100 page-title-bg align-self-end"
        imgFallbackSrc={pageTitleBackgroundImage}
        isRelativelyPositioned
        isSvg
      />
    </div>
  );
};

PageTitle.propTypes = {
  lineNumber: PropTypes.number,
  pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired
};

PageTitle.defaultProps = {
  lineNumber: 1
};

export default PageTitle;
