import { useEffect, useState } from 'react';

import 'swiper/swiper.scss';

/**
 * this custom hook is used to define and initialize a carousel object whose initialization options are given as parameter.
 * "carouselContainerClass" parameter is used to assign carousel object to which DOM element.
 */
const useCarousel = (carouselOptions, carouselContainerClass) => {
  const [carousel, setCarousel] = useState(null);

  const goToNextSlideHandler = () => carousel.slideNext(500, true); // handler function for a typical button to go to next slide
  const goToPrevSlideHandler = () => carousel.slidePrev(500, true); // handler function for a typical button to go to previous slide

  useEffect(() => {
    const initiateCarousel = async () => {
      const { Swiper } = await import('swiper');
      setCarousel(new Swiper(`.${carouselContainerClass}`, carouselOptions)); // attach carousel(=swiper) element to DOM element whose class is "swiper-container" at component mount
    };

    initiateCarousel();

    // at component unmount, detach all previously attached event listeners and destroy the carousel object in order to prevent possible memory leaks
    // !!!THIS FAILS IN DEV MODE!!!
    /* return () => {
      // carousel.detachEvents();
      carousel.destroy(true, true);
    }; */
  }, []);

  return { carousel, goToNextSlideHandler, goToPrevSlideHandler };
};

export default useCarousel;
