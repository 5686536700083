import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const sweetAlert = (status, title, message = '', timer = 0, showCancelButton = false) => {
  try {
    // const Swal = await import('sweetalert2');
    // const withReactContent = await import('sweetalert2-react-content');

    const MySwal = withReactContent(Swal);

    return MySwal.fire({
      icon: status,
      title,
      text: message,
      confirmButtonText: '<i class="fas fa-check"></i> OK',
      cancelButtonText: '<i class="fas fa-times"></i> STORNIEREN',
      showCancelButton,
      cancelButtonColor: '#dc3545',
      confirmButtonColor: ' #28a745',
      timer
    });
  } catch (error) {
    // console.log('[sweetAlert.js] error: ', error);
  }
  /* const MySwal = withReactContent(Swal);

  return MySwal.fire({
    icon: status,
    title,
    text: message,
    confirmButtonText: '<i class="fas fa-check"></i> OK',
    cancelButtonText: '<i class="fas fa-times"></i> STORNIEREN',
    showCancelButton,
    cancelButtonColor: '#dc3545',
    confirmButtonColor: ' #28a745',
    timer
  }); */
};

export default sweetAlert;
