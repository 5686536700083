import googlePlayStoreSvgImage from '../../static/assets/images/svg/store/google-play-store.svg';
import iosAppStoreSvgImage from '../../static/assets/images/svg/store/ios-app-store.svg';

export const playStoreConnectionButtons = [
  {
    id: 'google-play-store',
    href: 'https://play.google.com/store/apps/details?id=com.roadjet.mobile',
    image: {
      altText: 'Google Play Store Universal Button',
      source: googlePlayStoreSvgImage
    }
  },

  {
    id: 'ios-app-store',
    href: 'https://apps.apple.com/lt/app/roadjet/id1561920503',
    image: {
      altText: 'iOS App Store Universal Button',
      source: iosAppStoreSvgImage
    },
    title: 'Download on the',
    text: 'App Store'
  }
];
