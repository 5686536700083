import Datatable from '../../components/Datatable';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import PageLanding from '../../components/PageLanding';
import PageTitle from '../../components/PageTitle';
import Button from '../../components/shared/Button';
import Card from '../../components/shared/Card';
import FaqComponent from '../../components/shared/FaqComponent';
import Footer from '../../components/shared/Footer';
import Header from '../../components/shared/Header';
import Icon from '../../components/shared/Icon';
import Input from '../../components/shared/Input';
import ReactHelmet from '../../components/shared/ReactHelmet';
import ReCaptcha from '../../components/shared/ReCaptcha';
import TabView from '../../components/shared/TabView';
import TicketCancellationCard from '../../components/TicketCancellationCard';
import TicketCancellationModal from '../../components/TicketCancellationModal';
import { scrollTo } from '../../utils';

import CancellationByBookingIdTab from './CancellationByBookingIdTab';
import CancellationByTicketIdTab from './CancellationByTicketIdTab';
import TicketCancellationWithoutLogin from './TicketCancellationWithoutLogin';
import { ticketCancellationTableCaptions } from './util';

export default TicketCancellationWithoutLogin;
export {
  CancellationByBookingIdTab,
  CancellationByTicketIdTab,
  Button,
  Card,
  Datatable,
  Error,
  FaqComponent,
  Footer,
  Header,
  Icon,
  Input,
  Loading,
  PageLanding,
  PageTitle,
  ReactHelmet,
  ReCaptcha,
  scrollTo,
  TabView,
  TicketCancellationCard,
  TicketCancellationModal,
  ticketCancellationTableCaptions
};
